import $ from "jquery";
import _ from 'underscore';
import axios from "axios";
import * as HttpStatus from "http-status-codes";
import moment from "moment";
import BaseModel from "./BaseModel";
import ProjectRoles from "./collections/ProjectRoles";
import BaseCollection from "./collections/BaseCollection";
import { ERROR_CODES } from "../utils/constants";
import { getValueOrDefault, isEmptyString } from "../utils/helperFunctions";
import { getApiUrl } from "../utils/serviceUrls";

export default class Project extends BaseModel {
    constructor(attributes) {
        super(attributes);
        this.path = '';
        if (this.model.project_roles) {
            this.model.project_roles = this.getProjectRoles();
        }
    }

    get(key) {
        return super.get(key);
    }

    isModelAlreadyLoaded() {
        return this.model.project_roles instanceof BaseCollection;
    }

    getProjectRoles() {
        if (this.model.project_roles) {
            if (this.isModelAlreadyLoaded()) {
                return this.model.project_roles;
            }

            return new ProjectRoles(this.model.project_roles);
        }
        return null;
    }

    fetch({ method, headers: customHeaders }, successCallback, errorCallback) {
        const defaultHeaders = {
            Authorization: window.loggedInUser.auth_token
        };
        const headers = { ...defaultHeaders, ...customHeaders };

        const success = (response) => {
            let extendedResponse = response;
            if (this.model) {
                extendedResponse = _.extend(this.model, response);
            }
            this.model = new Project(extendedResponse).model;
            if (successCallback) {
                return successCallback(extendedResponse);
            }
        };
        return $.ajax({
            url: this.path,
            method,
            headers,
            success,
            error: errorCallback
        });
    }

    save({ method, headers }, successCallback, errorCallback) {
        const defaultHeaders = {
            Authorization: window.loggedInUser.auth_token,
            'content-type': 'application/json'
        };

        const options = {
            method,
            headers: { ...defaultHeaders, ...headers },
            body: JSON.stringify({
                project: this.getJson()
            })
        };

        return fetch(this.path, options).then((response) => {
            if (response.status !== HttpStatus.NO_CONTENT && response.status !== HttpStatus.CONFLICT && response.status !== HttpStatus.CREATED) {
                console.error(`Looks like there was a problem. Status Code: ${response.status}`);
                return;
            }
            if (successCallback) {
                if (response.status === HttpStatus.CONFLICT) {
                    return response.json().then(error => errorCallback(ERROR_CODES.USER_OVERSTAFFED, error));
                }
                return successCallback(response);
            }
        }).catch(error => errorCallback(ERROR_CODES.UNKNOWN_ERROR, error));
    }

    create({ customerId }, callback) {
        axios.post(getApiUrl("PROJECTS", { customer_id: customerId }),
            { project: this.getJson() },
            { headers: this.getAuthHeaders() })
            .then((response) => {
                callback(response);
            });
    }

    getProject(params, successCallback, status) {
        const baseUrl = getApiUrl("GET_PROJECT", params);
        const url = status ? baseUrl.concat(`?${status}=true`) : baseUrl;
        axios.get(url,
            { headers: this.getAuthHeaders() })
            .then((response) => {
                successCallback(response.data);
            });
    }

    updateProject(params, successCallback, errorCallback) {
        axios.put(getApiUrl("GET_PROJECT", { customer_id: params.customer_id, id: params.id }),
            { project: this.getJson() }, { headers: this.getAuthHeaders() })
            .then((response) => {
                successCallback(response.data);
            })
            .catch((error) => {
                errorCallback(error.response.data.message);
            });
    }

    areActualDatesValid() {
        let isValid = true;
        let errorMessage = "";
        const inputFieldColor = {
            tentative_start_date: '',
            tentative_end_date: '',
            actual_start_date: '',
            actual_end_date: '',
            project_roles: []
        };
        if (this.model.actual_end_date !== ""
            && new moment(this.model.actual_end_date) < new moment(this.model.actual_start_date)) {
            isValid = false;
            inputFieldColor.actual_end_date = "field-with-error";
            errorMessage = ERROR_CODES.ACTUAL_END_DATE;
            return { errorMessage, inputFieldColor, isValid };
        }
        return { errorMessage, inputFieldColor, isValid };
    }

    areTentativeDatesValid() {
        let isValid = true;
        let errorMessage = "";
        const inputFieldColor = {
            tentative_start_date: '',
            tentative_end_date: '',
            actual_start_date: '',
            actual_end_date: '',
            project_roles: []
        };
        if (this.model.tentative_end_date !== ""
            && new moment(this.model.tentative_end_date) < new moment(this.model.tentative_start_date)) {
            isValid = false;
            inputFieldColor.tentative_end_date = "field-with-error";
            errorMessage = ERROR_CODES.TENTATIVE_END_DATE;
        }
        return { errorMessage, inputFieldColor, isValid };
    }

    isMandatoryField(field) {
        const fieldToBeCompared = field.toString();
        return (fieldToBeCompared !== 'tentative_end_date'
            && fieldToBeCompared !== 'actual_start_date'
            && fieldToBeCompared !== 'actual_end_date');
    }

    isMandatoryFieldEmtpy(field) {
        return (this.model[field] === ''
            || (Object.keys(this.model[field]).length === 0 && this.model[field].constructor === Object));
    }

    areValidValues() {
        let isValid = true;
        let errorMessage = "";
        const inputFieldColor = {
            tentative_start_date: '',
            tentative_end_date: '',
            actual_start_date: '',
            actual_end_date: '',
            project_roles: []
        };
        const fields = Object.keys(this.model) || [];
        for (let fieldIndex = 0; fieldIndex < fields.length; fieldIndex += 1) {
            const field = fields[fieldIndex];
            if (this.isMandatoryField(field) && this.isMandatoryFieldEmtpy(field)) {
                isValid = false;
                inputFieldColor[field] = "field-with-error";
            }
        }
        if (!isValid) {
            errorMessage = ERROR_CODES.MANDATORY_FIELDS;
        }
        return { errorMessage, inputFieldColor, isValid };
    }

    validateProjectRoles() {
        let isValid = true;
        let errorMessage = "";
        const inputFieldColor = {
            tentative_start_date: '',
            tentative_end_date: '',
            actual_start_date: '',
            actual_end_date: '',
            project_roles: new Map()
        };
        if (this.isModelAlreadyLoaded() && this.model.project_roles.getSize() > 0) {
            for (let index = 0; index < this.model.project_roles.getSize(); index += 1) {
                const projectRole = this.model.project_roles.get(index);
                const startDate = getValueOrDefault(this.model.actual_start_date, this.model.tentative_start_date);
                if (!projectRole.get('_destroy')) {
                    if (!(projectRole.isValidStartDate(startDate))) {
                        isValid = false;
                        inputFieldColor.project_roles.set(index, { role_start_date: "field-with-error" });
                        errorMessage = ERROR_CODES.START_DATE_LESS_THAN_END_DATE;
                    }
                    const endDate = getValueOrDefault(this.model.actual_end_date, this.model.tentative_end_date);
                    if (!(projectRole.isValidEndDate(endDate))) {
                        inputFieldColor.project_roles.set(index,
                            {
                                ...inputFieldColor.project_roles[index],
                                role_end_date: "field-with-error"
                            });
                        isValid = false;
                        errorMessage = ERROR_CODES.INCORRECT_ROLE_END_DATE;
                    }
                }
            }
        }
        return { errorMessage, inputFieldColor, isValid };
    }

    validateMappedUsersDates() {
        const projectRoles = this.model.project_roles;
        const startDate = this.getValueOrDefault(this.model.actual_start_date, this.model.tentative_start_date);
        const endDate = this.getValueOrDefault(this.model.actual_end_date, this.model.tentative_end_date);

        const { errorMessage, isValid } = projectRoles.validate(startDate, endDate);

        return { errorMessage, isValid };
    }

    removeEmptyMappedUsers() {
        const projectRoles = this.model.project_roles;
        projectRoles.map((projectRole) => {
            const projectRoleUsers = projectRole.get('project_role_users');
            projectRoleUsers.filterUsers();
            projectRole.set('project_role_users', projectRoleUsers);
        });
    }

    areProjectRoleSkillsEmpty() {
        const inputFieldColor = {
            tentative_start_date: '',
            tentative_end_date: '',
            actual_start_date: '',
            actual_end_date: '',
            project_roles: []
        };
        const projectRoles = this.model.project_roles;
        const { errorMessage, isValid } = projectRoles.areProjectRoleSkillsEmpty();
        return { errorMessage, isValid, inputFieldColor };
    }

    validate(isMapMode = false) {
        const isValid = true;
        const inputFieldColor = {
            tentative_start_date: '',
            tentative_end_date: '',
            actual_start_date: '',
            actual_end_date: '',
            project_roles: []
        };

        const errorMessage = "";
        if (isMapMode) {
            const errors = this.validateMappedUsersDates();
            if (!errors.isValid) {
                return errors;
            }
        }
        let errors = this.areValidValues();
        if (!errors.isValid) {
            return errors;
        }
        errors = this.areTentativeDatesValid();
        if (!errors.isValid) {
            return errors;
        }
        errors = this.validateProjectRoles();
        if (!errors.isValid) {
            return errors;
        }
        errors = this.areActualDatesValid();
        if (!errors.isValid) {
            return errors;
        }
        errors = this.areProjectRoleSkillsEmpty();
        if (!errors.isValid) {
            return errors;
        }
        return { errorMessage, inputFieldColor, isValid };
    }

    isTentativeDateEmpty(key) {
        return _.isNull(this.model[key]) || isEmptyString(this.model[key]);
    }

    changeStartDate(key, startDate) {
        const projectRoles = this.get('project_roles');
        const projectEndDate = this.get('actual_end_date')
            ? this.get('actual_end_date') : this.get('tentative_end_date');
        projectRoles.changeRoleStartDates(startDate, projectEndDate);
        this.set(key, startDate);
    }

    changeEndDate(key, endDate) {
        this.set(key, endDate);
        const projectRoles = this.get('project_roles');
        projectRoles.changeRoleEndDates(endDate);
    }
}
