import moment from "moment";
import BaseCollection from "./BaseCollection";
import ProjectRoleUser from "../ProjectRoleUser";
import { ERROR_CODES } from "../../utils/constants";

export default class ProjectRoleUsers extends BaseCollection {
    getModel(attributes) {
        return new ProjectRoleUser(attributes);
    }

    getModelToJson(projectRoleUser) {
        return projectRoleUser.model;
    }

    remove(index) {
        const collection = this.collection;
        collection.splice(index, 1);
        return collection;
    }

    destroy(index) {
        const projectRoleUser = this.collection[index];
        if (projectRoleUser.get('id') === undefined) {
            this.collection.splice(index, 1);
            return;
        }
        projectRoleUser.model._destroy = true;
        this.collection[index] = projectRoleUser;
    }

    filterUsers() {
        this.collection = this.collection.filter(projectRoleUser => projectRoleUser.get('user_id') !== "");
        return this.collection;
    }

    deleteUser(user) {
        const projectRoleUsers = this.collection;
        projectRoleUsers.map((projectRoleUser, index) => {
            if (projectRoleUser === user) {
                if (user.get('id') === undefined) {
                    projectRoleUsers.splice(index, 1);
                    return;
                }
                projectRoleUser.set('_destroy', true);
                return true;
            }
        });
    }

    validate(startDate, endDate) {
        for (let index = 0; index < this.collection.length; index += 1) {
            const date = this.collection[index];
            if (date.isDateRangeNullOrEmpty()) {
                const errorMessage = ERROR_CODES.EMPTY_MAPPED_USER_DATES;
                return { errorMessage, isValid: false };
            }
            if (date.startDateComesBefore(startDate)
                && this.collection[index].endDateComesAfter(endDate)) {
                const errorMessage = ERROR_CODES.MAPPED_USER_DATES;
                return { errorMessage, isValid: false };
            }
            if (date.startDateComesBefore(startDate)) {
                const errorMessage = ERROR_CODES.MAPPED_USER_START_DATE;
                return { errorMessage, isValid: false };
            }
            if (date.endDateComesAfter(endDate)) {
                const errorMessage = ERROR_CODES.MAPPED_USER_END_DATE;
                return { errorMessage, isValid: false };
            }
            if (date.startDateComesAfterEndDate()) {
                const errorMessage = ERROR_CODES.MAPPED_USER_DATES_ERROR;
                return { errorMessage, isValid: false };
            }
        }

        return { errorMessage: "", isValid: true };
    }

    getStartDateOfUserWhoStartedFirst() {
        const projectRoleUsers = this.collection;
        let minimumStartDate = projectRoleUsers[0].getMoment('start_date');
        projectRoleUsers.map((projectRoleUser) => {
            if (projectRoleUser.getMoment('start_date') < minimumStartDate) {
                minimumStartDate = projectRoleUser.get('start_date');
            }
        });
        return new moment(minimumStartDate);
    }

    getStartDateOfUserWhoStartedLast() {
        const projectRoleUsers = this.collection;
        let maximumStartDate = projectRoleUsers[0].getMoment('start_date');
        projectRoleUsers.map((projectRoleUser) => {
            if (projectRoleUser.getMoment('start_date') > maximumStartDate) {
                maximumStartDate = projectRoleUser.get('start_date');
            }
        });
        return new moment(maximumStartDate);
    }

    changeUsersStartDate(roleStartDate, roleEndDate) {
        const projectRoleUsers = this.collection;
        projectRoleUsers.map((projectRoleUser) => {
            const userStartDate = projectRoleUser.getMoment('start_date');
            const userEndDate = projectRoleUser.getMoment('end_date');
            const canModifyUserStartDate = userStartDate < new moment(roleStartDate);
            if (canModifyUserStartDate) {
                projectRoleUser.set('start_date', roleStartDate);
                const isUserDateOverlapWithRoleDate = userEndDate >= new moment(roleStartDate);
                if (!isUserDateOverlapWithRoleDate) {
                    projectRoleUser.set('end_date', roleEndDate);
                }
            }
        });
    }

    changeUsersEndDate(endDate) {
        const projectRoleUsers = this.collection;
        projectRoleUsers.map((projectRoleUser) => {
            const userEndDate = projectRoleUser.getMoment('end_date');
            if (userEndDate >= new moment(endDate)) {
                projectRoleUser.set('end_date', endDate);
            }
        });
    }
}
