import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import Users from "../../models/collections/Users";
import SelectAccessRoles from "./selectAccessRoles";
import PopupForm from "./popupForm";


export default class DisplayOwnerAndAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
            users: new Users([]),
        };
    }

    componentWillMount() {
        this.fetchUsers();
    }

    fetchUsers = () => {
        const { users } = this.state;
        users.getUsersWithPrivilegedRole((users) => {
            this.setState({ users });
        });
    }

    handleChangeInParentComponent(value) {
        const { users } = this.state;
        users.set('role', value);
    }

    togglePopup = () => {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    displayUsers = () => {
        const { users } = this.state;
        return (users.map(user => (
            <Row>
                <Col md={10}><div className="name">{`${user.get('name')} (${user.get('email')})`}</div></Col>
                <Col md={2}>
                    <SelectAccessRoles
                        id={user.get('id')}
                        fetchUsers={this.fetchUsers}
                        selectedRole={user.get('role')}
                        handleChangeInParentComponent={this.handleChangeInParentComponent}
                    />
                </Col>
            </Row>
        )));
    }

    render() {
        return (
            <div>
                <Row>
                    <Col md={8} className="heading-1">
                        User Permission
                    </Col>
                    <Col md={2}>
                        <button
                            className="btn btn-primary"
                            onClick={this.togglePopup}
                        >
                            Add Member
                        </button>
                        <PopupForm
                            text='Cancel'
                            closePopup={this.togglePopup}
                            fetchUsers={this.fetchUsers}
                            showPopup={this.state.showPopup}
                        />
                    </Col>
                </Row>
                <Row className="access-role-grid">
                    <Col md={8}>
                        <Row className="heading">
                            <Col md={10}>
                                Name
                            </Col>
                            <Col md={2}>
                                Role Assigned
                            </Col>
                        </Row>
                        <div className="content">
                            {this.displayUsers()}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
