import axios from "axios";
import $ from "jquery";
import BaseCollection from "./BaseCollection";
import Project from "../Project";
import { getApiUrl } from "../../utils/serviceUrls";

export default class Projects extends BaseCollection {
    getModel(attributes) {
        return new Project(attributes);
    }

    getModelToJson(project) {
        return project.model;
    }

    getActiveProjects(params, successCallback) {
        const apiUrl = getApiUrl("GET_ACTIVE_PROJECTS") + params;
        axios.get((apiUrl), { headers: super.getAuthHeaders() })
            .then((response) => {
                this.collection = new Projects(response.data).collection;
                successCallback(response.data);
            });
    }

    fetchAll(successCallback) {
        const apiUrl = getApiUrl("GET_ALL_PROJECTS");
        axios.get((apiUrl), { headers: super.getAuthHeaders() })
            .then((response) => {
                this.collection = new Projects(response.data).collection;
                successCallback(response.data);
            });
    }

    getProjects(successCallback) {
        const apiUrl = getApiUrl("GET_PROJECTS");
        axios.get((apiUrl), { headers: super.getAuthHeaders() })
            .then((response) => {
                this.collection = new Projects(response.data).collection;
                successCallback(response.data);
            });
    }

    getAllProjects(params, successCallback) {
        axios.get((getApiUrl("GET_ALL_PROJECTS_FOR_USER") + $.param(params)),
            { headers: super.getAuthHeaders() })
            .then((response) => {
                successCallback(response.data);
            });
    }

    getProjectVacancies(params, callBack) {
        axios.get((getApiUrl("GET_PROJECT_VACANCIES") + $.param(params)),
            { headers: super.getAuthHeaders() })
            .then((response) => {
                callBack(response.data);
            });
    }

    fetchByCustomerId(customerId, successCallback) {
        const apiUrl = (getApiUrl("GET_CUSTOMER_PROJECTS", { customer_id: customerId }));
        axios.get((apiUrl), { headers: super.getAuthHeaders() })
            .then((response) => {
                this.collection = new Projects(response.data).collection;
                successCallback(response.data);
            });
    }

    convertArrayTo2DArray(partitionIndex) {
        const groupsOfSlides = [];
        const projects = [...this.collection];
        while (projects.length) {
            const partitionedProjects = new Projects(projects.splice(0, partitionIndex));
            groupsOfSlides.push(partitionedProjects);
        }
        return groupsOfSlides;
    }

    getCurrentUserProjects(successCallback) {
        axios.get((getApiUrl("GET_MY_PROJECTS")))
            .then((response) => {
                this.collection = new Projects(response.data).collection;
                successCallback(response.data);
            });
    }

    getUserProjectsInDateRange(params, callback) {
        axios.get(getApiUrl("GET_USER_PROJECT_STATISTICS"), { params, headers: this.getAuthHeaders() })
            .then((response) => {
                callback(response.data);
            });
    }
}
