import React from 'react';
import { Table } from 'react-bootstrap';
import Pill from "../../common/Pill";
import formatDate from "../../../utils/formatDate";

export default class UserDataTable extends React.Component {
    getUnmatchedSkills(user, matchedSkill, userSkillsResponse) {
        let userSkills;
        if (user.all_skills !== undefined) {
            userSkills = user.all_skills;
            userSkills.forEach((skill, index) => {
                if (matchedSkill === undefined || matchedSkill.indexOf(skill) === -1) {
                    userSkillsResponse.push(
                        <Pill
                            key={index}
                            value={skill}
                        />
                    );
                }
            });
        }
    }

    getMatchedSkills(user, userSkillsResponse) {
        let matchedSkill;
        const matchedSkillExsist = user.matched_skills !== undefined;
        if (matchedSkillExsist) {
            matchedSkill = user.matched_skills;
            matchedSkill.forEach((skill, index) => {
                userSkillsResponse.push(
                    <Pill
                        key={index + skill}
                        value={skill}
                        highlight
                    />
                );
            });
        }
        return matchedSkill;
    }

    getClassForProjectName(projectName) {
        return projectName === "-" ? "" : "project-name-column";
    }

    getRows(data) {
        return data.map(user => (
            <tr key={user.id}>
                <td className="width-15">{user.name}</td>
                <td className="width-15">
                    {user.project_end_date === undefined || user.project_end_date === "-"
                        ? "-"
                        : formatDate(user.project_end_date)}
                </td>
                <td
                    className="width-15"

                >
                    <span
                        className={this.getClassForProjectName(user.project_name)}
                        onClick={() => this.redirectToProjectView(user.customer_id, user.project_id)}
                    >
                        {user.project_name}
                    </span>
                </td>
                <td className="width-15">
                    {user.project_start_date === undefined || user.project_start_date === "-"
                        ? "-"
                        : formatDate(user.project_start_date)}
                </td>
                <td className="width-25">
                    {this.renderUserRoleSkills(user)}
                </td>
                <td className="width-15">
                    {user.resume && user.resume !== "-"
                        ? (
                            <a
                                href={user.resume}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                View Resume
                            </a>
                        )
                        : '-'}
                </td>
            </tr>
        ));
    }

    renderUserRoleSkills = (user) => {
        const userSkillsResponse = [];
        const matchedSkills = this.getMatchedSkills(user, userSkillsResponse);
        this.getUnmatchedSkills(user, matchedSkills, userSkillsResponse);
        return userSkillsResponse;
    }

    redirectToProjectView(customerId, projectId) {
        if (customerId !== "-" && projectId !== "-") {
            const { history, lastPageUrl } = this.props;
            const url = `/customers/${customerId}/view-projects/${projectId}`;
            history.push(`${url}`, {
                customerID: customerId,
                lastPageUrl
            });
        }
    }

    renderData = () => {
        const { data } = this.props;
        const rows = [];
        if (data.model.length) {
            const unassigned = [];
            const assigned = [];
            data.model.forEach((user) => {
                if (user.project_name === "-") {
                    unassigned.push(user);
                } else {
                    assigned.push(user);
                }
            });
            rows.push(this.getRows(unassigned));
            rows.push(this.getRows(assigned));
        }

        return rows;
    }


    render() {
        return (
            <Table bordered responsive className="margin-top-1">
                <thead>
                    <tr className="table-header">
                        <th className="width-15">Name</th>
                        <th className="width-15">Assigned till</th>
                        <th className="width-15">Project Name</th>
                        <th className="width-15">Project start date</th>
                        <th className="width-25">Skills</th>
                        <th className="width-15">Resume</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderData()}
                </tbody>
            </Table>
        );
    }
}
