import React from 'react';

const TooltipWithImage = (props) => {
    const {
        src, tooltipText
    } = props;
    return (
        <span className="tooltip-image">
            <img
                className="info-icon"
                src={src}
                alt="info"
            />
            <span className="tooltip-text text-extra-small">
                {tooltipText}
            </span>
        </span>
    );
};

export default TooltipWithImage;
