import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import Login from './components/login';
import ShowProject from './components/project/list/showProject';
import CreateProject from './components/project/create/createProject';
import ManageProfile from './components/manageProfile/manageProfile';
import CreateCustomer from './components/customer/create/createCustomer';
import Error403 from './components/errorPages/error403';
import UserDashboard from './components/dashboard/UserDashboard';
import Dashboard from './components/dashboard/Dashboard';
import ReportSkill from "./components/reportSkill/reportSkill";
import CustomerList from "./components/customer/list/customerList";
import ViewCustomer from "./components/customer/viewCustomer";
import ViewUserProjects from './components/project/assigned/viewUserProjects';
import CaslAbility from './components/common/caslAuthorisation/ability';
import LeftNav from "./components/common/leftNav";
import ProjectReport from './components/reports/projectReport/projectReport';
import UnassignedPeople from './components/reports/viewUnassignedPeople/unassignedPeople';
import UsersAspirationsReport from './components/reports/user-aspirations/UsersAspirationsReport';
import SkillList from './components/skills/List';
import DisplayAdminAndOwner from "./components/settings/displayOwnerAndAdmin";
import MergeDuplicateSkills from "./components/mergeSkill/mergeDuplicateSkills";
import withTracker from './components/common/withTracker';
import MappingScreen from "./components/project/mapping/mappingScreen";
import EditProjectScreen from "./components/project/edit/editProjectScreen";
import ShowProjectScreen from "./components/project/show/ShowProjectScreen";
import Loader from "./components/common/Loader";
import { ROLES } from "./utils/constants";
import OpenRoles from "./components/reports/openRoles/openRoles";

ReactGA.initialize(process.env.UNIVERSAL_ANALYTICS_CODE || process.env.REACT_APP_UNIVERSAL_ANALYTICS_CODE);

const App = () => {
    const ability = new CaslAbility();

    const getDashboard = () => (
        window.loggedInUser
            ? window.loggedInUser.role === ROLES.USER ? UserDashboard : Dashboard : "");

    const dashboard = getDashboard();

    const routePaths = {
        '/dashboard': { accessVerb: "*", module: "*", component: withTracker(dashboard) },
        '/settings/user-permission': { accessVerb: "settings", module: "Settings", component: withTracker(DisplayAdminAndOwner) },
        '/search-skill': { accessVerb: "search", module: "User", component: withTracker(ReportSkill) },
        '/customers': { accessVerb: "index", module: "Customer", component: withTracker(CustomerList) },
        '/projects': { accessVerb: "index", module: "Project", component: withTracker(ShowProject) },
        '/projects/create': {
            accessVerb: "create",
            module: "Project",
            component: withTracker(CreateProject)
        },
        '/customers/:customer_id/projects/:id': { accessVerb: "update", module: "Project", component: withTracker(EditProjectScreen) },
        '/customers/:customer_id/view-projects/:id': { accessVerb: "showOnly", module: "Project", component: withTracker(ShowProjectScreen) },
        '/open-roles': { accessVerb: "open-roles", module: "User", component: withTracker(OpenRoles) },
        '/customers/:customer_id/projects/map/:id': { accessVerb: "map", module: "Project", component: withTracker(MappingScreen) },
        '/customers/create': { accessVerb: "create", module: "Customer", component: withTracker(CreateCustomer) },
        '/customers/:id': { accessVerb: "show", module: "Customer", component: withTracker(ViewCustomer) },
        '/manage-profile': { accessVerb: "update", module: "User", component: withTracker(ManageProfile) },
        '/my-projects': { accessVerb: 'view-users-project', module: 'Project', component: withTracker(ViewUserProjects) },
        '/unassigned-people': { accessVerb: 'view-unassigned', module: "User", component: withTracker(UnassignedPeople) },
        '/aspirations': { accessVerb: 'view-unassigned', module: "User", component: withTracker(UsersAspirationsReport) },
        '/project-report': { accessVerb: 'project-report', module: "Project", component: withTracker(ProjectReport) },
        '/settings/skills': { accessVerb: 'index', module: "Skill", component: withTracker(SkillList) },
        '/settings/merge-skills': { accessVerb: 'index', module: "Skill", component: withTracker(MergeDuplicateSkills) }
    };

    const routeArray = [<Route exact path='/login' component={Login} key={0} />];
    for (const path in routePaths) {
        const route = routePaths[path];
        const canAccess = (route.accessVerb === '*' && route.module === '*')
            || ability.can(route.accessVerb, route.module);

        const component = canAccess ? route.component : Error403;
        routeArray.push(<PrivateRoute exact path={path} component={component} key={path} />);
    }
    routeArray.push(<Redirect to={{ pathname: '/dashboard' }} key="dashboard" />);
    return (
        <Switch>
            {routeArray}
        </Switch>
    );
};

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            (!window.loggedInUser || window.loggedInUser === null) ? (
                <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                }}
                />
            ) : (
                <div className="wrapper">
                    <article className="main">
                        <div id="component-wrapper">
                            <Component {...props} />
                        </div>
                        <div id="loader-background" className="d-none">
                            <Loader />
                        </div>
                    </article>
                    <aside className="aside">
                        <LeftNav {...props} />
                    </aside>
                </div>
            )
        )}
    />
);

export default App;
