import React, { Component } from 'react';
import _ from 'underscore';
import UserEfficienciesDataSourceGenerator from '../../../utils/userEfficienciesDataSourceGenerator';
import PercentageColorCodeProvider from '../../../utils/colorCodeGenerator';

export default class PrintUsersWorkedOnThisProject extends Component {
    constructor(props) {
        super(props);

        this.weeklyAverage = 0;
        this.dayCountThisWeek = 0;
        this.dayCountThisMonth = 0;
        this.monthlyAverage = 0;
        this.currentIterator = 0;
        this.dataTable = [];
        this.isUserRolePresent = false;

        _.bindAll(this, 'generateUserEfficienciesDatasource', 'printHeader', 'printHeaderForDailyData',
            'printHeaderForWeeklyData', 'printHeaderForMonthlyData', 'getCellContent', 'printWeeklyData',
            'printMonthlyData');
    }

    getNumberOfDays(startDate, endDate) {
        const millisecondsInDay = 1000 * 60 * 60 * 24;
        return (Math.round(Math.abs((endDate.getTime() - startDate.getTime())) / millisecondsInDay)) + 1;
    }

    getCellContent(percentageValue) {
        const cellContent = [];
        const backgroundColor = PercentageColorCodeProvider.getColorCode(percentageValue);
        const suffix = percentageValue !== '-' ? '%' : '';
        const formattedValue = percentageValue + suffix;
        cellContent.push(
            <td className="table-data" bgcolor={backgroundColor}>{formattedValue}</td>,
        );
        return cellContent;
    }

    generateUserEfficienciesDatasource() {
        const {
            projectDetails, selectedDateDetails, rolesName, allUsers
        } = this.props;
        this.dataTable = UserEfficienciesDataSourceGenerator.generate(
            projectDetails.project_roles,
            selectedDateDetails,
            rolesName,
            allUsers
        );

        const userRole = this.dataTable[0][0].roles;

        if (typeof (userRole) === 'undefined') {
            this.isUserRolePresent = true;
        }
    }

    printWeeklyData(totalNoOfRoles) {
        const eachCellData = [];
        this.weeklyAverage = Math.floor(this.weeklyAverage / this.dayCountThisWeek);
        const bgc = PercentageColorCodeProvider.getColorCode(this.weeklyAverage);
        eachCellData.push(<td className="table-data" bgcolor={bgc}>
            {this.weeklyAverage}
            {'%'}
        </td>);
        if (this.currentIterator !== totalNoOfRoles + 1) {
            this.currentIterator += 2;
            eachCellData.push(<td className="table-data">-</td>);
            eachCellData.push(<td className="table-data">-</td>);
        }
        this.dayCountThisWeek = 0;
        this.weeklyAverage = 0;
        return eachCellData;
    }

    calculateWeeklyMappedPercentage(mappedPercentageValue) {
        this.dayCountThisWeek += 1;
        this.weeklyAverage += mappedPercentageValue;
        this.currentIterator += 1;
    }

    printMonthlyData() {
        const eachCellData = [];
        this.monthlyAverage = Math.floor(this.monthlyAverage / this.dayCountThisMonth);
        const bgc = PercentageColorCodeProvider.getColorCode(this.monthlyAverage);
        this.dayCountThisMonth = 0;
        eachCellData.push(<td className="table-data" bgcolor={bgc}>
            {this.monthlyAverage}
            {'%'}
        </td>);
        this.monthlyAverage = 0;
        return eachCellData;
    }

    calculateMonthlyMappedPercentage(mappedPercentageValue, eachRow, eachRowInside, length) {
        if (this.currentIterator < length - 1) {
            const previousMonth = this.dataTable[eachRow][eachRowInside].dates[this.currentIterator][1];

            const currentMonth = this.dataTable[eachRow][eachRowInside].dates[this.currentIterator + 1][1];
            if (previousMonth === currentMonth) {
                if (mappedPercentageValue === '-') {
                    this.currentIterator += 1;
                    return false;
                }
                this.monthlyAverage += mappedPercentageValue;
                this.dayCountThisMonth += 1;
                this.currentIterator += 1;
                return false;
            }
            if (mappedPercentageValue === '-') {
                this.currentIterator += 1;
                return true;
            }
            this.monthlyAverage += mappedPercentageValue;
            this.dayCountThisMonth += 1;
            this.currentIterator += 1;
            return true;
        }
        if (this.currentIterator === length - 1) {
            if (mappedPercentageValue === '-') {
                return true;
            }
            this.monthlyAverage += mappedPercentageValue;
            this.dayCountThisMonth += 1;
            return true;
        }
    }

    printData() {
        const finalData = [];
        for (const projectRoleGeneratedId in this.dataTable) {
            for (const userInProjectGeneratedId in this.dataTable[projectRoleGeneratedId]) {
                this.currentIterator = 0;
                this.dayCountThisMonth = 0;
                const eachCellData = [];
                const userRole = this.dataTable[projectRoleGeneratedId][userInProjectGeneratedId].roles;
                if (typeof (userRole) === 'undefined') {
                    this.isUserRolePresent = true;
                    break;
                }
                const allRoles = Object.keys(userRole);

                const isShadowPresent = this.dataTable[projectRoleGeneratedId][userInProjectGeneratedId].shadow;
                if (isShadowPresent) {
                    eachCellData.push(
                        <td
                            className="table-data display-inline align-left project-report-table"
                            width="150"
                        >
                            {this.dataTable[projectRoleGeneratedId][userInProjectGeneratedId].name}
                        </td>
                    );
                } else {
                    eachCellData.push(
                        <td
                            className="table-data display-inline align-left"
                            width="150"
                        >
                            {this.dataTable[projectRoleGeneratedId][userInProjectGeneratedId].name}
                        </td>,
                    );
                }
                eachCellData.push(
                    <td className="table-data align-left">{allRoles}</td>,
                );

                for (let eachRole = 0; eachRole < userRole[allRoles].length; eachRole += 1) {
                    const roleName = Object.keys(userRole);
                    const mappedPercentageValue = userRole[roleName][eachRole];
                    const currentDay = this.dataTable[projectRoleGeneratedId][userInProjectGeneratedId]
                        .dates[eachRole][0];

                    if (userRole[roleName].length <= 15) {
                        eachCellData.push(this.getCellContent(mappedPercentageValue));
                    }
                    if (userRole[roleName].length > 15 && userRole[roleName].length <= 60) {
                        if (currentDay >= 1 && currentDay < 6) {
                            this.calculateWeeklyMappedPercentage(mappedPercentageValue);
                        }
                        if (currentDay === 6 || eachRole === (userRole[roleName].length) - 1) {
                            if (currentDay === 6 && this.currentIterator === 0) {
                                this.currentIterator += 1;
                                eachCellData.push(<td className="table-data">-</td>);
                            } else {
                                eachCellData.push(this.printWeeklyData((userRole[roleName].length) - 1));
                                eachRole += 1;
                            }
                        }
                        if (currentDay === 0) {
                            this.currentIterator += 1;
                            eachCellData.push(<td className="table-data">-</td>);
                        }
                    }
                    if (userRole[roleName].length > 60) {
                        if ((this.calculateMonthlyMappedPercentage(mappedPercentageValue, projectRoleGeneratedId,
                            userInProjectGeneratedId, userRole[roleName].length))) {
                            eachCellData.push(this.printMonthlyData());
                        }
                    }
                }

                finalData.push(<tr>{eachCellData}</tr>);
            }
        }
        return finalData;
    }

    printHeaderForMonthlyData(fromDate, toDate) {
        const header = [];
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
            'October', 'November', 'December'];
        let currentYear = fromDate.getFullYear();
        let currentMonth = fromDate.getMonth();
        header.push(<th
            className="table-header border-1 margin-right-2 small-font "
        >
            {monthNames[currentMonth]}
            {' '}
            {currentYear}
        </th>);
        for (let eachDate = fromDate; eachDate <= toDate; eachDate.setDate(eachDate.getDate() + 1)) {
            if (eachDate.getMonth() !== currentMonth || eachDate.getFullYear() !== currentYear) {
                header.push(<th
                    className="table-header border-1 margin-right-2 small-font "
                >
                    {monthNames[eachDate.getMonth()]}
                    {' '}
                    {eachDate.getFullYear()}
                </th>);
                currentMonth = eachDate.getMonth();
                currentYear = eachDate.getFullYear();
            }
        }
        return header;
    }

    printHeaderForWeeklyData(fromDate, toDate) {
        const header = [];
        let weekCounter = 0;
        for (let eachDate = fromDate; eachDate <= toDate; eachDate.setDate(eachDate.getDate() + 1)) {
            if (eachDate.getDay() === 0 || eachDate.getDay() === 6) {
                header.push(<th className="table-header border-1 margin-right-2 small-font "> S </th>);
                if (eachDate.toDateString() === toDate.toDateString()) {
                    return header;
                }
            }
            if (eachDate.getDay() === 5 || eachDate.toDateString() === toDate.toDateString()) {
                if (eachDate.toDateString() === toDate.toDateString() && (toDate.getDay() === 0
                    || toDate.getDay() === 6)) {
                    header.push(<th className="table-header border-1 margin-right-2 small-font "> S </th>);
                } else {
                    header.push(<th
                        className="table-header border-1 margin-right-2 small-font "
                    >
                        {` Week ${weekCounter + 1} `}
                    </th>);
                    weekCounter += 1;
                }
            }
        }
        return header;
    }

    printHeaderForDailyData(fromDate, toDate) {
        const header = [];
        for (const eachDate = fromDate; eachDate <= toDate; eachDate.setDate(eachDate.getDate() + 1)) {
            header.push(<th
                className="table-header border-1 margin-right-2 small-font "
            >
                {eachDate.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                })}
            </th>);
        }
        return header;
    }

    printHeader() {
        const header = [];
        const fromDate = new Date(this.props.selectedDateDetails.from);
        const toDate = new Date(this.props.selectedDateDetails.to);
        header.push(<th>Name</th>);
        header.push(<th>Role</th>);
        if (this.getNumberOfDays(fromDate, toDate) <= 15) {
            return (header.concat(this.printHeaderForDailyData(fromDate, toDate)));
        }
        if (this.getNumberOfDays(fromDate, toDate) > 15 && this.getNumberOfDays(fromDate, toDate) <= 60) {
            return (header.concat(this.printHeaderForWeeklyData(fromDate, toDate)));
        }
        if (this.getNumberOfDays(fromDate, toDate) > 60) {
            return (header.concat(this.printHeaderForMonthlyData(fromDate, toDate)));
        }
    }

    updateGlobalVariables() {
        this.weeklyAverage = 0;
        this.dayCountThisWeek = 0;
        this.dayCountThisMonth = 0;
        this.monthlyAverage = 0;
        this.currentIterator = 0;
        this.isUserRolePresent = false;
    }

    render() {
        const styles = {
            width: '100%',
        };

        if (this.props.selectedDateDetails.from && this.props.selectedDateDetails.to) {
            this.generateUserEfficienciesDatasource();
            if (this.isUserRolePresent) {
                return (
                    <div>
                        No Data Found
                        {this.updateGlobalVariables()}
                    </div>
                );
            }
            return (
                <div className="scroll-table">
                    <div className="row">
                        <div className="col-2 text-left">
                            <span className="color-black" />
Billed Employees
                        </div>
                        <div className="col-2 text-left">
                            <span className="square-shadow" />
Shadow
                        </div>
                    </div>
                    <table style={styles} className="table table-bordered align-left">
                        <tbody>
                            <tr className="table-header">{this.printHeader()}</tr>
                            {this.printData()}
                            {this.updateGlobalVariables()}
                        </tbody>
                    </table>
                </div>
            );
        }
        return <div />;
    }
}
