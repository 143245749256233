import React, { Component } from "react";
import _ from "underscore";

import Skills from "../../models/collections/Skills";
import { isEmptyString } from "../../utils/helperFunctions";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

export default class ReactSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: new Skills([]),
      options: [],
    };
    _.bindAll(
      this,
      "handleSkillChange",
      "getSkillsSuccessCallback",
      "getSkillsErrorCallback"
    );
  }
  componentWillMount() {
    const { skills } = this.state;
    skills.getSkills(this.getSkillsSuccessCallback);
  }

  getSkillsErrorCallback(error) {
    console.error("error: ", error);
  }

  getSkillsSuccessCallback(response) {
    const skills = new Skills(response);
    const options = this.asOptions(skills);
    this.setState({ skills, options });
  }

  handleAddition = (value) => {
    const { options } = this.state;
    this.setState({
      options: [{ text: value, value }, ...options],
    });
    this.props.handleAddSkill(value);
  };

  getSkillById = (id) => {
    const { skills } = this.state;
    const filteredSkill = skills.find(id);
    return {
      id: filteredSkill.get("id"),
      skill_type: filteredSkill.get("skill_type"),
    };
  };

  userSuggestedSkill = (value) => _.isNumber(value) === false;

  isSkillModel = (skill) => skill.id;

  convertUserSkillsToValues(skill) {
    if (!this.isSkillModel(skill) && isEmptyString(skill)) {
      return "";
    }
    if (this.isSkillModel(skill)) {
      return skill.skill_type;
    }
    return skill;
  }

  asOptions(skills) {
    const options = skills.map((skill) => ({
      key: skill.get("skill_type"),
      text: skill.get("skill_type"),
      value: skill.get("id"),
    }));
    return options;
  }

  handleSkillChange(value) {
    const handleStateUpdate = this.props.handleStateAssignment;
    if (isEmptyString(value)) {
      handleStateUpdate("skillType", value);
      return;
    }
    if (this.userSuggestedSkill(value)) {
      this.handleAddition(value);
      handleStateUpdate("skillType", value);
      return;
    }
    const skill = this.getSkillById(value);
    handleStateUpdate("skillType", skill);
  }

  render() {
    const filter = createFilterOptions();
    const filterSkillOptions = (options, params) => {
      const filtered = filter(options, params);

      if (params.inputValue !== "") {
        filtered.push({
          inputValue: params.inputValue,
          key: `Add "${params.inputValue}"`,
        });
      }
      return filtered;
    };

    const handleSkill = (event, newValue) => {
      if (typeof newValue === "string") {
        this.handleSkillChange(newValue);
      } else if (newValue && newValue.inputValue) {
        this.handleSkillChange(newValue.inputValue);
      } else {
        this.handleSkillChange(newValue.value);
      }
    };

    const getSkillOptionLabel = (option) => {
      if (typeof option === "string") {
        return option;
      }
      if (option.inputValue) {
        return option.inputValue;
      }
      return option.text;
    };

    const { valid, skillType } = this.props;
    const { options } = this.state;
    return (
      <div>
        <Autocomplete
          value={this.convertUserSkillsToValues(skillType)}
          onChange={handleSkill}
          filterOptions={filterSkillOptions}
          style={{ width: "28ch" }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="skills"
          options={options}
          getOptionLabel={getSkillOptionLabel}
          renderOption={(option) => option.key}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="skills" variant="outlined" required />
          )}
        />
      </div>
    );
  }
}
