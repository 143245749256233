import { Col, Row } from "react-bootstrap";
import React from "react";
import AppComponent from "../../AppComponent";
import ShowProjectRoleGroup from "./ShowProjectRoleGroup";
import Roles from "../../../models/collections/Roles";
import Pill from "../../common/Pill";

export default class ShowProjectRoles extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            toggle: {},
            roles: new Roles([])
        };
    }

    componentDidMount() {
        const { roles } = this.state;
        roles.getRoles(
            (response) => {
                const updatedRoles = new Roles(response);
                this.setState({ roles: updatedRoles });
            }
        );
    }

    createProjectRoleMap = (projectRoles) => {
        const { roles } = this.state;
        const rolesMap = new Map();
        if (roles.collection.length) {
            projectRoles.map((projectRole) => {
                const roleId = projectRole.get('role_id');
                const roleName = roles.find(roleId)
                    .get('name');
                rolesMap[roleId] = roleName;
            });
        }
        return rolesMap;
    };

    handleToggle = (index) => {
        const { toggle } = this.state;
        this.setState({
            toggle: {
                ...toggle,
                [index]: !toggle[index]
            }
        });
    };

    renderUserRoleSkills = (projectRoleSkills) => {
        const userRoleSkills = projectRoleSkills.map((value, index) => (
            <Pill
                key={index}
                value={(value.get('skill').get('skill_type'))}
            />
        ));
        return userRoleSkills;
    }


    getClassName = (toggle) => {
        const cssClass = toggle ? "show-project-role-table" : "show-project-role-table link-pointer";
        return cssClass;
    }

    renderProjectRoleGroups = () => {
        const {
            projectRoles, project
        } = this.props;
        const groupedProjectRoles = projectRoles.getProjectRolesGroups();
        const rolesMap = this.createProjectRoleMap(projectRoles);
        const rows = groupedProjectRoles.collection.map((groupedRole, index) => {
            const roleId = groupedRole[0].get('role_id');
            const projectSkills = groupedRole[0].get('project_role_skills').collection;
            const noOfRoles = groupedRole.filter(role => role.get('_destroy') != true).length;
            if (noOfRoles > 0) {
                return (
                    <Row key={index}>
                        <Col
                            md={11}
                            className={this.getClassName(this.state.toggle[index])}
                            onClick={this.state.toggle[index] ? null : () => this.handleToggle(index)}
                        >
                            <Row className="role-label">
                                <Col md={2}>
                                    {' '}
                                    {rolesMap[roleId]}
                                </Col>
                                <Col md={1}>
                                    {noOfRoles}
                                </Col>
                                <Col md={3} className="user-skill-names">
                                    {this.renderUserRoleSkills(projectSkills)}
                                </Col>
                                <Col md={1} className="padding-left-46">
                                    {!this.state.toggle[index] ? (
                                        <span>
                                            <img className="expand-collapse-img" alt="Expand" src="/expand.svg" />
                                        </span>
                                    )
                                        : (
                                            <span onClick={() => this.handleToggle(index)}>
                                                <img
                                                    className="expand-collapse-img"
                                                    alt="Decrease"
                                                    src="/collapse.svg"
                                                />
                                            </span>
                                        )}
                                </Col>
                            </Row>
                            <div>
                                <ShowProjectRoleGroup
                                    key={roleId}
                                    elements={groupedRole}
                                    project={project}
                                    toggle={this.state.toggle[index]}
                                    projectRoles={projectRoles}
                                    noOfRoles={noOfRoles}
                                />
                            </div>
                        </Col>
                    </Row>
                );
            }
        });
        return rows;
    };

    render() {
        return (
            <React.Fragment>
                {this.renderProjectRoleGroups()}
            </React.Fragment>
        );
    }
}
