import React, { Component } from 'react';
import { Table } from "react-bootstrap";
import formatDate from '../../utils/formatDate';

export default class GridView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headings: [],
            datasource: []
        };
    }

    componentWillMount() {
        const { headings, dataToPrint } = this.props;
        this.setState({
            headings,
            datasource: dataToPrint
        });
    }

    componentWillReceiveProps(nextProps) {
        const { headings, dataToPrint } = nextProps;
        this.setState({
            headings,
            datasource: dataToPrint
        });
    }

    shouldComponentUpdate(nextProps) {
        const { headings, dataToPrint } = this.props;
        const { headings: nextPropsHeadings, dataToPrint: nextPropsDataToPrint } = nextProps;
        return headings !== nextPropsHeadings
            || dataToPrint !== nextPropsDataToPrint;
    }


    handleEditClick(project) {
        const { constructEditUrl, history, lastPageUrl } = this.props;
        const url = constructEditUrl(project);
        history.push(`${url}`, { customerID: project.get('customer_id'), lastPageUrl });
    }

    handleMapClick(project) {
        const { constructMapUrl, history, lastPageUrl } = this.props;
        const url = constructMapUrl(project);
        history.push(`${url}`, { customerID: project.get('customer_id'), lastPageUrl });
    }

    renderGridHeader() {
        const { headings } = this.state;
        const tableHeading = headings.map((heading, index) => (
            <th key={index + 3}>
                {heading.text}
            </th>
        ));
        tableHeading.push(<th className="table-header border-1" key="headerId">Actions</th>);
        return tableHeading;
    }

    redirectToProjectView(project) {
        const { constructShowUrl, history, lastPageUrl } = this.props;
        const url = constructShowUrl(project);
        history.push(`${url}`, { customerID: project.get('customer_id'), lastPageUrl });
    }

    renderGridRows() {
        const { headings, datasource } = this.state;
        const completeTableContent = datasource.map((data, projectId) => {
            const rowContent = headings.map((heading, headerId) => {
                const columnName = heading.column_name;
                const cellData = datasource.get(projectId).get(columnName);
                const isDate = (columnName === "tentative_start_date" || columnName === "tentative_end_date"
                    || columnName === "actual_start_date");
                const cellFormattedValue = isDate && cellData ? formatDate(cellData) : cellData;
                return (
                    <td
                        className="table-data align-left"
                        key={`${headerId + 3}-${projectId}`}
                        onClick={() => this.redirectToProjectView(data)}
                    >
                        {' '}
                        {cellFormattedValue || 'N/A'}
                    </td>
                );
            });
            return (
                <tr key={projectId + 1} className="table-data link-pointer">
                    {rowContent}
                    <td className="align-left actions">
                        <button
                            type="button"
                            title="Edit"
                            onClick={() => this.handleEditClick(data)}
                            className="btn"
                        >
                            <span>
                                <img src="/pencil.svg" alt="pencil" />
                            </span>
                        </button>
                        <button
                            type="button"
                            title="Map"
                            onClick={() => this.handleMapClick(data)}
                            className="btn margin-left-5"
                        >
                            <span>
                                <img src="/mapping.svg" alt="team" />
                            </span>
                        </button>
                    </td>
                </tr>
            );
        });
        return completeTableContent;
    }

    render() {
        return (
            <Table bordered responsive>
                <thead>
                    <tr className="table-header">
                        {this.renderGridHeader()}
                    </tr>
                </thead>
                <tbody>
                    {this.renderGridRows()}
                </tbody>
            </Table>
        );
    }
}
