import React from 'react';
import { Col, Row } from "react-bootstrap";
import CustomerName from '../customerName';
import ContactPerson from '../contactPerson';
import CustomerEmail from '../customerEmail';
import SalesExecutive from '../salesExecutive';
import Notes from '../notes';
import Customer from "../../../models/Customer";
import { SUCCESS_CODES } from "../../../utils/constants";
import AppComponent from "../../AppComponent";

const toastr = require("toastr");

export default class CreateCustomer extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            customer: {
                name: '',
                contact_person: '',
                email: '',
                sales_executive: {},
                notes: ""
            },
            inputFieldColor: {
                name: '',
                contact_person: '',
                email: '',
                sales_executive: {},
                notes: ""
            },
            errorMessage: '',
            errorColumns: []
        };
    }

    createCustomerErrorCallback = (error) => {
        // TODO: Handle error call backs
        console.error("error: ", error);
    }

    createCustomerSuccessCallBack = () => {
        let lastPageUrl = "/dashboard";
        if (this.props.location.state != undefined) lastPageUrl = this.props.location.state.lastPageUrl;
        toastr.success(SUCCESS_CODES.CUSTOMER_CREATE_MESSAGE);
        this.props.history.push(lastPageUrl);
    }

    handleChangeInParentComponent = (key, value) => {
        const customer = this.state.customer;
        customer[key] = value;
        this.setState({ [key]: customer, errorMessage: '' });
    }

    handleCreate = () => {
        const customer = new Customer(this.state.customer);
        const { isValid, inputFieldColor, errorMessage } = customer.validate();
        if (!isValid) {
            this.setState({
                inputFieldColor
            });
            this.setState({
                errorMessage
            });
            return;
        }
        customer.create(this.createCustomerSuccessCallBack, this.createCustomerErrorCallback);
    }

    redirectToPreviousPage = () => {
        this.navigateTo(this.props.location.state.lastPageUrl, this.props.location.state);
    };

    render() {
        return (
            <div className='col-md-11'>
                <div
                    className='col-sm red-color'
                >
                    {this.state.errorMessage}
                </div>
                <Row>
                    <Col xs={10} md={10}>
                        <div><span className="customer-create">Create Customer</span></div>
                    </Col>
                </Row>
                <div className="margin-top-3">
                    <Row className="margin-top-1 show-grid">
                        <Col xs={6} md={4}>
                            <CustomerName
                                name={this.state.customer.name}
                                handleChangeInParentComponent={this.handleChangeInParentComponent}
                                inputFieldColor={this.state.inputFieldColor}
                            />
                        </Col>
                        <Col xs={0} md={2} />
                        <Col xs={6} md={4}>
                            <ContactPerson
                                contact_person={this.state.customer.contact_person}
                                handleChangeInParentComponent={this.handleChangeInParentComponent}
                                inputFieldColor={this.state.inputFieldColor}
                            />
                        </Col>
                    </Row>
                    <Row className="margin-top-1 show
                    -grid"
                    >
                        <Col xs={6} md={4}>
                            <CustomerEmail
                                email={this.state.customer.email}
                                handleChangeInParentComponent={this.handleChangeInParentComponent}
                                inputFieldColor={this.state.inputFieldColor}
                            />
                        </Col>
                        <Col xs={0} md={2} />
                        <Col xs={4} md={4}>
                            <SalesExecutive
                                default_value=""
                                sales_executive={this.state.customer.sales_executive}
                                handleChangeInParentComponent={this.handleChangeInParentComponent}
                                inputFieldColor={this.state.inputFieldColor}
                            />
                        </Col>
                    </Row>
                    <Row className="margin-top-1 show-grid">
                        <Col xs={5} md={5}>
                            <Notes
                                notes={this.state.customer.notes}
                                handleChangeInParentComponent={this.handleChangeInParentComponent}
                            />
                        </Col>
                    </Row>
                </div>
                <Row className="margin-top-3 text-align-right">
                    <Col md={8} />
                    <Col md={1}>
                        <button
                            type="button"
                            onClick={this.redirectToPreviousPage}
                            className="btn btn-secondary back-button"
                        >
                                Cancel
                        </button>
                    </Col>
                    <Col md={1}>
                        <button type="button" onClick={this.handleCreate} className="btn btn-primary ">
                                Create
                        </button>
                    </Col>
                </Row>
            </div>
        );
    }
}
