import { Component } from 'react';

export default class VisaValidation extends Component {
    constructor(visa, state) {
        super(visa, state);
        this.state = state;
        this.visa = visa;
        this.inValidFieldsId = [];
    }

    checkValidValidity() {
        if (this.visa.validity == "") this.inValidFieldsId.push('visa-validity');
    }

    checkValidType() {
        if (this.visa.type_of_visa == "") this.inValidFieldsId.push('visa-type');
    }

    addInvalidToId(invalidIdFields) {
        for (const key in invalidIdFields) {
            document.getElementById(invalidIdFields[key]).classList.add('is-invalid');
        }
    }

    removeInvalidId(invalidIdFields) {
        for (const key in invalidIdFields) {
            document.getElementById(invalidIdFields[key]).classList.remove('is-invalid');
        }
    }

    getInvalidColumnsId() {
        return this.inValidFieldsId;
    }

    validate() {
        this.removeInvalidId(this.inValidFieldsId);
        this.checkValidValidity();
        this.checkValidType();
        if (this.inValidFieldsId.length > 0) {
            this.addInvalidToId(this.inValidFieldsId);
            return false;
        }
        return true;
    }

    checkAvailability(visa, allVisa) {
        for (const key in allVisa) {
            const country = allVisa[key].country;
            const type = allVisa[key].type_of_visa;
            const validity = allVisa[key].validity;
            if ((`${country}-${type}-${validity}`) === (`${visa.country}-${visa.type_of_visa}-${visa.validity}`)) {
                return true;
            }
        }
        return false;
    }
}
