import React, { Component } from 'react';
import { replace } from '../../utils/helperFunctions';
import { SPECIAL_CHAR_REMOVAL_REGEX } from '../../utils/constants';

export default class CustomerName extends Component {
    constructor(props) {
        super(props);
        this.handleChangeOfName = this.handleChangeOfName.bind(this);
    }

    handleChangeOfName(event) {
        const { handleChangeInParentComponent } = this.props;
        handleChangeInParentComponent(
            'name',
            replace(SPECIAL_CHAR_REMOVAL_REGEX.NAME, event.target.value)
        );
    }

    render() {
        const { inputFieldColor: { name: nameFieldColor }, name } = this.props;
        return (
            <div>
                <div>
                    <label className="labels">
Customer Name:
                        <span>
                            <i
                                className='field-indicator-color'
                            >
*
                            </i>
                        </span>
                    </label>
                </div>
                <div>
                    <input
                        type="text"
                        name="customer-name"
                        id="customer-name"
                        className={`${nameFieldColor} text-font border-thin customer-form`}
                        value={name}
                        onChange={this.handleChangeOfName}
                    />
                </div>
            </div>
        );
    }
}
