import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ProjectDetails from './projectDetails';
import Project from "../../../models/Project";
import Roles from "../../../models/collections/Roles";
import { SUCCESS_CODES } from "../../../utils/constants";
import Customer from "../../../models/Customer";
import ProjectRoleDetails from "./ProjectRoleDetails";
import Customers from "../../../models/collections/Customers";
import AppComponent from "../../AppComponent";
import ProjectRoles from "../../../models/collections/ProjectRoles";

const toastr = require("toastr");

const DECIMAL_RADIX = 10;

export default class CreateProject extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            roles: new Roles([]),
            project: new Project({
                project_name: "",
                customer_id: "",
                tentative_start_date: "",
                tentative_end_date: "",
                actual_start_date: "",
                actual_end_date: "",
                project_roles: []
            }),
            isEditMode: false,
            isBorderRequired: true,
            customer: new Customer(),
            customers: new Customers([]),
            customerName: "",
            inputFieldColor: {
                project_roles: new Map()
            }
        };
    }

    componentDidMount() {
        this.populateData();
    }

    getCustomers = () => {
        const { customers } = this.state;
        customers.getAll((updatedCustomers) => {
            this.setState({ customers: updatedCustomers });
        });
    };

    populateData = () => {
        const {
            roles, project
        } = this.state;
        const { location } = this.props;
        this.getRoles(roles);
        if (this.isCustomerSelected()) {
            this.getCustomer();
            project.set('customer_id', parseInt(location.state.customer_id, DECIMAL_RADIX));
        }
        this.getCustomers();
    };

    getCustomer = () => {
        const { location } = this.props;
        const { customer } = this.state;
        const customerId = location.state.customer_id;
        customer.getCustomer(customerId, (response) => {
            this.setState({ customerName: new Customer(response).get('name') });
        });
    };

    getRoles = (roles) => {
        roles.getRoles((response) => {
            this.setState({ roles: new Roles(response) });
        });
    };

    createProjectSuccessCallback = (response) => {
        const { location } = this.props;
        const lastPageUrl = location.state === undefined
            ? "/dashboard" : location.state.lastPageUrl;
        this.handleResponse(response, response.id, "Created", lastPageUrl);
    };

    handleResponse = (response, id, notice, lastPageUrl) => {
        const { history } = this.props;
        window.scrollTo(0, 0);
        toastr.success(SUCCESS_CODES.PROJECT_SUCCESS_MESSAGE + notice);
        history.push(lastPageUrl);
    };

    handleSubmit = () => {
        const { project } = this.state;
        this.updateProjectRoles(project);
        const { isValid, inputFieldColor, errorMessage } = project.validate();
        if (!isValid) {
            window.scrollTo(0, 0);
            this.setState({
                inputFieldColor
            });
            this.notifyError(errorMessage);
            return;
        }
        project.create({ customerId: project.get('customer_id') },
            this.createProjectSuccessCallback);
    };

    updateProjectRoles = (project) => {
        const existingProjectRoleGroups = this.refs.projectRoleDetails.state.projectRoleGroups;
        const newProjectRoles = new ProjectRoles([]);
        for (const projectRoleGroup of existingProjectRoleGroups) {
            let numberOfProjectRoles = projectRoleGroup.numberOfProjectRoles;
            const newProjectRole = projectRoleGroup.projectRoles.get(0);
            while (numberOfProjectRoles > 0) {
                newProjectRoles.add(newProjectRole);
                numberOfProjectRoles -= 1;
            }
        }
        project.set("project_roles", newProjectRoles);
    }

    updateProject = (project) => {
        this.setState({ project });
    };

    handleOnChangeOfDetails = (key, value) => {
        const { project } = this.state;
        project.set(key, value);
        this.setState({ project });
    };

    isCustomerSelected = () => {
        const { location } = this.props;
        if (location.state.customer_id) {
            return true;
        }
        return false;
    };

    redirectToPreviousPage = () => {
        this.navigateTo(this.props.location.state.lastPageUrl, this.props.location.state);
    };

    render() {
        const {
            project, roles,
            isEditMode, customerName, inputFieldColor, isBorderRequired, customers
        } = this.state;
        const customerFromProject = project.get('customer') ? project.get('customer').name : '';
        const projectRoles = project.get('project_roles');
        return (
            <div>
                <div className="heading-1">
                    Create Project
                </div>
                <form>
                    <ProjectDetails
                        projectRoles={projectRoles}
                        roles={roles}
                        handleOnChangeOfDetails={this.handleOnChangeOfDetails}
                        project={project}
                        isEditMode={isEditMode}
                        updateProject={this.updateProject}
                        customerName={customerName || customerFromProject}
                        inputFieldColor={inputFieldColor}
                        isBorderRequired={isBorderRequired}
                        customers={customers}
                        isCustomerSelected={this.isCustomerSelected()}
                    />
                    <ProjectRoleDetails
                        ref='projectRoleDetails'
                        roles={roles}
                        project={project}
                        projectRoles={projectRoles}
                    />
                    <br />
                    <Row>
                        <Col md={8} />
                        <Col md={1}>
                            <button
                                type="button"
                                className='btn btn-secondary back-button'
                                onClick={this.redirectToPreviousPage}
                                data-test="cancel"
                            >
                                Cancel
                            </button>
                        </Col>
                        <Col md={2}>
                            <button
                                type="button"
                                className='btn btn-primary'
                                onClick={this.handleSubmit}
                                data-test="create"
                            >
                               Create Project
                            </button>
                        </Col>
                    </Row>
                </form>
            </div>
        );
    }
}
