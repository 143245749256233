const adminSlides = [
    {
        img: '/admin-1.png',
        label: 'admin-slide-1',
        header: "Welcome to Staffing! ",
        subheader: "Your one-stop solution for all your staffing needs.\n",
        message: "Find the winning team for your Clients, based on Project"
            + " needs and People’s aspirations, all with the help of just a few clicks."
    },
    {
        img: '/admin-2.png',
        label: 'admin-slide-2',
        header: "Want to build your next winning team?",
        subheader: "Have a look at your team member's availability, "
            + "who are interested and skilled to start new projects today.",
        message: ""
    },
    {
        img: '/admin-3.png',
        label: 'admin-slide-3',
        header: "",
        subheader: "Invite your team to simplify your staffing needs.",
        message: ""
    }
];
const nonAdminSlides = [
    {
        img: '/non-admin-1.png',
        label: 'non-admin-slide-1',
        header: "Welcome to Staffing!",
        subheader: "Staffing helps you see all our current and future projects in a nutshell."
            + " Start creating your profile now.",
        message: ""
    },
    {
        img: '/non-admin-2.png',
        label: 'non-admin-slide-2',
        header: "",
        subheader: "Easily find projects that meets your aspirations for roles or technologies. "
            + "Keep your profile updated and get to work on exciting projects.",
        message: ""
    },
    {
        img: '/non-admin-3.png',
        label: 'non-admin-slide-3',
        header: "",
        subheader: "Learn and explore projects which are currently underway or are in the pipeline. "
            + "Update your aspirations and get to select projects which you want to work on. ",
        message: ""
    }
];
export { adminSlides, nonAdminSlides };
