import React from "react";
import { Row, Col } from "react-bootstrap";
import Project from "../../../models/Project";
import ShowProjectDetails from "./ShowProjectDetails";
import ProjectRoles from "../../../models/collections/ProjectRoles";
import ShowProjectRoles from "./ShowProjectRoles";
import AppComponent from "../../AppComponent";

export default class ShowProjectScreen extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            project: new Project({
                project_roles: new ProjectRoles([])
            }),
        };
        this.childRef = React.createRef();
    }

    componentWillMount() {
        const { match: { params: { customer_id, id } } } = this.props;
        const { project } = this.state;
        const params = {
            customer_id,
            id
        };
        project.getProject(params, this.projectSuccessCallback, "project_status");
    }

    projectSuccessCallback = (response) => {
        const project = new Project(response);
        this.setState({ project });
    };

    getLastPageUrl(location) {
        return (location.state === undefined || this.isEmpty(location.state))
            ? "/dashboard"
            : location.state.lastPageUrl;
    }

    handleCancel = () => {
        const { location } = this.props;
        const lastPageUrl = this.getLastPageUrl(location);
        this.navigateTo(lastPageUrl);
    }

    render() {
        const {
            project
        } = this.state;
        const projectRoles = project.get('project_roles');
        return (
            <div>
                <ShowProjectDetails
                    lastPageUrl={this.props.location.pathname}
                    history={this.props.history}
                    project={project}
                />
                <Row className="role-attributes">
                    <Col md={2} className="text-medium-large">
                            Roles
                    </Col>
                </Row>
                <div className="edit-project-roles">
                    <ShowProjectRoles
                        project={project}
                        projectRoles={projectRoles}
                    />
                </div>
                <Row className="margin-top-2">
                    <Col md={11}>
                        <button
                            className='btn btn-secondary back-button'
                            onClick={this.handleCancel}
                            type="button"
                            data-test="back"
                        >
                            Back
                        </button>
                    </Col>
                </Row>
            </div>
        );
    }
}
