import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import Box from "@material-ui/core/Box";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#EDEDED",
    color: theme.palette.common.black,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 300,
  },
});

export default function CertificateTable(props) {
  const classes = useStyles();
  const certificates = props.certificates;
  //   const handleDeleteVisa = props.handleDeleteVisa;
 

  return (
    <TableContainer component={Paper} className={classes.container}>
      { (certificates ? certificates.length : 0)>0 && (
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Certificate</StyledTableCell>
              {/* <StyledTableCell align="right">Actions</StyledTableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {certificates.map((row) => (
              <StyledTableRow >
                <StyledTableCell >{row}</StyledTableCell>

                {/* <StyledTableCell align="right">
                  <Box>
                    <DeleteForeverIcon
                    onClick={(event) =>
                      props.deleteCertificate(
                        `row`,
                        event
                      )
                    }
                    />
                  </Box>
                </StyledTableCell> */}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
