import $ from "jquery";
import axios from "axios";
import _ from "underscore";
import BaseModel from "./BaseModel";
import { getApiUrl } from "../utils/serviceUrls";

export default class UserStatistics extends BaseModel {
    getUsersCountByCurrentStatus(params, successCallback) {
        axios.get(getApiUrl("GET_CURRENT_STATUS_COUNTS", params))
            .then((response) => {
                successCallback(new UserStatistics(response.data).model);
            });
    }

    getUnassignedPeople(params, callback) {
        const options = {};

        if (params.skills) {
            options.skills = params.skills;
        }

        if (params.startDate && params.endDate) {
            options.startDate = params.startDate;
            options.endDate = params.endDate;
        }
        const queryString = $.param(options);

        const apiUrl = getApiUrl("UNASSIGNED_PEOPLE") + queryString;
        axios.get(apiUrl)
            .then((response) => {
                callback(new UserStatistics(response.data).model);
            });
    }

    getUsersStatistics(params, successCallback) {
        axios.get(getApiUrl("GET_USERS_STATISTICS", params))
            .then((response) => {
                successCallback(new UserStatistics(response.data).model);
            });
    }

    getOpenRoles(params, successCallback) {
        const options = {};

        if (params.option) {
            if (params.option === "with_details") {
                options.with_details = true;
            } else {
                options.role = params.option;
            }
        }

        if (params.startDate && params.endDate) {
            options.startDate = params.startDate;
            options.endDate = params.endDate;
        }

        const queryString = $.param(options);
        axios.get(getApiUrl("GET_OPEN_ROLES") + queryString,
            { headers: this.getAuthHeaders() })
            .then((response) => {
                const data = options.role || options.with_details ? this.groupOpenRoles(response.data) : response.data;
                successCallback(data);
            });
    }

   groupOpenRoles = (data) => {
       const groups = _.groupBy(data,
           value => value.role_name
        + value.project_name
        + value.role_skills
        + value.role_start_date
        + value.role_end_date);
       const groupedOpenRoles = _.map(groups, group => ({
           role_id: group[0].role_id,
           role_name: group[0].role_name,
           customer_id: group[0].customer_id,
           project_name: group[0].project_name,
           project_id: group[0].project_id,
           role_skills: group[0].role_skills,
           role_start_date: group[0].role_start_date,
           role_end_date: group[0].role_end_date,
           project_role_id: _.pluck(group, 'project_role_id')
       }));
       return groupedOpenRoles;
   };
}
