import React from "react";
import Select from 'react-select';
import { Col, Row } from "react-bootstrap";
import DonutChart from "react-google-charts";
import AppComponent from "../AppComponent";
import Day from "../../utils/day";
import Projects from "../../models/collections/Projects";
import {
    ALLPROJECTS,
    COLORSUPTOSIXVACANCIES,
    COLORSUPTOTHIRTEENVACANCIES,
    COLORSUPTOTHREEVACANCIES, NOPROJECT, ONGOINGPROJECT, UPCOMINGPROJECT
} from "../../utils/constants";

export default class ProjectVacancies extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            startDate: Day.getTodaysDate(),
            endDate: Day.getTodaysDate(),
            projects: new Projects([]),
            chartData: [[]],
            selectedValue: 1
        };
    }

    componentDidMount() {
        this.populateData();
    }

    populateData() {
        const { startDate, endDate } = this.state;
        const projects = new Projects([]);
        projects.getProjectVacancies({
            startDate,
            endDate
        }, (response) => {
            this.setState({ projects: response }, () => {
                this.getChartData({ label: ALLPROJECTS, value: 1 });
            });
        });
    }

    asOptions = () => [{ label: ALLPROJECTS, value: 1 }, { label: ONGOINGPROJECT, value: 2 },
        { label: UPCOMINGPROJECT, value: 3 }]

    getChartData = (event) => {
        const { label, value } = event;
        const skills = this.populateChartData(label);
        const chartData = Object.entries(skills);
        this.setState({ chartData, selectedValue: value });
    }

    populateChartData(label) {
        const { projects } = this.state;
        const skills = { name: "size" };
        let filteredProjects = [];
        filteredProjects = projects.filter(project => project.status === label);
        if (label === ALLPROJECTS) {
            filteredProjects = projects;
        }
        filteredProjects.map((project) => {
            const skillsVacancy = project.skills_vacancy;
            Object.keys(skillsVacancy).map((skill) => {
                if (skills[skill]) {
                    skills[skill] += skillsVacancy[skill];
                } else {
                    skills[skill] = skillsVacancy[skill];
                }
            });
        });
        if (Object.keys(skills).length === 1) {
            skills[NOPROJECT] = 1;
        }
        return skills;
    }

    getColors = () => {
        const { chartData } = this.state;
        if (chartData.length <= 4) {
            return COLORSUPTOTHREEVACANCIES;
        }
        if (chartData.length <= 7) {
            return COLORSUPTOSIXVACANCIES;
        }

        return COLORSUPTOTHIRTEENVACANCIES;
    }

    render() {
        const { chartData, selectedValue } = this.state;
        if (this.state.projects.length > 0) {
            const colors = chartData ? this.getColors() : [{}];
            return (
                <div>
                    <Row className="margin-top-2" />
                    <Row>
                        <Col md={3} className="heading-2">
                            Technologies in open roles
                        </Col>
                        <Col md={2}>
                            <Select
                                options={this.asOptions()}
                                value={selectedValue}
                                onChange={this.getChartData}
                            />
                        </Col>
                    </Row>
                    <Row className="user-dashboard-technologies" />
                    <Row>
                        <Col md={5}>
                            <DonutChart
                                width="100%"
                                height="28em"
                                chartType="PieChart"
                                loader={<div>Loading Chart</div>}
                                data={chartData}
                                options={{
                                    title: '',
                                    pieHole: 0.4,
                                    backgroundColor: "rgb(246, 248, 250)",
                                    legend: { position: 'bottom', maxLines: 2 },
                                    slices: colors
                                }}
                                rootProps={{ 'data-testid': '3' }}

                            />
                        </Col>
                        <Col md={1} />
                        <Col md={5} className="got-some-ideas">
                            <Row className="padding-top-3" />
                            <Row>
                                <Col md={4} />
                                <Col md={3} className="bulb">
                                    <img src="./idea.svg" />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3} />
                                <Col md={8} className="write-to-us">
                                Got some ideas ? Write to us!
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4} />
                                <Col md={4} className="send-mail">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                    >
                                        <a href="mailto:hello@technogise.com">Send Mail</a>
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            );
        }
        return (
            <div />
        );
    }
}
