import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

export default function VisaEditDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [country, setCountry] = React.useState(props.country);
  const [visaType, setVisaType] = React.useState(props.visa.type_of_visa);
  const [validity, setValidity] = React.useState(props.visa.validity);
  const [visaTypeErrorText, setError] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleVisaChange = (event) => {
    setVisaType(event.target.value);
  };

  const handleValidityChange = (event) => {
    setValidity(event.target.value);
  };

  const handleEditVisa = () => {
    if (visaType === "") {
      setError(true);
      return;
    }

    let visaObject = props.visa;
    const tempId = `${visaObject.country}-${visaObject.type_of_visa}-${visaObject.validity}`;
    visaObject.country = country === "" ? props.visa.country : country;
    visaObject.type_of_visa =
      visaType === "" ? props.visa.type_of_visa : visaType;
    visaObject.validity = validity === "" ? props.visa.validity : validity;
    props.rowEditVisa(visaObject, tempId);
    setOpen(false);
  };

  return (
    <div>
      <EditIcon onClick={handleClickOpen} />{" "}
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
         
          <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="flex-start">
             
                <Grid item sm>
                  <FormControl variant="outlined">
                    <InputLabel id="certificate-label">Country</InputLabel>
  
                    <Select
                      style={{ width: "28ch" }}
                      labelId="country-label"
                      id="Country"
                      label="Country"
                      onChange={handleCountryChange}
                      value={country}
                    >
                      {props.countries.map((country) => (
                        <MenuItem value={country.key} key={country.key}>
                          {country.key}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
             

              
                <Grid item sm={4}>
                  <TextField
                    required={true}
                    id="visa"
                    label="Visa-Type(H1-B etc)"
                    variant="outlined"
                    onChange={handleVisaChange}
                    value={visaType}
                    error={visaTypeErrorText}
                    helperText={visaTypeErrorText ? "This field is required" : ""}
                  />
              
                </Grid>

              <Grid item sm>
                <TextField
                  id="date"
                  label="Visa-Validity"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleValidityChange}
                  value={validity}
                />
              </Grid>
            </Grid>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditVisa} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
