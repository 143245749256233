import React from "react";
import { Row, Col } from "react-bootstrap";
import Label from "../../common/Label";
import Project from "../../../models/Project";
import { SUCCESS_CODES, TIME_INTERVAL } from "../../../utils/constants";
import ProjectMappingTable from "./projectMappingTable";
import ProjectRoles from "../../../models/collections/ProjectRoles";
import AppComponent from "../../AppComponent";

export default class MappingScreen extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            project: new Project({
                project_roles: new ProjectRoles([])
            }),
        };
    }

    componentWillMount() {
        const { match: { params: { customer_id, id } } } = this.props;
        const { project } = this.state;
        const params = {
            customer_id,
            id
        };
        project.getProject(params, this.projectSuccessCallback);
    }

    projectSuccessCallback = (response) => {
        const project = new Project(response);
        this.setState({ project });
    };

    updateProject = (project) => {
        this.setState({ project });
    };

    updateProjectSuccessCallback = (response) => {
        const { location, match } = this.props;
        const lastPageUrl = this.getLastPageUrl(location);
        this.handleResponse(response, match.params.id, "Mapped", lastPageUrl);
    };

    getLastPageUrl(location) {
        return location.state === undefined ? "/dashboard" : location.state.lastPageUrl;
    }

    handleResponse = (response, id, notice, lastPageUrl) => {
        window.scrollTo(0, 0);
        this.notifySuccess(SUCCESS_CODES.PROJECT_SUCCESS_MESSAGE + notice, 'Success',
            { timeOut: TIME_INTERVAL.THOUSAND });
        this.navigateTo(lastPageUrl);
    };

    handleCancel = () => {
        const { location } = this.props;
        const lastPageUrl = this.getLastPageUrl(location);
        this.navigateTo(lastPageUrl);
    };

    updateProjectErrorCallback = (errorMessage) => {
        this.notifyError(errorMessage, { timeOut: TIME_INTERVAL.FIFTEEN_HUNDRED });
    };

    handleSubmit = () => {
        const { project } = this.state;
        const { match: { params: { customer_id, id } } } = this.props;
        const params = {
            customer_id,
            id
        };
        project.removeEmptyMappedUsers();
        const { isValid, errorMessage } = project.validate({ isMapMode: true });
        if (!isValid) {
            window.scrollTo(0, 0);
            this.notifyError(errorMessage, { timeOut: TIME_INTERVAL.THREE_THOUSAND });
            return;
        }
        project.updateProject(params, this.updateProjectSuccessCallback, this.updateProjectErrorCallback);
    };

    render() {
        const { project } = this.state;
        const projectRoles = project.get('project_roles');
        return (
            <div>
                <Row className="heading-1">
                    <Col md={9} xs={5} data-test="projectName">
                    Project Mapping
                    </Col>
                </Row>
                <Row className="project-details">
                    <Col md={3}>
                        <Label data-test="projectName" className="label-margin-right" displayInline>Project Name</Label>
                        <Label className="text-color-grey" displayInline>
                            {' '}
                            {project.get('project_name')}
                        </Label>
                    </Col>
                    <Col md={3} mdOffset={3}>
                        <Label
                            data-test="customerName"
                            className="label-margin-right"
                            displayInline
                        >
Customer Name
                            {' '}
                        </Label>
                        <Label className="text-color-grey" displayInline>
                            {' '}
                            {project.get('customer') ? project.get('customer').name : ""}
                        </Label>
                    </Col>
                </Row>
                <Row className="role-attributes">
                    <Col md={9} xs={5} data-test="projectName" className="text-medium-large">
                    Roles
                        <span className="field-indicator-color">
                        *
                        </span>
                    </Col>
                </Row>
                <>
                    <ProjectMappingTable
                        project={project}
                        projectRoles={projectRoles}
                        updateProject={this.updateProject}
                    />
                </>
                <Row className="margin-top-2">
                    <Col md={10}>
                        <button
                            className='btn btn-secondary btn-border-cancel'
                            onClick={this.handleCancel}
                            type="button"
                            data-test='cancel'
                        >
                            Cancel
                        </button>
                    </Col>
                    <Col md={2}>
                        <button
                            className='btn btn-primary margin-top-5 text-small'
                            onClick={this.handleSubmit}
                            type="button"
                            data-test="submitMapping"
                        >
                            Save Changes
                        </button>
                    </Col>
                </Row>


            </div>
        );
    }
}
