import React, { Component } from 'react';
import Select from 'react-select';
import { Col, Row } from "react-bootstrap";
import _ from "underscore";
import Project from "../../../models/Project";
import Projects from "../../../models/collections/Projects";
import DateRangeSelector from "../../common/DateRangeSelector";

export default class SelectProjectToReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            from: null,
            to: null,
            errors: {}
        };
        this.errorMessage = 'Please fill in the required fields to view the Report';
        this.isError = false;
        this.selectProject = React.createRef();
        this.fetchProject = this.fetchProject.bind(this);
        this.handleChangeInProject = this.handleChangeInProject.bind(this);
    }

    componentWillMount() {
        const projects = new Projects([]);
        projects.fetchAll((response) => {
            this.setState({
                options: response
            });
        });
    }

    onDateRangeSelected = (from, to) => {
        this.setState({ from, to });
    }

    invalidSubmission = () => {
        const { selectedProject } = this.props;
        const { from } = this.state;
        const errors = {};
        if (!from) {
            errors.dateRange = true;
        }
        if (!selectedProject) {
            errors.selectedProject = true;
        }
        this.setState({ errors });
        return !_.isEmpty(errors);
    }

    getErrorClassOnDateRange =() => {
        const { errors } = this.state;
        if (errors.dateRange) { return ('field-with-error'); }
    }

    getErrorClassOnSelectProject =() => {
        const { errors } = this.state;
        if (errors.selectedProject) { return ('field-with-error'); }
    }

    handleChangeInProject(event) {
        const { handleChangeInState } = this.props;
        handleChangeInState('selectedProject', event);
    }

    fetchProject() {
        this.clearErrors();
        const { onDateRangeSelected, handleChangeInState } = this.props;
        const { from, to } = this.state;
        if (this.invalidSubmission()) {
            handleChangeInState('projectDetails', null);
            return null;
        }
        onDateRangeSelected(from, to);
        this.fetchDetailsForSelectedProject();
    }

    clearErrors() {
        this.setState({ errors: {} });
    }

    fetchDetailsForSelectedProject() {
        const { selectedProject, handleChangeInState } = this.props;
        const project = new Project([]);
        project.getProject({
            customer_id: selectedProject.customerId,
            id: selectedProject.value
        }, (response) => {
            handleChangeInState('projectDetails', response);
        });
    }

    render() {
        const {
            errors, options, from, to
        } = this.state;
        const { selectedProject } = this.props;
        return (
            <div>
                <p className='red-color'>{ _.isEmpty(errors) ? '' : this.errorMessage }</p>
                <Row className="sub-heading">
                    <Col xs={2} md={2}>
                        Select Project:
                        <span><i className='red-color'>*</i></span>
                    </Col>
                    <Col xs={4} md={4} className="select-actions">
                        <Select
                            placeholder='Select a project'
                            options={options}
                            onChange={this.handleChangeInProject}
                            value={selectedProject}
                            className={this.getErrorClassOnSelectProject()}
                        />
                    </Col>
                    <Col xs={2} md={2} mdOffset={2} xsOffset={2}>
                        Select Range:
                        <span><i className='red-color'>*</i></span>
                    </Col>
                    <Col xs={2} md={3}>
                        <div>
                            <span className={this.getErrorClassOnDateRange()}>
                                <DateRangeSelector
                                    onRangeSelected={this.onDateRangeSelected}
                                    startDate={from}
                                    endDate={to}
                                    textSize="text-sm"
                                />
                            </span>
                        </div>
                    </Col>
                    <Col xs={1} md={1}>
                        <button
                            type="button"
                            onClick={this.fetchProject}
                            className="btn btn-primary margin-left-2"
                        >
                            {' '}
                            Submit
                        </button>
                    </Col>
                </Row>
            </div>
        );
    }
}
