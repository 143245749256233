import React, { Component } from 'react';
import _ from 'underscore';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { Row, Col } from 'react-bootstrap';
import { getMonthsOptions, getYearsOptions } from "../../utils/helperFunctions";


export default class SkillEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            errorInModal: '',
            editSkillExperience: '',
            editExpertiseLevel: '',
            editCurrentlyWorking: false
        };
        this.months = 0;
        this.years = 0;
        _.bindAll(this, 'onCloseModal', 'onOpenModal', 'passParametersFromSameComponent', 'handleEditCurrentlyWorking',
            'rowEditCheck', 'handleSkillExperienceChange', 'passParametersFromSelectComponent');
    }

    componentWillMount() {
        this.setState({
            editSkillExperience: this.props.usersSkill.skill_experience,
            editExpertiseLevel: this.props.usersSkill.expertise_level,
            editCurrentlyWorking: this.props.usersSkill.currently_working
        });
    }

    onOpenModal() {
        this.handleStateAsissngment('openModal', true);
    }

    onCloseModal() {
        this.handleStateAsissngment('openModal', false);
    }

    handleStateAsissngment(key, value) {
        this.setState({
            [key]: value
        });
    }

    rowEditCheck() {
        const currentObject = this.props.usersSkill;
        const tempId = currentObject.skill.skill_type - currentObject.editSkillExperience;
        const currentSkillExperience = this.state.editSkillExperience != null ? this.state.editSkillExperience : "";
        const currentExpLevel = this.state.editExpertiseLevel != null ? this.state.editExpertiseLevel : "Beginner";
        const currentWorking = this.state.editCurrentlyWorking ? this.state.editCurrentlyWorking : false;
        return this.rowEdit(currentObject, tempId, currentSkillExperience, currentExpLevel, currentWorking);
    }


    componentWillReceiveProps() {
        this.handleStateAsissngment('openModal', false);
    }

    rowEdit(currentObject, tempId, skillExperience, level, working) {
        currentObject.skill_experience = skillExperience;
        currentObject.expertise_level = level;
        currentObject.currently_working = working;
        this.onCloseModal.bind(this);
        this.props.rowEditSkill(currentObject, tempId);
    }

    passParametersFromSameComponent(key, event) {
        this.handleStateAsissngment('errorInModal', "");
        this.handleStateAsissngment('errorInTable', "");
        this.handleStateAsissngment(key, event.target.value);
    }

    handleEditCurrentlyWorking(event) {
        this.handleStateAsissngment('editCurrentlyWorking', event.target.checked);
        if (event.target.checked) {
            return this.handleStateAsissngment('editExperiencedTo', this.props.currentDate);
        }
        this.handleStateAsissngment('editExperiencedTo', '');
    }

    handleSkillExperienceChange(years, months) {
        let totalMonths = 0;
        totalMonths = parseInt((12 * years)) + parseInt(months);
        this.setState({
            editSkillExperience: totalMonths
        });
    }

    passParametersFromSelectComponent(key, event) {
        this.handleStateAsissngment('errorInModal', "");
        this.handleStateAsissngment('errorInTable', "");
        let level = '';
        if (event !== null) level = event.value;

        if (key === "years") {
            this.handleSkillExperienceChange(event.value, this.months);
        } else if (key === "months") {
            this.handleSkillExperienceChange(this.years, event.value);
        }
        this.handleStateAsissngment(key, level);
    }

    render() {
        const { years, months } = this.props.convertSkillExperienceToYears(this.state.editSkillExperience);
        this.years = years;
        this.months = months;
        return (
            <button
                type="button"
                className="btn btn-warning"
                onClick={this.onOpenModal}
            >
                <Modal
                    little
                    styles={{ modal: { width: '50%' } }}
                    closeIconSize={15}
                    open={this.state.openModal}
                    onClose={this.onCloseModal}
                >
                    <div className='red-color'>
                        {this.state.errorInModal}
                    </div>
                    <Row>
                        <Col xs={1} md={1}>
                            <label> Skill: </label>
                        </Col>
                        <Col xs={11} md={11}>
                            <label>{this.props.usersSkill.skill.skill_type}</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} md={4}>
                            <label>Years</label>
                            <Select
                                multi={false}
                                options={getYearsOptions()}
                                onChange={event => this.passParametersFromSelectComponent('years', event)}
                                value={years}
                            />
                        </Col>
                        <Col xs={4} md={4}>
                            <label>Months</label>
                            <Select
                                multi={false}
                                options={getMonthsOptions()}
                                onChange={event => this.passParametersFromSelectComponent('months', event)}
                                value={months}
                            />
                            <input
                                type="checkbox"
                                checked={this.state.editCurrentlyWorking ? this.state.editCurrentlyWorking : false}
                                onChange={this.handleEditCurrentlyWorking}
                            />
                            {' '}
                            Currently Working
                            <br />
                        </Col>
                        <Col xs={4} md={4}>
                            <label>Expertise Level</label>
                            <select
                                className="form-control"
                                value={this.state.editExpertiseLevel ? this.state.editExpertiseLevel : "Beginner"}
                                onChange={event => this.passParametersFromSameComponent('editExpertiseLevel', event)}
                            >
                                <option>Beginner</option>
                                <option>Intermediate</option>
                                <option>Advance</option>
                            </select>
                        </Col>
                    </Row>
                    <button
                        className="btn btn-primary"
                        onClick={this.rowEditCheck}
                    >
                        Update Row
                    </button>
                </Modal>
                <img className="height-20" src="./pencil.svg" alt="edit skill" />
            </button>
        );
    }
}
