import React, { Component } from 'react';
import { Radio, Pagination } from 'semantic-ui-react';
import Modal from "../common/Modal";
import Skills from "../../models/collections/Skills";
import Skill from "../../models/Skill";
import { SKILLS_PER_PAGE, NO_RECORDS_FOUND } from "../../utils/constants";

export default class SkillList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collection: new Skills([]),
            show: false,
            model: new Skill({}),
            activePage: 1,
            totalPages: 0,
        };
    }

    componentDidMount() {
        this.populateSkills();
    }

    onStatusChange = (model) => {
        const previouslyEnabled = model.get('enabled');
        if (previouslyEnabled) {
            this.setState({ model, show: true });
            return;
        }
        model.enableSkill(this.populateSkills);
    };

    populateSkills = () => {
        const { collection, activePage } = this.state;
        collection.getPaginatedSkills({ page: activePage, per_page: SKILLS_PER_PAGE }, (response) => {
            const skills = new Skills(response.skills);
            this.setState({ collection: skills, totalPages: response.total_pages });
        });
    };

    handlePaginationChange = (e, { activePage }) => {
        this.setState({ activePage }, () => {
            this.populateSkills();
        });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    handleSave = () => {
        this.setState({ show: false });
        const { model } = this.state;
        model.disableSkill(this.populateSkills);
    };

    renderGridContent = () => {
        const { collection } = this.state;
        const gridContent = collection.map(model => (
            <div key={`${model.get('id')}`} className="grid-content">
                <div className="name">
                    <span>{model.get('skill_type')}</span>
                </div>
                <div className="status">
                    <Radio
                        onChange={() => this.onStatusChange(model)}
                        checked={model.get('enabled') || false}
                        toggle
                    />
                </div>
            </div>
        ));
        return gridContent;
    };

    render() {
        const {
            show, model, collection, activePage, totalPages
        } = this.state;

        if (!collection.getSize()) {
            return (
                <div className="data-listing">
                    <span className="heading-2">{NO_RECORDS_FOUND("Skills")}</span>
                </div>
            );
        }
        return (
            <div className="data-listing">
                <div>
                    <span className="heading-1">Skills</span>
                </div>
                <div className="grid">
                    <div className="grid-header">
                        <div className="name">
                            Skill
                        </div>
                        <div className="status">
                            Status
                        </div>
                    </div>
                    {this.renderGridContent()}
                    <div className="grid-footer">
                        <Pagination
                            activePage={activePage}
                            boundaryRange={1}
                            onPageChange={this.handlePaginationChange}
                            size='mini'
                            siblingRange={1}
                            totalPages={totalPages}
                        />
                    </div>
                </div>
                <Modal
                    showCloseIcon
                    open={show}
                    onClose={this.handleClose}
                    header="Disable skill"
                    content={(
                        <span>
Are you sure you want to disable
                            <strong>{` ${model.get('skill_type')} `}</strong>
skill?
                        </span>
                    )}
                    handleClose={this.handleClose}
                    handleSave={this.handleSave}
                    actionBtnText="Disable"
                    warningText="Disabling skill might affect projects"
                />
            </div>
        );
    }
}
