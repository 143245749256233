import React from 'react';
import DownArrow from 'react-icons/lib/io/android-arrow-dropdown';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import Url from './url';
import Can from './caslAuthorisation/can';
import caslAbility from './caslAuthorisation/ability';
import AppComponent from "../AppComponent";

const IMAGE_URLS = {
    logo: "/logo.png",
    dashboard: "/dashboard.png",
    dashboard_selected: "/dashboard-selected.png",
    projects: "/projects.png",
    projects_selected: "/projects-selected.png",
    customers: "/customers.png",
    customers_selected: "/customers-selected.png",
    reports: "/reports.png",
    reports_selected: "/reports-selected.png",
    settings: "/settings.png",
    settings_selected: "/settings-selected.png",
    logout: "/logout.png"
};

const ListItemLogo = () => (
    <li className="logo item">
        <Link
            to={Url.DASHBOARD}
        >
            <img className="icons" src={IMAGE_URLS.logo} alt="logo" />
        </Link>
    </li>
);

const ListItemLogout = (props) => {
    const { logout } = props;
    return (
        <li className="item">
            <Link
                to="#"
                onClick={logout}
            >
                <img className="icons" src={IMAGE_URLS.logout} alt="logout" />
                <span className="menu-name">Logout</span>
            </Link>
        </li>
    );
};

const NonExpandableOption = (props) => {
    const { optionData, pathname, optionClicked } = props;
    let isSelected = pathname.includes(optionData.url) && pathname.split("/").length < 4;
    if (optionData.name === "Projects") {
        isSelected = pathname.includes("projects") && pathname.split("/").length < 6;
    }
    return (
        <li className={`${isSelected ? "selected" : ""}`}>
            <Link
                to={{
                    pathname: optionData.url,
                    state: { optionClicked, lastPageUrl: window.location.pathname }
                }}
            >
                <img
                    className="icons"
                    src={`${isSelected
                        ? optionData.imageUrlFilled : optionData.imageUrl}`}
                    alt="filledImage"
                />
                <span className="menu-name">{optionData.name}</span>
            </Link>
        </li>
    );
};

const ExpandableOption = (props) => {
    const {
        optionData, pathname, buttonClicked, renderColour, imageFilled, isExpanded, dropDown
    } = props;
    const isSelected = optionData.url.filter(url => pathname.includes(url)).length;
    let children = [];
    if (isExpanded) {
        children = optionData.expandableData.map((child, index) => dropDown(optionData.expandableData[index]));
    }

    const onClick = () => {
        buttonClicked(optionData.name);
    };

    return (
        <li className={`${isSelected ? "selected" : ""}`}>
            <a
                onClick={onClick}
            >
                <img className="icons" src={`${imageFilled}`} alt="imageFilled" />
                <span className="menu-name">
                    {optionData.name}
                    {' '}
                    <DownArrow />
                </span>
            </a>
            <ul className={`${renderColour}`}>
                {children}
            </ul>
        </li>
    );
};

const DropDownOptions = (props) => {
    const { isSubMenuSelected, optionData, optionClicked } = props;
    return (
        <li className={`sub-menu-item ${isSubMenuSelected}`}>
            <Link
                to={{
                    pathname: optionData.url,
                    state: { optionClicked, lastPageUrl: window.location.pathname }
                }}
            >
                <span className="name">{optionData.name}</span>
            </Link>
        </li>
    );
};

export default class LeftNav extends AppComponent {
    constructor(props) {
        super(props);
        this.ability = new caslAbility();
        this.state = {
            optionClicked: {},
            option: this.getOptions()
        };
        _.bindAll(this, 'dropDown', 'buttonClicked', 'expandableOption', 'nonExpandableOption', 'renderMenuItems');
    }

    getOptions() {
        const options = [
            {
                expandable: false,
                name: "Home",
                url: Url.DASHBOARD,
                imageUrl: IMAGE_URLS.dashboard,
                imageUrlFilled: IMAGE_URLS.dashboard_selected,
                ability: 'home',
                module: 'home'
            },
            {
                expandable: false,
                name: "Customers",
                url: "/customers",
                imageUrl: IMAGE_URLS.customers,
                imageUrlFilled: IMAGE_URLS.customers_selected,
                ability: 'customer',
                module: 'Option'
            },
            {
                expandable: true,
                name: "Reports",
                url: ['/project-report', "/search-skill", "/unassigned-people", "/aspirations", "/open-roles"],
                imageUrl: IMAGE_URLS.reports,
                imageUrlFilled: IMAGE_URLS.reports_selected,
                ability: 'report',
                module: 'Option',
                expandableData: [
                    {
                        name: "Project report",
                        url: "/project-report",
                        ability: 'map',
                        module: 'Project'
                    },
                    {
                        name: "Availability",
                        url: '/unassigned-people',
                        ability: 'view-unassigned',
                        module: 'User'
                    },
                    {
                        name: "Aspirations",
                        url: '/aspirations',
                        ability: 'view-aspirations',
                        module: 'User'
                    },
                    {
                        name: "Open Roles",
                        url: '/open-roles',
                        ability: 'open-roles',
                        module: 'User'
                    }
                ]
            },
            {
                expandable: true,
                name: "Settings",
                url: ['/settings/skills', '/settings/user-permission', '/manage-profile', '/settings/merge-skills'],
                imageUrl: IMAGE_URLS.settings,
                imageUrlFilled: IMAGE_URLS.settings_selected,
                ability: 'index',
                module: 'Settings',
                expandableData: [
                    {
                        name: "My Profile",
                        url: '/manage-profile',
                        ability: 'update',
                        module: 'User'
                    },
                    {
                        name: "User Permission",
                        url: '/settings/user-permission',
                        ability: 'settings',
                        module: 'Settings'
                    },
                    {
                        name: "Skills",
                        url: "/settings/skills",
                        ability: 'index',
                        module: 'Skill'
                    },
                    {
                        name: "Merge Skills",
                        url: "/settings/merge-skills",
                        ability: 'index',
                        module: 'Skill'
                    },
                ]
            }
        ];
        if (window.loggedInUser.role === 'user') {
            options.splice(1, 0, {
                expandable: false,
                name: "Projects",
                url: '/my-projects',
                imageUrl: IMAGE_URLS.projects,
                imageUrlFilled: IMAGE_URLS.projects_selected,
                ability: 'my-project',
                module: 'my-project'
            });
        } else {
            options.splice(1, 0, {
                expandable: true,
                name: "Projects",
                url: ['/my-projects', "/projects", "projects"],
                imageUrl: IMAGE_URLS.projects,
                imageUrlFilled: IMAGE_URLS.projects_selected,
                ability: 'my-project',
                module: 'my-project',
                expandableData: [
                    {
                        name: "All Projects",
                        url: "/projects",
                        ability: 'show',
                        module: 'Project'
                    },
                    {
                        name: "My Projects",
                        url: "/my-projects",
                        ability: 'my-project',
                        module: 'my-project'
                    }
                ]
            });
        }
        return options;
    }

    buttonClicked(key) {
        const { optionClicked: newOptionClicked } = this.state;
        newOptionClicked[key] = !newOptionClicked[key];
        this.setState({ optionClicked: newOptionClicked });
    }

    expandableOption(optionData, isExpanded) {
        const { location: { pathname } } = this.props;
        const isSelected = optionData.url.filter(url => pathname.includes(url)).length;
        const renderColour = isSelected ? "selected" : "";
        const imageFilled = isSelected ? optionData.imageUrlFilled : optionData.imageUrl;
        return (
            <Can
                key={`${optionData.name}-expandables`}
                do={optionData.ability}
                on={optionData.module}
                ability={this.ability}
            >
                <ExpandableOption
                    renderColor={renderColour}
                    imageFilled={imageFilled}
                    buttonClicked={this.buttonClicked}
                    pathname={pathname}
                    optionData={optionData}
                    isExpanded={isExpanded}
                    dropDown={this.dropDown}
                />
            </Can>
        );
    }

    nonExpandableOption(optionData) {
        const { location: { pathname } } = this.props;
        const { optionClicked } = this.state;
        return (
            <Can
                key={`${optionData.name}-nonExpandables`}
                do={optionData.ability}
                on={optionData.module}
                ability={this.ability}
            >
                <NonExpandableOption
                    optionData={optionData}
                    pathname={pathname}
                    optionClicked={optionClicked}
                />
            </Can>
        );
    }

    dropDown(optionData) {
        const { optionClicked } = this.state;
        const { location: { pathname } } = this.props;
        const isSubMenuSelected = pathname === optionData.url ? "sub-menu-selected" : "";
        return (
            <Can
                key={`${optionData.name}-${optionData}-underExpandables`}
                do={optionData.ability}
                on={optionData.module}
                ability={this.ability}
            >
                <DropDownOptions
                    optionClicked={optionClicked}
                    isSubMenuSelected={isSubMenuSelected}
                    pathname={pathname}
                    optionData={optionData}
                />
            </Can>
        );
    }

    renderMenuItems() {
        const { location: { pathname } } = this.props;
        const { option, optionClicked } = this.state;
        const leftNavOption = option.map((element) => {
            if (element.expandable) {
                const isExpanded = optionClicked[element.name] || element.url.indexOf(pathname) > -1;
                return this.expandableOption(element, isExpanded);
            }
            return this.nonExpandableOption(element);
        });
        return (
            <ul className="item">
                {leftNavOption}
            </ul>
        );
    }

    render() {
        return (
            <nav className="main-menu">
                <ul>
                    <ListItemLogo />
                    {this.renderMenuItems()}
                    <ListItemLogout logout={this.logout} />
                </ul>
            </nav>
        );
    }
}
