import React, { Component } from 'react';
import { Table } from "react-bootstrap";
import { MESSAGES } from "../../utils/constants";
import formatDate from "../../utils/formatDate";
import Pill from "../common/Pill";

export default class SkillsGrid extends Component {
    notContainedIn = collection => function arrNotContains(element) {
        return !collection.includes(element);
    }

    renderUserUnmatchedSkills=(matchedSkills, userSkills) => {
        const unmatchedSkill = userSkills.filter(this.notContainedIn(matchedSkills));
        return unmatchedSkill.map(skill => (
            <Pill value={skill} />
        ));
    }

    renderUserMatchedSkills= matchedSkills => matchedSkills.map(skill => (
        <Pill value={skill} highlight />
    ))

    matchedSkills(selectedSkills, userSkills) {
        return selectedSkills.filter(skill => userSkills.includes(skill));
    }

    renderMatchedSkillsUsers() {
        const { users, selectedSkillsLabel } = this.props;
        const allSkills = users.map((user, index) => {
            const userSkills = user.get('skillSet');
            const matchedSkill = this.matchedSkills(selectedSkillsLabel, userSkills);
            const userResume = user.get('resumeUrl') || null;
            return (
                <tr key={`${user.get('name') + index}`} className="table-data">
                    <td>{user.get('name')}</td>
                    <td>{user.get('project')}</td>
                    <td>
                        {(user.get('endDate') === "-") ? user.get('endDate') : formatDate(user.get('endDate'))}
                    </td>
                    <td>{matchedSkill.length}</td>
                    <td className="width-30">
                        {this.renderUserMatchedSkills(matchedSkill)}
                        {this.renderUserUnmatchedSkills(matchedSkill, userSkills)}
                    </td>
                    <td className="width-13">
                        <a
                            href={userResume}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {userResume ? 'View Resume' : '-'}
                        </a>
                    </td>
                </tr>
            );
        });
        return allSkills;
    }

    renderSkillReportHeading() {
        const header = [];
        const { users } = this.props;
        if (users.getSize() > 0) {
            header.push(
                <tr key={0} className="table-header">
                    <th>Name</th>
                    <th>Assigned Project</th>
                    <th>Assigned Till</th>
                    <th>Matched Skills</th>
                    <th>Skills</th>
                    <th>Resume</th>
                </tr>
            );
        }
        return header;
    }


    render() {
        const { users, isInitialState } = this.props;
        if (users.getSize() <= 0 && !isInitialState) {
            return (
                <div className='margin-top-5 font-size-20'>
                    {MESSAGES.NO_USERS_WITH_SKILLS}
                </div>
            );
        }
        return (
            <div className='skill-report-table'>
                <Table bordered responsive>
                    <thead>
                        {this.renderSkillReportHeading()}
                    </thead>
                    <tbody>
                        {this.renderMatchedSkillsUsers()}
                    </tbody>
                </Table>
            </div>
        );
    }
}
