import React from 'react';
import 'react-table/react-table.css';
import { Col, Row } from "react-bootstrap";
import _ from 'underscore';
import GridView from '../../common/GridView';
import Projects from "../../../models/collections/Projects";
import AppComponent from "../../AppComponent";

export default class ShowProject extends AppComponent {
    constructor(props) {
        super(props);
        this.noProjectError = "No Project found for the Customer. Create one now.";
        this.state = {
            projects: new Projects([]),
            headings: [],
            allowAccess: false
        };
        _.bindAll(this, 'constructEditUrl', 'constructMapUrl', 'constructShowUrl', 'getProjectSuccessCallback',
            'getProjectErrorCallback',
            'isAllProjectsPage');
    }

    getProjectSuccessCallback(response, shouldExtractCustomerName) {
        let updatedResponse = response;
        if (shouldExtractCustomerName) {
            updatedResponse = response.map(element => ({ ...element, customer_name: element.customer.name }));
        }
        const projects = new Projects(updatedResponse);
        this.setState({ projects, allowAccess: true });
        this.dataToBePrint = this.state.projects;
    }

    getProjectErrorCallback(error) {
        console.error("error: ", error);
    }

    isAllProjectsPage() {
        return this.props.location.pathname === "/projects";
    }

    componentDidMount() {
        const { projects } = this.state;
        const headings = this.getHeadings();
        if (!this.isAllProjectsPage()) {
            const customerId = this.props.customer.get('id');
            projects.fetchByCustomerId(customerId, response => this.getProjectSuccessCallback(response, false));
            this.setState({ headings });
            return;
        }
        const insertAtIndex = 2;
        headings.splice(insertAtIndex, 0, { text: 'Customer Name', column_name: 'customer_name' });
        this.setState({ headings });

        projects.getProjects(response => this.getProjectSuccessCallback(response, true));
    }

    getHeadings() {
        return [
            { text: 'Id', column_name: 'id' },
            { text: 'Project Name', column_name: 'project_name' },
            { text: 'Tentative Start Date', column_name: 'tentative_start_date' },
            { text: 'Tentative End Date', column_name: 'tentative_end_date' },
            { text: 'Actual Start Date', column_name: 'actual_start_date' },
            { text: 'Team Size', column_name: 'team_size' }
        ];
    }

    constructEditUrl(project) {
        if (this.props.location.pathname !== "/projects") {
            return `/customers/${this.props.customer.get('id')}/projects/${project.get('id')}`;
        }
        return `/customers/${project.get('customer_id')}/projects/${project.get('id')}`;
    }

    constructMapUrl(project) {
        if (this.props.location.pathname !== "/projects") {
            return `/customers/${this.props.customer.get('id')}/projects/map/${project.get('id')}`;
        }
        return `/customers/${project.get('customer_id')}/projects/map/${project.get('id')}`;
    }

    constructShowUrl(project) {
        if (this.props.location.pathname !== "/projects") {
            return `/customers/${this.props.customer.get('id')}/view-projects/${project.get('id')}`;
        }

        return `/customers/${project.get('customer_id')}/view-projects/${project.get('id')}`;
    }

    redirectToCreateProject = () => {
        this.navigateTo("/projects/create", { lastPageUrl: "/projects" });
    };

    isProjectListPage = () => this.props.location.pathname === "/projects";

    redirectToAddProject = () => {
        const id = this.props.customer.get('id');
        this.props.history.push(`/projects/create`, { lastPageUrl: `/customers/${id}`, customer_id: id });
    }

    render() {
        if (!this.state.allowAccess) return (<div />);
        let notice = ""; let
            headerNotice;
        if (this.state.projects == undefined) notice = this.noProjectError;
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.notice) headerNotice = this.props.location.state.notice;
        }
        if (!this.props.customer) headerNotice = "";
        return (
            <div>
                <center>
                    <p>
                        <span className="green-color">
                            {headerNotice}
                            {' '}
                        </span>
                    </p>
                </center>

                <Row className="show-grid">
                    <Col xs={6} md={6}>
                        <div><span className="heading-1">Projects</span></div>
                    </Col>
                    {!this.isProjectListPage()
                    && (
                        <Col xs={6} md={6}>
                            <button
                                type="button"
                                className="btn btn-primary float-right"
                                onClick={this.redirectToAddProject}
                            >
                            Add Project
                            </button>
                        </Col>
                    )
                    }
                    {this.isProjectListPage()
                    && (
                        <Col xs={6} md={6}>
                            <button
                                type="button"
                                className="btn btn-primary float-right text-small"
                                onClick={this.redirectToCreateProject}
                            >
                                    Create Project
                            </button>
                        </Col>
                    )
                    }
                </Row>

                <div className="margin-top-3">
                    <GridView
                        lastPageUrl={this.props.location.pathname}
                        headings={this.state.headings}
                        dataToPrint={this.state.projects}
                        constructEditUrl={this.constructEditUrl}
                        constructMapUrl={this.constructMapUrl}
                        constructShowUrl={this.constructShowUrl}
                        history={this.props.history}
                    />
                </div>
                {notice}
            </div>
        );
    }
}
