import React from "react";
import { Row, Col } from "react-bootstrap";
import X from "react-icons/lib/go/x";
import moment from "moment";
import ProjectRoleUserRow from "./projectRoleUserRow";
import ProjectRoleUser from "../../../models/ProjectRoleUser";
import ProjectRoleUsers from "../../../models/collections/ProjectRoleUsers";
import Day from "../../../utils/day";
import AppComponent from "../../AppComponent";
import { ERROR_CODES } from "../../../utils/constants";

export default class ProjectRoleUsersRows extends AppComponent {
    addProjectRoleUser = () => {
        const { element } = this.props;
        if (element.canAddUser()) {
            const projectRoleUser = this.createUserWithDefaultValues(element);
            const { project, updateProject } = this.props;
            const projectRoleUsers = element.get('project_role_users');
            projectRoleUsers.add(projectRoleUser);
            updateProject(project);
            return;
        }
        this.notifyError(ERROR_CODES.ROLE_END_DATE_PASSED_CANNOT_ADD_NEW_USER);
    }

    createUserWithDefaultValues = (projectRole) => {
        const roleStartDate = new moment(projectRole.get('role_start_date'));
        const roleEndDate = new moment(projectRole.get("role_end_date"));
        const todaysDate = new moment(Day.getTodaysDate());

        const projectRoleUser = new ProjectRoleUser({
            user_id: "",
            user: { name: "" },
            start_date: roleStartDate > todaysDate ? roleStartDate._i : todaysDate._i,
            end_date: roleEndDate > todaysDate ? roleEndDate._i : todaysDate._i,
            mapped_percentage: "-",
            shadow: false
        });
        return projectRoleUser;
    }

    deleteProjectRoleUser = (user) => {
        const { element } = this.props;
        if (!user.canDeleteUser()) {
            this.notifyError(ERROR_CODES.CANNOT_DELETE_USER);
            return;
        }
        const projectRoleUsers = element.get('project_role_users');
        projectRoleUsers.deleteUser(user);
        element.set('project_role_users', projectRoleUsers);
        const { project, updateProject } = this.props;
        updateProject(project);
    }

    handleCheckBox = (projectRoleUser) => {
        const { project, updateProject } = this.props;
        const shadow = projectRoleUser.get('shadow');
        projectRoleUser.set('shadow', !shadow);
        updateProject(project);
    }

    renderProjectRoleUserRow = () => {
        const { element, project, updateProject } = this.props;
        const projectRoleUsers = element.get('project_role_users');
        const activeUsers = element.get('project_role_users')
            .filter(user => user.get("_destroy") !== true);
        let filteredUsers = new ProjectRoleUsers(activeUsers);
        const projectRoleSkills = element.get('project_role_skills');
        const row = [];
        if (filteredUsers.getSize() === 0) {
            const projectRoleUser = new ProjectRoleUser({
                user_id: "",
                user: { name: "" },
                start_date: element.get("role_start_date"),
                end_date: element.get("role_end_date"),
                mapped_percentage: "-",
                shadow: false

            });
            projectRoleUsers.add(projectRoleUser);
            filteredUsers = element.get('project_role_users').filter(user => user.get("_destroy") !== true);
        }
        filteredUsers.map((user, index) => {
            row.push(
                <Row key={index} className="project-role-user-group mapping-table-data">
                    <ProjectRoleUserRow
                        key={user.get('id')}
                        projectRoleUser={user}
                        project={project}
                        updateProject={updateProject}
                        index={index}
                        value={{ label: user.get('user').name, value: user.get('user').id }}
                        projectRoleSkills={projectRoleSkills}
                        projectRole={element}
                    />
                    <Col md={1}>
                        {index === 0 ? (
                            <button
                                type="button"
                                className="btn add-button-dimensions"
                                onClick={this.addProjectRoleUser}
                                data-test="add-project-role-user"
                            >
                                <span>
                                    <img alt="Add" src="/plus_icon.png" />
                                </span>
                            </button>
                        )
                            : (
                                <div>
                                    <input
                                        className="shadow-checkbox"
                                        type="checkbox"
                                        onClick={() => this.handleCheckBox(user)}
                                        checked={user.get('shadow')}
                                        title="Mark as shadow"
                                        data-test="check-box"
                                    />
                                    <button
                                        title="Delete role"
                                        type="button"
                                        className="btn delete-btn"
                                        onClick={() => this.deleteProjectRoleUser(user)}
                                        data-test="delete-user"
                                    >
                                        <X />
                                    </button>
                                </div>
                            )}
                    </Col>
                </Row>
            );
        });
        return row;
    };

    render() {
        return (
            <>
                {this.renderProjectRoleUserRow()}
            </>
        );
    }
}
