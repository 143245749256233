import BaseModel from "./BaseModel";
import Skill from "./Skill";

export default class ProjectRoleSkill extends BaseModel {
    constructor(attributes) {
        super(attributes);
        if (this.model.skill) {
            this.model.skill = this.getSkill();
        }
    }

    get(key) {
        return super.get(key);
    }

    isModelAlreadyLoaded() {
        return this.model.skill instanceof BaseModel;
    }

    hasSkillType(skillType) {
        return this.get('skill').get('skill_type') === skillType;
    }

    getSkill() {
        if (this.model.skill) {
            if (this.isModelAlreadyLoaded()) {
                return this.model.skill;
            }

            return new Skill(this.model.skill);
        }
        return null;
    }
}
