import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const Select = (props) => {
    const {
        required, name, value, onChange, title, onAddItem,
        options, placeholder, search, allowAdditions, selection, fluid, multiple, className, clearable
    } = props;

    return (
        <React.Fragment>
            {title && (
                <label htmlFor={name} className="labels">{title}</label>
            )}
            {required && (
                <span>
                    <i className='field-indicator-color'>*</i>
                </span>
            ) }
            <Dropdown
                options={options}
                placeholder={placeholder}
                search={search || undefined}
                selection={selection || undefined}
                fluid={fluid || undefined}
                allowAdditions={allowAdditions || undefined}
                clearable={clearable || false}
                value={value}
                onAddItem={onAddItem || undefined}
                onChange={onChange}
                multiple={multiple}
                className={`select-box ${className || ""}`}
            />
        </React.Fragment>
    );
};

export default Select;
