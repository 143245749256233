import React from 'react';
import { TIME_INTERVAL } from "../utils/constants";

const toastr = require("toastr");

export default class AppComponent extends React.Component {
    notifyError = (message, timeout = TIME_INTERVAL.THOUSAND) => {
        toastr.error(message, "Error", timeout);
    }

    notifySuccess = (message, timeout = TIME_INTERVAL.THOUSAND) => {
        toastr.success(message, "Success", timeout);
    }

    navigateTo = (url, state = {}) => {
        const { history } = this.props;
        history.push(url, state);
    }

    logout = (event) => {
        const { history } = this.props;
        event.preventDefault();
        window.clearStorage();
        window.loggedInUser = null;
        history.push('/login');
    }

    isEmpty(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }
}
