import React, { Component } from "react";

import $ from "jquery";

import { getApiUrl } from "../../utils/serviceUrls";

import Grid from "@material-ui/core/Grid";
import CertificateTable from "./certificateTable";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

export default class Certification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multi: true,
      databaseCertificates: [],
      enteredCertificates: [],
      selectedCertificate: "",
      errorOccured: false,
    };
    this.handleOnAdditionOfCertificates = this.handleOnAdditionOfCertificates.bind(
      this
    );
  }

  componentWillMount() {
    $.ajax({
      url: getApiUrl("CERTIFICATION_AVAILABLE"),
      headers: { Authorization: window.loggedInUser.auth_token },
      type: "GET",
      success: (response) => {
        this.setState({ databaseCertificates: response });
      },
    });
  }

  handleOnAdditionOfCertificates(value) {
    var breakLoop = false;
    if (this.props.certification === null) {
      this.props.handleModelChange("certification", [value]);
      return;
    }

    this.props.certification.map((item) => {
      if (item.toLowerCase() === value.toLowerCase()) {
        breakLoop = true;
      }
      return;
    });

    if (breakLoop) {
      return;
    }
    this.props.handleModelChange("certification", [
      ...this.props.certification,
      value,
    ]);
    this.setState({
      selectedCertificate: "",
    });
  }

  render() {
    const filter = createFilterOptions();
    const handleCertificateChange = (event, newValue) => {
      if (typeof newValue === "string") {
        this.handleOnAdditionOfCertificates(newValue);
      } else if (newValue && newValue.inputValue) {
        this.handleOnAdditionOfCertificates(newValue.inputValue);
      } else {
        this.handleOnAdditionOfCertificates(newValue.value);
      }
    };
    const filterOptions = (options, params) => {
      const filtered = filter(options, params);

      if (params.inputValue !== "") {
        filtered.push({
          inputValue: params.inputValue,
          label: `Add "${params.inputValue}"`,
        });
      }
      return filtered;
    };
    const getLabels = (option) => {
      if (typeof option === "string") {
        return option;
      }
      if (option.inputValue) {
        return option.inputValue;
      }
      return option.value;
    };
    const { selectedCertificate } = this.state;
    const options = this.state.databaseCertificates;
    return (
      <div id="certification">
        <Grid>
          <Autocomplete
            value={selectedCertificate}
            onChange={handleCertificateChange}
            filterOptions={filterOptions}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="skills"
            options={options}
            getOptionLabel={getLabels}
            renderOption={(option) => option.label}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Certification" variant="outlined" />
            )}
          />
        </Grid>
        <br />
        <CertificateTable
          certificates={this.props.certification}
          deleteCertificate={this.props.handleDelete}
        />
      </div>
    );
  }
}
