import Day from './day';
import { DECIMAL_RADIX } from "./constants";

export default class UserEfficienciesDataSourceGenerator {
    static generate(projectRoles, dateRange, rolesName, allUsers) {
        const datasource = [];
        for (const projectRoleGeneratedId in projectRoles) {
            const userEfficiencies = [{}];
            for (const userInProjectGeneratedId in projectRoles[projectRoleGeneratedId].project_role_users) {
                const userEfficiencyInPercentage = [];
                const dates = [];
                const userInProject = projectRoles[projectRoleGeneratedId].project_role_users[userInProjectGeneratedId];

                const fromDate = new Date(dateRange.from);
                const toDate = new Date(dateRange.to);

                for (const eachDate = fromDate; eachDate <= toDate; eachDate.setDate(eachDate.getDate() + 1)) {
                    dates.push([eachDate.getDay(), eachDate.getMonth()]);
                    const value = UserEfficienciesDataSourceGenerator.getFormattedPercentage(eachDate, userInProject);
                    userEfficiencyInPercentage.push(value);
                }

                const roleId = projectRoles[projectRoleGeneratedId].role_id;
                const roleName = rolesName[roleId];
                const userRoles = {};

                userRoles[roleName] = userEfficiencyInPercentage;
                userEfficiencies[userInProjectGeneratedId] = UserEfficienciesDataSourceGenerator
                    .getUserEfficiency(userInProject, userRoles, dates, allUsers);
            }
            datasource.push(userEfficiencies);
        }

        return datasource;
    }

    static getFormattedPercentage(date, projectRoleUser) {
        if (Day.isNonWorkingDay(date)) {
            return '-';
        }

        const startDate = (new Date(projectRoleUser.start_date)).setHours(0, 0, 0, 0);
        const endDate = (new Date(projectRoleUser.end_date)).setHours(0, 0, 0, 0);

        const dateFallsWithinSelectedDateRange = date.setHours(0, 0, 0, 0) >= (startDate)
            && date.setHours(0, 0, 0, 0) <= (endDate);

        if (dateFallsWithinSelectedDateRange) {
            return UserEfficienciesDataSourceGenerator.getValueOrDefault(projectRoleUser.mapped_percentage);
        }

        return 0;
    }

    static getUserEfficiency(userInProject, userRoles, dates, allUsers) {
        if (userInProject.user_id) {
            return {
                name: allUsers[userInProject.user_id].name,
                roles: userRoles,
                dates,
                username: allUsers[userInProject.user_id].username,
                shadow: userInProject.shadow
            };
        }
    }

    static getValueOrDefault(percentage) {
        if (percentage) {
            return parseInt(percentage, DECIMAL_RADIX);
        }

        return '-';
    }
}
