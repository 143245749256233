import React, { Component } from "react";
import _ from "underscore";
import { Row, Col } from "react-bootstrap";
import SkillEdit from "./skillsEdit";
import SkillType from "./skillType";
import { DECIMAL_RADIX, MESSAGES } from "../../utils/constants";
import Skill from "../../models/Skill";
import {
  getExpertiseLevel,
  getMonthsOptions,
  getYearsOptions,
} from "../../utils/helperFunctions";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import SkillTable from "./skillTable";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F9CE1F",
    },
  },
});

const toastr = require("toastr");

export default class SkillSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skillType: "",
      workingStatus: "Worked Earlier",
      skillExperience: "",
      expertiseLevel: "",
      currentlyWorking: false,
      years: 0,
      months: 0,
      valid: {
        skillType: 1,
        expertiseLevel: 1,
        skillExperience: 1,
      },
      skill: new Skill({}),
      isUserSuggestedSkill: false,
    };
    this.MSG_ON_NO_ENTRY = MESSAGES.NO_ENTRY;
    this.MSG_ON_EXPERIENCE_LEFT_EMPTY = MESSAGES.EXPERIENCE_LEFT_EMPTY;
    this.MSG_ON_INVALID_DATES = MESSAGES.INVALID_DATES;
    this.SKILL_ALREADY_PRESENT = (skill) =>
      `${skill} skill already exists. Please choose to edit.`;
    _.bindAll(
      this,
      "handleStateAssignment",
      "handleCurrentlyWorking",
      "handleInsertRow",
      "passParametersFromSameComponent",
      "renderUserExperienceGrid",
      "passParametersFromSelectComponent",
      "handleSkillExperienceChange",
      "handleYearsChange",
      "handleMonthsChange",
      "convertSkillExperienceToYears",
      "checkBySkillIdIfSkillPresent",
      "asOptions"
    );
  }

  handleAddNewSkill = (value) => {
    const { skill } = this.state;
    skill.set("skill_type", value);
    this.setState({ isUserSuggestedSkill: true });
  };

  handleMonthsChange(e) {
    const { years } = this.state;
    this.setState({
      months: e.target.value,
    });
    this.handleSkillExperienceChange(years, e.target.value);
  }

  convertSkillExperienceToYears(skillExperience) {
    const years = parseInt(skillExperience / 12, DECIMAL_RADIX);
    const months = skillExperience % 12;
    return {
      years,
      months,
    };
  }

  checkBySkillIdIfSkillPresent(skillToBeChecked) {
    const { usersSkill } = this.props;
    return _.some(
      usersSkill,
      (userSkill) => userSkill.skill.id === skillToBeChecked.id
    );
  }

  handleInsertRow() {
    let validate = true;
    let displayNotice = "";
    const { handleAddSkill } = this.props;
    const {
      expertiseLevel,
      skillType,
      skillExperience,
      currentlyWorking,
      workingStatus,
      isUserSuggestedSkill,
      skill,
    } = this.state;
    const fieldColor = {
      skillType: 1,
      expertiseLevel: 1,
      skillExperience: 1,
    };
    if (expertiseLevel === "") {
      validate = false;
      fieldColor.expertiseLevel = 0;
      displayNotice = this.MSG_ON_NO_ENTRY;
    }
    if (_.isEmpty(skillType)) {
      validate = false;
      fieldColor.skillType = 0;
      displayNotice = this.MSG_ON_NO_ENTRY;
    }
    if (skillExperience === "") {
      validate = false;
      fieldColor.skillExperience = 0;
      displayNotice = this.MSG_ON_EXPERIENCE_LEFT_EMPTY;
    }
    if (skillType === "") {
      validate = false;
      displayNotice = this.MSG_ON_INVALID_DATES;
    }
    if (
      validate &&
      !isUserSuggestedSkill &&
      this.checkBySkillIdIfSkillPresent(skillType)
    ) {
      validate = false;
      displayNotice = this.SKILL_ALREADY_PRESENT(skillType.skill_type);
    }

    this.setState({ valid: fieldColor });
    this.handleStateAssignment("errorInTable", displayNotice, () => {
      if (displayNotice !== "") {
        toastr.error(displayNotice, "Error");
      }
    });
    if (!validate) {
      return;
    }
    this.handleStateAssignment("errorInTable", "");
    this.setState({ skillType: "" });
    this.handleStateAssignment("expertiseLevel", "");
    this.handleStateAssignment("skillExperience", "");
    this.handleStateAssignment("years", 0);
    this.handleStateAssignment("months", 0);
    this.handleStateAssignment("currentlyWorking", false);
    this.handleStateAssignment("workingStatus", "Worked Earlier");

    const newSkill = {
      skill: {},
      skill_experience: skillExperience,
      expertise_level: expertiseLevel || "Beginner",
      currently_working: currentlyWorking,
      working_status: workingStatus,
      experience_from: skillExperience,
    };
    if (isUserSuggestedSkill) {
      skill.create((response) => {
        newSkill.skill = {
          id: response.data.id,
          skill_type: response.data.skill_type,
        };
        handleAddSkill(newSkill);
      });
    } else {
      newSkill.skill = skillType;
      handleAddSkill(newSkill);
    }
  }

  handleCurrentlyWorking(event) {
    this.handleStateAssignment("currentlyWorking", event.target.checked);
    if (event.target.checked) {
      this.handleStateAssignment("workingStatus", "Currently Working");
      return this.handleStateAssignment("experiencedTo", this.currentDate());
    }
    this.handleStateAssignment("workingStatus", "Worked Earlier");
    this.handleStateAssignment("experiencedTo", "");
  }

  handleStateAssignment(key, value, callback) {
    this.setState(
      {
        [key]: value,
      },
      () => {
        if (callback !== undefined) {
          callback();
        }
      }
    );
  }

  currentDate() {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    dd = dd < 10 ? `0${dd}` : dd;
    mm = mm < 10 ? `0${mm}` : mm;
    const date = `${yyyy}-${mm}-${dd}`;
    return date;
  }

  passParametersFromSameComponent(key, event) {
    this.handleStateAssignment("errorInModal", "");
    this.handleStateAssignment(key, event.target.value);
  }

  passParametersFromSelectComponent(key, event) {
    this.handleStateAssignment("errorInModal", "");
    let level = "";
    if (event !== null) {
      level = event.target.value;
      if (key === "years") {
        this.handleSkillExperienceChange(event.target.value, this.state.months);
      } else if (key === "months") {
        this.handleSkillExperienceChange(this.state.years, event.target.value);
      }
      this.handleStateAssignment(key, level);
    }
  }

  asOptions(x = getExpertiseLevel()) {
    return x.map((value) => ({ label: value, value }));
  }

  handleYearsChange(e) {
    const { months } = this.state;
    this.setState({
      years: e.target.value,
    });
    this.handleSkillExperienceChange(e.target.value, months);
  }

  handleSkillExperienceChange(years, months) {
    const totalMonths =
      parseInt(12 * years, DECIMAL_RADIX) + parseInt(months, DECIMAL_RADIX);
    this.setState({
      skillExperience: totalMonths,
    });
  }

  createData(
    skillObject,
    skillName,
    years,
    months,
    expertiseLevel,
    experience_from,
    currentlyWorking
  ) {
    return {
      skillObject,
      skillName,
      years,
      months,
      expertiseLevel,
      experience_from,
      currentlyWorking,
    };
  }

  renderUserExperienceGrid() {
    const { rowEditSkill, usersSkill, handleRemovalOfSkill } = this.props;

    const gridContent = [];
    const rows = [];
    for (const key in usersSkill) {
      const skill = usersSkill[key];
      if ("skill" in skill) {
        const { years, months } = this.convertSkillExperienceToYears(
          skill.skill_experience
        );
        rows.push(
          this.createData(
            skill,
            skill.skill.skill_type,
            years,
            months,
            skill.expertise_level,
            skill.skill_experience,
            skill.currently_working === true
              ? "Currently Working"
              : "Worked earlier"
          )
        );
      }
    }

    return rows;
  }

  renderUserExperienceCreateForm = () => {
    const { skillType, valid, months, years, expertiseLevel } = this.state;

    return (
      <Grid
        container
        spacing={4}
        direction="row"
        justify="center"
        alignItems="flex-start"
      >
        <Grid item sm>
          <SkillType
            valid={valid}
            handleStateAssignment={this.handleStateAssignment}
            skillType={skillType}
            handleAddSkill={this.handleAddNewSkill}
          />
        </Grid>

        <Grid item sm>
          <Col>
            <div>
              <Box display="flex" flexDirection="row">
                <FormControl required variant="outlined">
                  <InputLabel id="years-label">Years</InputLabel>
                  <Select
                    style={{ width: "13ch" }}
                    labelId="years-label"
                    id="years"
                    value={years}
                    onChange={(event) =>
                      this.passParametersFromSelectComponent("years", event)
                    }
                    label="Years"
                  >
                    {getYearsOptions().map((item) => (
                      <MenuItem value={item.value} key={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl required variant="outlined">
                  <InputLabel id="months-label">Months</InputLabel>
                  <Select
                    style={{ width: "13ch" }}
                    labelId="months-label"
                    id="months"
                    onChange={(event) =>
                      this.passParametersFromSelectComponent("months", event)
                    }
                    value={months}
                    label="Months"
                  >
                    {getMonthsOptions().map((item) => (
                      <MenuItem value={item.label} key={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
            <Box>
              <input
                className="checkbox-small"
                type="checkbox"
                checked={this.state.currentlyWorking}
                onChange={this.handleCurrentlyWorking}
              />{" "}
              <span className="font-size-13">Currently Working</span>
            </Box>
          </Col>
        </Grid>

        <Grid item sm>
          <FormControl required variant="outlined">
            <InputLabel id="expertise-label">Expertise Level</InputLabel>
            <Select
              style={{ width: "25ch" }}
              labelId="expertise-label"
              id="expertise"
              onChange={(event) =>
                this.passParametersFromSelectComponent("expertiseLevel", event)
              }
              value={expertiseLevel}
              label="Expertise level"
            >
              {this.asOptions().map((item) => (
                <MenuItem value={item.label} key={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={2}>
          <MuiThemeProvider theme={theme}>
            <Box py={1}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.handleInsertRow}
              >
                Add
              </Button>
            </Box>
          </MuiThemeProvider>
        </Grid>
      </Grid>
    );
  };

  render() {
    return (
      <div>
        {this.renderUserExperienceCreateForm()}
        <br />
        {
          <SkillTable
            skills={this.renderUserExperienceGrid()}
            handleDelete={this.props.handleRemovalOfSkill}
            handleEdit={this.props.rowEditSkill}
            expertiseOptions={this.asOptions()}
          />
        }
      </div>
    );
  }
}
