import $ from "jquery";
import _ from 'underscore';
import BaseModel from "./BaseModel";
import { getApiUrl } from "../utils/serviceUrls";
import { ERROR_CODES, METHOD_CALLS } from "../utils/constants";

const propertyMappings = {
    visa_details: 'visa_details_attributes',
    users_experience: 'users_experience_attributes'
};

const renameKeys = (propertyMappings, model) => Object
    .keys(model)
    .reduce((accumulator, key) => ({
        ...accumulator,
        ...{ [propertyMappings[key] || key]: model[key] }
    }), {});

export default class User extends BaseModel {
    constructor(attributes) {
        super(attributes);
        this.path = '';
    }

    get(key) {
        return super.get(key);
    }

    getProfile(params, successCallback, errorCallback) {
        const success = (response) => {
            this.model = new User(response).model;
            if (successCallback) {
                return successCallback(response);
            }
        };

        return $.ajax({
            url: getApiUrl("GET_PROFILE"),
            headers: this.getAuthHeaders(),
            data: params,
            method: METHOD_CALLS.GET,
            success,
            error: errorCallback
        });
    }

    addSkill(skill) {
        this.model.users_experience.push(skill);
    }

    addVisa(visa) {
        this.model.visa_details.push(visa);
    }

    deleteSkill(uniqueKey) {
        const userSkills = this.model.users_experience;
        for (const key in userSkills) {
            if (`${userSkills[key].skill.skill_type}-${userSkills[key].skill_experience}` === uniqueKey) {
                userSkills[key]._destroy = true;
            }
        }
    }

    //TODO: Fix this to delete certificate
    deleteCertificate(uniqueKey) {
        const userCertificates = this.model.certifications;
        for (const key in userCertificates) {
            if(`${userCertificates[key]}`===uniqueKey){
                userCertificates[key]._destroy = true;
            }
        }
    }

    deleteVisa(uniqueKey) {
        const visa = this.model.visa_details;
        for (const key in visa) {
            if (`${visa[key].country}-${visa[key].type_of_visa}-${visa[key].validity}` === uniqueKey) {
                visa[key]._destroy = true;
            }
        }
    }

    editVisa(updatedVisa, uniqueKey) {
        const visa = this.model.visa_details;
        for (const key in visa) {
            if (`${visa[key].country}-${visa[key].type_of_visa}-${visa[key].validity}` === uniqueKey) {
                visa[key] = updatedVisa;
            }
        }
    }

    editSkill(updatedSkill, uniqueKey) {
        const userSkills = this.model.users_experience;
        for (const key in userSkills) {
            if (`${userSkills[key].skill.skill_type}-${userSkills[key].skill_experience}` === uniqueKey) {
                userSkills[key] = updatedSkill;
            }
        }
    }

    updateProfile(successCallback, errorCallback) {
        const success = (response) => {
            this.model = new User(response).model;
            if (successCallback) {
                return successCallback(response);
            }
        };
        return $.ajax({
            url: getApiUrl("UPDATE_PROFILE", { id: this.model.id }),
            headers: this.getAuthHeaders(),
            data: { user: renameKeys(propertyMappings, this.model) },
            method: METHOD_CALLS.PATCH,
            success,
            error: errorCallback
        });
    }

    addUserIdAndSkillId() {
        const userSkills = this.model.users_experience;
        const updatedUserSkills = [];
        for (const key in userSkills) {
            const userSkill = { ...userSkills[key] };
            userSkill.skill_id = userSkill.skill.id;
            userSkill.user_id = window.loggedInUser.id;
            userSkill.skill_attributes = userSkill.skill;
            delete userSkill.skill;
            updatedUserSkills.push(userSkill);
        }
        this.model.users_experience = updatedUserSkills;
    }

    isMandatoryField(field) {
        return field === 'name' || field === 'total_experience';
    }

    isMandatoryFieldEmpty(field) {
        const value = this.model[field];
        if (_.isString(value)) {
            return _.isEmpty(value);
        }
        return !_.isNumber(value);
    }

    validate() {
        let isValid = true;
        const inputFieldColor = {
            name: '', total_experience: ''
        };
        let errorMessage = "";
        for (const field in this.model) {
            if (this.isMandatoryField(field) && this.isMandatoryFieldEmpty(field)) {
                isValid = false;
                inputFieldColor[field] = "field-with-error";
            }
        }

        if (!isValid) {
            errorMessage = ERROR_CODES.MANDATORY_FIELDS;
            return { errorMessage, inputFieldColor, isValid };
        }

        return { errorMessage, inputFieldColor, isValid };
    }
}
