export const SERVICE_URLS = (key, params) => {
    const routes = {
        /* TODO: Clean URL patterns */
        LOGIN: `/api/authentication/authenticate_user_by_login`,
        LOGIN_BY_GOOGLE: `/api/authentication/authenticate_user_by_google`,
        AUTHENTICATE_USER: `/api/authentication/authenticate_user_token`,
        USERS_WITH_SALES_ROLE: `/api/users/users_with_sales_role`,
        CERTIFICATION_AVAILABLE: `/api/users/certification_available`,
        USER_WITH_SKILL: `/api/users/user_with_skill?`,
        UNASSIGNED_PEOPLE: `/api/users/unassigned_people?`,
        GET_USERS_ASPIRATIONS: `/api/users/users_aspirations`,
        GET_ADMIN_AND_OWNER_USERS: `/api/users/users_with_owner_and_admin_role`,
        GET_ACCESS_ROLES: 'api/users/get_access_roles',
        UPDATE_PROFILE: `/api/users/${params.id}`,
        GET_PROFILE: `/api/users/current_profile_details`,
        GET_USER_WITH_SKILL_LEVEL: `/api/users/user_with_skill_and_available?`,
        GET_CURRENT_STATUS_COUNTS: `/api/users/count_current_status_of_developers?${params.date}`,
        GET_USER_WITH_SKILLS_WITNIN_DATES: `/api/users/get_available_users?`,
        GET_USERS: `/api/users`,
        MERGE_SKILLS: `/api/skills/skills/merge_duplicate_skills`,
        GET_ROLES: `/api/roles/`,
        STAFFING_ROLE: `/api/staffing_role/staffing_role/`,
        GET_USERS_STATISTICS: `api/statistics/get_user_statistics?as_on=${params.asOnDate}`,
        GET_OPEN_ROLES: `api/statistics/open_roles?`,
        GET_USER_PROJECT_STATISTICS: `api/statistics/user_projects`,
        GET_PAGINATED_SKILLS: `/api/skills/skills?page=${params.page}&per_page=${params.per_page}`,
        GET_SKILLS: `/api/skills/skills`,
        ENABLE_SKILL: `/api/skills/skills/enable/${params.id}`,
        DISABLE_SKILL: `/api/skills/skills/disable/${params.id}`,

        GET_CUSTOMER: `/api/customers/${params.id}`,
        GET_CUSTOMERS: `/api/customers`,
        COUNTRIES: `https://restcountries.eu/rest/v2/all`,

        PROJECTS: `/api/customers/${params.customer_id}/projects`,
        GET_PROJECTS: `/api/projects/get_all_projects`,
        GET_ALL_PROJECTS: `/api/projects/all_projects`,
        GET_ALL_PROJECTS_FOR_USER: `/api/projects/get_projects_within_date_range?`,
        GET_PROJECT: `/api/customers/${params.customer_id}/projects/${params.id}`,
        GET_MY_PROJECTS: `/api/projects/view_users_projects`,
        GET_ACTIVE_PROJECTS: `/api/projects/active_projects?`,
        GET_CUSTOMER_PROJECTS: `/api/customers/${params.customer_id}/projects`,
        GET_PROJECT_VACANCIES: `/api/projects/get_skill_vacancies?`
    };
    return routes[key];
};


export function getApiUrl(key, { ...params }) {
    return SERVICE_URLS(key, params);
}
