import React from "react";
import moment from "moment";
import _ from "underscore";
import FaCalendar from "react-icons/lib/fa/calendar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { formatDate } from "../../utils/helperFunctions";

export default class DateSelector extends React.Component {
    constructor(props) {
        super(props);
        _.bindAll(this, 'handleEvent');
    }

    handleEvent(event, picker) {
        const { onDateSelected } = this.props;
        if (onDateSelected) {
            onDateSelected(formatDate(picker.startDate));
        }
    }

    render() {
        let { startDate } = this.props;
        const {
            isButtonBlocked, min, max, textSize
        } = this.props;
        let label = "Select date";
        if (startDate) {
            startDate = new moment(startDate, "YYYY-MM-DD");
            const start = startDate.format("DD-MM-YYYY");
            label = `${start}`;
        }
        const minDate = min ? moment(min) : moment("1947-01-01");
        const maxDate = max ? moment(max) : moment("2025-12-31");
        return (
            <DateRangePicker
                singleDatePicker
                showDropdowns
                startDate={startDate || undefined}
                minDate={minDate}
                maxDate={maxDate}
                onApply={this.handleEvent}
            >
                <button
                    type="button"
                    className={`btn ${textSize || "text-small"} ${isButtonBlocked ? "btn-block btn-raised" : ""}`}
                >
                    <span className="text-center data-font-weight">
                        {label}
                        {' '}
                        <FaCalendar />
                    </span>
                </button>
            </DateRangePicker>
        );
    }
}
