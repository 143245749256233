import React, { Component } from 'react';
import { replace } from "../../utils/helperFunctions";
import { SPECIAL_CHAR_REMOVAL_REGEX } from "../../utils/constants";

export default class ContactPerson extends Component {
    constructor(props) {
        super(props);
        this.handleChangeOfName = this.handleChangeOfName.bind(this);
    }

    handleChangeOfName(event) {
        const { handleChangeInParentComponent } = this.props;
        handleChangeInParentComponent('contact_person',
            replace(SPECIAL_CHAR_REMOVAL_REGEX.CONTACT_PERSON, event.target.value));
    }

    render() {
        const { inputFieldColor: { contact_person: contactPersonFieldColor }, contact_person } = this.props;
        return (
            <div>
                <div>
                    <label className="labels">
                            Contact Person:
                        <span>
                            <i
                                className='field-indicator-color'
                            >
*
                            </i>
                        </span>
                    </label>
                </div>
                <div>
                    <input
                        type="text"
                        name="contact-person"
                        id="contact-person"
                        className={`${contactPersonFieldColor} contact-person`}
                        value={contact_person}
                        onChange={this.handleChangeOfName}
                    />
                </div>
            </div>
        );
    }
}
