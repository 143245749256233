import React from 'react';
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Dialog from "@material-ui/core/Dialog/Dialog";
import { nonAdminSlides, adminSlides } from "./SlidesContent";
import Carousel from "./Carousel";


export default function FirstTimeUser(props) {
    const slides = props.nonAdminUser ? nonAdminSlides : adminSlides;
    const open = props.open === undefined ? false : props.open;
    return (
        <Dialog open={open} className="slider-modal slider-background">
            <DialogContent>
                <Carousel slidesContent={slides} closeOnBoardingSlider={props.closeOnBoardingSlider} />
            </DialogContent>
        </Dialog>
    );
}
