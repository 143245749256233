import $ from "jquery";
import HttpStatus from "http-status-codes";
import axios from "axios";
import BaseCollection from "./BaseCollection";
import User from "../User";
import { ERROR_CODES } from "../../utils/constants";
import { getApiUrl } from "../../utils/serviceUrls";

export default class Users extends BaseCollection {
    getModel(attributes) {
        return new User(attributes);
    }

    getModelToJson(project) {
        return project.model;
    }

    fetchUsersWithSkills(params, callback) {
        axios.get((getApiUrl("USER_WITH_SKILL") + $.param(params)), { headers: super.getAuthHeaders() })
            .then((response) => {
                callback(response.data);
            });
    }

    getUsersWithSkillsWithinDates(params, callback) {
        axios.get((getApiUrl("GET_USER_WITH_SKILLS_WITNIN_DATES") + $.param(params)),
            { headers: super.getAuthHeaders() })
            .then((response) => {
                callback(response.data);
            });
    }

    getAllUsers(callback) {
        axios.get(getApiUrl("GET_USERS"),
            { headers: super.getAuthHeaders() })
            .then((response) => {
                callback(new Users(response.data));
            });
    }

    getUsersWithPrivilegedRole(callback) {
        axios.get(getApiUrl("GET_ADMIN_AND_OWNER_USERS"),
            { headers: super.getAuthHeaders() })
            .then((response) => {
                callback(new Users(response.data));
            });
    }

    getWithSalesRole(callback) {
        axios.get(getApiUrl("USERS_WITH_SALES_ROLE"),
            { headers: super.getAuthHeaders() })
            .then((response) => {
                callback(response);
            });
    }

    getUsersWithSkills(params, callback) {
        const apiUrl = getApiUrl("GET_USER_WITH_SKILL_LEVEL") + $.param({ skills: params.skills, date: params.date });
        axios.get(apiUrl,
            { headers: super.getAuthHeaders() })
            .then((response) => {
                this.collection = new Users(response.data).collection;
                if (callback) {
                    return callback(response.data);
                }
            });
    }

    fetchUnassignedUsers({ method, headers, params }, successCallback, errorCallback) {
        const customHeaders = { ...super.getAuthHeaders(), ...headers };

        const options = {
            method,
            headers: customHeaders,
            body: params
        };

        return fetch(this.path, options).then((response) => {
            if (response.status !== HttpStatus.OK && response.status !== HttpStatus.CONFLICT) {
                return;
            }
            if (successCallback) {
                return response.json().then(responseJSON => successCallback(responseJSON));
            }
        }).catch(error => errorCallback(ERROR_CODES.UNKNOWN_ERROR, error));
    }

    getUsers() {
        return this.getCollectionToJson();
    }
}
