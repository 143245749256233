import BaseCollection from "./BaseCollection";
import ProjectRoleSkill from "../ProjectRoleSkill";

export default class ProjectRoleSkills extends BaseCollection {
    getModel(attributes) {
        return new ProjectRoleSkill(attributes);
    }

    getModelToJson(projectRoleSkill) {
        return projectRoleSkill.model;
    }

    hasProjectRoleSkill(skill) {
        return this.collection.some(
            projectRoleSkill => projectRoleSkill.hasSkillType(skill.get('skill_type'))
        );
    }

    add(skill) {
        const projectRoleSkill = new ProjectRoleSkill({ skill_id: skill.get('id'), skill: skill.model });
        this.collection.push(projectRoleSkill);
    }

    remove(index, skill) {
        const modelToBeRemove = { ...skill.model, _destroy: true };
        const removedSkill = new ProjectRoleSkill(modelToBeRemove);
        this.collection[index] = removedSkill;
    }

    delete(index) {
        const skillToBeRemoved = this.collection[index];
        if (skillToBeRemoved.get('id')) {
            skillToBeRemoved.set('_destroy', true);
            return;
        }
        this.collection.splice(index, 1);
    }

    areEmpty() {
        const projectRoleSkills = this.collection.filter(projectRoleSkill => !projectRoleSkill.get('_destroy'));
        return !projectRoleSkills.length;
    }

    getSkillIds() {
        return this.collection.map(model => model.get('skill').get('id'));
    }
}
