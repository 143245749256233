import _ from 'underscore';
import moment from "moment";
import BaseModel from "./BaseModel";
import { isEmptyString } from "../utils/helperFunctions";
import Day from "../utils/day";

export default class ProjectRoleUser extends BaseModel {
    isEmpty() {
        return this.model.user_id === "";
    }

    isDateRangeNullOrEmpty() {
        const startDate = this.model.start_date;
        const endDate = this.model.end_date;
        return (isEmptyString(startDate) || isEmptyString(endDate) || _.isNull(startDate) || _.isNull(endDate));
    }

    startDateComesBefore(date) {
        return new moment(this.model.start_date) < new moment(date);
    }

    endDateComesAfter(date) {
        return new moment(this.model.end_date) > new moment(date);
    }

    startDateComesAfterEndDate() {
        return new moment(this.model.start_date) > new moment(this.model.end_date);
    }

    canDeleteUser = () => {
        const startDate = new moment(this.get('start_date'));
        const todaysDate = new moment(Day.getTodaysDate());
        return startDate >= todaysDate;
    }

    isNewUser = () => !this.get('id')
}
