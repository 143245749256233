import React from 'react';

const Label = (props) => {
    const {
        children, htmlFor, required, displayInline, className
    } = props;
    let display = "";
    if (!displayInline) {
        display = "display-block";
    }
    return (
        (
            <label className={`labels ${`${display}  ${className}`}`} htmlFor={htmlFor || ""}>
                {children}
                { required && (
                    <span>
                        <i className='field-indicator-color'>*</i>
                    </span>
                ) }
            </label>
        )
    );
};

export default Label;
