import React from 'react';
import { Button, Modal, Header } from 'semantic-ui-react';

const CustomModal = (props) => {
    const {
        open, header, content, warningText, handleClose, handleSave, actionBtnText, dialogClassName
    } = props;
    return (
        <Modal
            className={dialogClassName}
            open={open}
        >
            <Modal.Header>{header}</Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                    <Header>{content}</Header>
                    <p><em>{warningText}</em></p>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button className='btn-link' labelPosition='right' onClick={handleClose} content='Close' />
                <Button className='btn-primary' onClick={handleSave}>{actionBtnText}</Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CustomModal;
