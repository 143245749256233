import _ from 'underscore';
import BaseCollection from "./BaseCollection";

export default class ProjectRolesGroups extends BaseCollection {
    findIndexOfProjectRoleWith(key) {
        key.projectRoleSkills.sort();
        const collection = this.collection;
        for (let index = 0; index < collection.length; index += 1) {
            if (collection[index][0]) {
                const skillIds = collection[index][0].get('project_role_skills').getSkillIds();
                const projectRoleKey = {
                    roleId: collection[index][0].get('role_id'),
                    projectRoleSkills: skillIds.sort()
                };
                if (_.isEqual(projectRoleKey, key)) {
                    return index;
                }
            }
        }
        return -1;
    }
}
