import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Row, Col } from 'react-bootstrap';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';

function Carousel(props) {
    const { slidesContent } = props;
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = slidesContent.length;

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function handleStepChange(step) {
        setActiveStep(step);
    }
    const isFirstSlide = activeStep === 0;
    const isLastSlide = activeStep === maxSteps - 1;
    return (
        <Row className="first-time-user-slider">
            <Col md={2} className="navigation-link-wrapper">
                {!isFirstSlide && (
                    <Button
                        size="small"
                        className="navigation-button"
                        onClick={handleBack}
                        data-test="left-arrow"
                    >
                        <img className="img" src="/left-arrow.svg" alt="left" />
                    </Button>
                )
                }
            </Col>
            <Col md={8} className="slides">
                <Row>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                    >
                        {slidesContent.map(({
                            img, label, header, subheader, message
                        }, index) => (
                            Math.abs(activeStep - index) <= 2 ? (
                                <div className="onboarding-slide" key={index}>
                                    <img src={img} alt={label} />
                                    <section className="message">
                                        <h3>{header}</h3>
                                        <h5>{subheader}</h5>
                                        <blockquote>
                                            <p>
                                                {message}
                                            </p>
                                        </blockquote>
                                    </section>
                                </div>
                            ) : null
                        ))}
                    </SwipeableViews>
                </Row>

                <Row className="stepper-wrapper">
                    <MobileStepper
                        variant="dots"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                    />
                </Row>
            </Col>
            <Col md={2} className="navigation-link-wrapper">
                {isLastSlide
                    ? (
                        <Button className="start-button" data-test="start-button" onClick={props.closeOnBoardingSlider}>
                        LETS START
                        </Button>
                    )
                    : (
                        <Button
                            size="small"
                            onClick={handleNext}
                            className="navigation-button"
                            data-test="right-arrow"
                        >
                            <img className="img" src="/right-arrow.svg" alt="right" />
                        </Button>
                    )
                }
            </Col>
        </Row>
    );
}

export default Carousel;
