import React from 'react';
import {
    ListGroup, ListGroupItem
} from "react-bootstrap";
import moment from 'moment';
import { formatDate } from "../../utils/helperFunctions";
import { MAX_SKILLS_CHAR_LENGTH } from "../../utils/constants";

const constructShowUrl = project => `/customers/${project.customer_id}/view-projects/${project.project_id}`;

const redirectToProjectView = (selectedProject, history, location) => {
    const url = constructShowUrl(selectedProject);
    const lastPageUrl = location.pathname;
    history.push(`${url}`, { lastPageUrl });
};

const ProjectDetails = (props) => {
    const { selectedProject, history, location } = props;
    const { skills = [] } = selectedProject;
    let joinedSkills = skills.join(", ");
    const skillsCharacterLength = joinedSkills.length;
    const showViewMoreLink = skillsCharacterLength > MAX_SKILLS_CHAR_LENGTH;
    const startIndex = 0;
    if (showViewMoreLink) {
        joinedSkills = joinedSkills.slice(startIndex, MAX_SKILLS_CHAR_LENGTH);
    }
    return (
        <ListGroup
            className="project-details"
            onClick={() => redirectToProjectView(selectedProject, history, location)}
        >
            <ListGroupItem>{selectedProject.status}</ListGroupItem>
            <ListGroupItem>
                {selectedProject.name}
            </ListGroupItem>
            <ListGroupItem>
                {selectedProject.teamSize}
            &nbsp;
                <img src="./project_role_count.svg" alt="team" />
            </ListGroupItem>
            <ListGroupItem>
                {formatDate(new moment(selectedProject.startDate))}
            &nbsp; - &nbsp;
                {formatDate(new moment(selectedProject.endDate))}
            </ListGroupItem>
            <ListGroupItem>
                {joinedSkills}
            &nbsp;
                {showViewMoreLink
                    ? (<a className="view-more" href="">...view more</a>) : ""
                }
            </ListGroupItem>
        </ListGroup>
    );
};

export default ProjectDetails;
