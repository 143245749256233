import React from 'react';
import Select from 'react-select';
import X from "react-icons/lib/go/x";
import _ from 'underscore';
import { Row, Col, FormGroup } from 'react-bootstrap';
import Roles from "../../../models/collections/Roles";
import AppComponent from "../../AppComponent";
import { getValueOrDefault, numberOfRoles } from "../../../utils/helperFunctions";
import DateSelector from "../../common/DateSelector";
import Skill from "../../../models/Skill";
import SkillSelect from '../../common/Select';
import Skills from "../../../models/collections/Skills";
import Label from "../../common/Label";

export default class ProjectRoleGroupRow extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            roles: new Roles([])
        };
    }

    componentWillMount() {
        const { roles } = this.state;
        roles.getRoles((response) => {
            const updatedRoles = new Roles(response);
            this.setState({ roles: updatedRoles });
        });
    }

    handleRoleStartDateChange = (startDate) => {
        this.props.projectRoleGroup.projectRoles.collection.forEach((projectRole) => {
            projectRole.set("role_start_date", startDate);
        });
        this.props.updateProjectRoleGroups();
    };

    handleRoleEndDateChange = (endDate) => {
        this.props.projectRoleGroup.projectRoles.collection.forEach((projectRole) => {
            projectRole.set('role_end_date', endDate);
        });
        this.props.updateProjectRoleGroups();
    };

    handleNumberOfProjectRolesChange = ({ value }) => {
        const { projectRoleGroup } = this.props;
        projectRoleGroup.numberOfProjectRoles = value;
        this.props.updateProjectRoleGroups();
    };

    convertSkillsToOptions = (skills) => {
        const filteredSkills = skills.filter(skill => !skill.get('_destroy'));
        const validSkills = filteredSkills.filter(skill => skill.get('skill') !== undefined);
        const options = validSkills.map(skill => skill.get('skill').get('id'));
        return options;
    };

    onAddItem = (e, { value }) => {
        this.handleAddSkill(value);
    };

    handleAddSkill = (value) => {
        const skill = new Skill({ skill_type: value });
        skill.create((response) => {
            this.props.projectRoleGroup.projectRoles.collection.forEach((projectRole) => {
                const projectRoleSkills = projectRole.get('project_role_skills');
                projectRoleSkills.add(new Skill(response.data));
            });
            this.props.updateProjectRoleGroups();
            this.props.fetchSkills();
        });
    };

    getSkillsByIds = (ids) => {
        const filteredIds = ids.filter(id => !this.isUserSuggestedSkill(id), this);
        const skills = filteredIds.map((id) => {
            const { allSkills } = this.props;
            const filteredSkill = allSkills.find(id);
            return filteredSkill;
        }, this);
        return new Skills(skills);
    };

    addSkillsForProjectRole = (projectRole, updatedSkills) => {
        const projectRoleSkills = projectRole.get('project_role_skills');
        const iterator = updatedSkills.iterator();
        for (let nextValue = iterator.next(); nextValue.done !== true; nextValue = iterator.next()) {
            const skill = nextValue.value;
            const isProjectRoleSkillPresent = projectRoleSkills.hasProjectRoleSkill(skill);
            if (!isProjectRoleSkillPresent) {
                projectRoleSkills.add(skill);
                projectRole.set('project_role_skills', projectRoleSkills);
            }
        }
    };

    deleteSkillsInProjectRole = (projectRole, updatedSkills) => {
        const projectRoleSkills = projectRole.get('project_role_skills');
        const iterator = projectRoleSkills.iterator();
        for (let nextValue = iterator.next(), skillIndex = 0;
            nextValue.done !== true;
            nextValue = iterator.next(), skillIndex += 1) {
            const projectRoleSkill = nextValue.value;
            const isSkillPresent = updatedSkills.hasSkill(projectRoleSkill);
            if (!isSkillPresent) {
                projectRoleSkills.delete(skillIndex);
                projectRole.set('project_role_skills', projectRoleSkills);
            }
        }
    };

    handleSkillChange = (event, { value }) => {
        const updatedSkills = this.getSkillsByIds(value);
        const { projectRoleGroup } = this.props;
        projectRoleGroup.projectRoles.collection.forEach((projectRole) => {
            this.deleteSkillsInProjectRole(projectRole, updatedSkills);
            this.addSkillsForProjectRole(projectRole, updatedSkills);
        });
        this.props.updateProjectRoleGroups();
    };

    isUserSuggestedSkill = value => _.isString(value);

    deleteProjectRole = () => {
        const { index } = this.props;
        this.props.deleteProjectRoleGroup(index);
    };

    setValueOrDefaultEndDate = () => {
        const { project, projectRoleGroup } = this.props;
        const currentProjectRole = projectRoleGroup.projectRoles.get(0);
        const endDate = getValueOrDefault(project.get('actual_end_date'), project.get('tentative_end_date'));
        const roleEndDate = getValueOrDefault(currentProjectRole.get('role_end_date'), endDate);
        const projectRoles = projectRoleGroup.projectRoles;
        projectRoles.collection.forEach((projectRole) => {
            projectRole.set("role_end_date", roleEndDate);
        });
    };

    setValueOrDefaultRoleStartDate = () => {
        const { project, projectRoleGroup } = this.props;
        const currentProjectRole = projectRoleGroup.projectRoles.get(0);
        const startDate = project.getValueOrDefault(project.get('actual_start_date'),
            project.get('tentative_start_date'));
        const roleStartDate = getValueOrDefault(currentProjectRole.get('role_start_date'), startDate);
        const projectRoles = projectRoleGroup.projectRoles;
        projectRoles.collection.forEach((projectRole) => {
            projectRole.set("role_start_date", roleStartDate);
        });
    }

    render() {
        const {
            projectRoleGroup, roleId, skillsOptions, project
        } = this.props;
        let projectRoleSkills = [];
        const projectRole = projectRoleGroup.projectRoles.get(0);
        if (projectRole.get('project_role_skills')) {
            projectRoleSkills = projectRole.get('project_role_skills');
        }
        const { roles } = this.state;
        const rolesMap = roles.getRolesMap();
        this.setValueOrDefaultRoleStartDate();
        this.setValueOrDefaultEndDate();
        const minRoleEndDate = projectRole.get('role_start_date')
            ? projectRole.get('role_start_date') : project.get("actual_start_date")
            || project.get('tentative_start_date');
        return (
            <FormGroup>
                <Row>
                    <Col md={2} className="vertical-align-center">
                        <span>{rolesMap.get(roleId)}</span>
                    </Col>
                    <Col md={1}>
                        <Select
                            options={numberOfRoles()}
                            value={projectRoleGroup.numberOfProjectRoles}
                            onChange={this.handleNumberOfProjectRolesChange}
                            clearable={false}
                            data-test="numberOfRoles"
                        />
                    </Col>
                    <Col md={3}>
                        <SkillSelect
                            options={skillsOptions}
                            placeholder="Choose Skill"
                            search
                            selection
                            fluid
                            allowAdditions
                            value={this.convertSkillsToOptions(projectRoleSkills)}
                            onAddItem={this.onAddItem}
                            onChange={this.handleSkillChange}
                            multiple
                            data-test="skills"
                        />
                    </Col>
                    <Col md={2}>
                        <DateSelector
                            onDateSelected={this.handleRoleStartDateChange}
                            onClear={this.clearDate}
                            startDate={projectRole.get('role_start_date')}
                            isButtonBlock={false}
                            min={project.get("actual_start_date") || project.get('tentative_start_date')}
                            max={project.get("actual_end_date") || project.get('tentative_end_date')}
                            data-test='roleStartDate'
                        />
                    </Col>
                    <Col md={2}>
                        <DateSelector
                            onDateSelected={this.handleRoleEndDateChange}
                            onClear={this.clearDate}
                            startDate={projectRole.get('role_end_date')}
                            isButtonBlock={false}
                            min={minRoleEndDate}
                            max={project.get("actual_end_date") || project.get('tentative_end_date')}
                            data-test='roleEndDate'
                        />
                    </Col>
                    <Col md={1}>
                        <Label data-test="capacity">100%</Label>
                    </Col>
                    <Col md={1}>
                        <button
                            data-test="deleteProjectRoleGroup"
                            title="Delete role"
                            type="button"
                            className="edit-action-btn btn align-center"
                            onClick={this.deleteProjectRole}
                        >
                            <X />
                        </button>
                    </Col>
                </Row>
            </FormGroup>
        );
    }
}
