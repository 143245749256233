import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import { getMonthsOptions, getYearsOptions } from "../../utils/helperFunctions";

import MenuItem from "@material-ui/core/MenuItem";
import { Row, Col } from "react-bootstrap";

export default function SkillEditDialog(props) {
  const findWorkingStatus = () => {
    return props.row.currentlyWorking === "Currently Working" ? true : false;
  };
  const [open, setOpen] = React.useState(false);
  const [year, setYearChange] = React.useState(props.row.years);
  const [month, setMonthChange] = React.useState(props.row.months);
  const [expertiseLevel, setExpertiseChange] = React.useState(
    props.row.expertiseLevel
  );
  const [currentlyWorking, setCurrentWorkingStatus] = React.useState(
    findWorkingStatus()
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYearChange = (event) => {
    setYearChange(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonthChange(event.target.value);
  };

  const handleExpertiseChange = (event) => {
    setExpertiseChange(event.target.value);
  };

  const handleCurrentWorkingStatus = (event) => {
    setCurrentWorkingStatus(event.target.checked);
  };

  const handleSkillExperienceChange = (years, months) => {
    return parseInt(12 * years) + parseInt(months);
  };

  const setWorkingStatus = () => {
    return currentlyWorking === true ? "Currently Working" : "Worked Earlier";
  };

  const handleEditSkill = () => {
    let skillObject = props.row.skillObject;
    let tempId =
      skillObject.skill.skill_type -
      handleSkillExperienceChange(props.row.years, props.row.months);
    skillObject.skill_experience = handleSkillExperienceChange(year, month);
    skillObject.expertise_level = expertiseLevel;
    skillObject.currently_working = currentlyWorking;
    skillObject.working_status = setWorkingStatus();

    props.editSkill(skillObject, tempId);
    setOpen(false);
  };

  return (
    <div>
      <EditIcon onClick={handleClickOpen} />{" "}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Edit Skill: {" " + props.row.skillName}
        </DialogTitle>
        <DialogContent>
          <Row>
            <Col>
              <Col>
                <div>
                  <Box display="flex" flexDirection="row" px="5ch">
                    <FormControl required variant="outlined">
                      <InputLabel id="years-label">Years</InputLabel>
                      <Select
                        style={{ width: "13ch" }}
                        labelId="years-label"
                        id="years"
                        value={year}
                        onChange={handleYearChange}
                        label="Years"
                      >
                        {getYearsOptions().map((item) => (
                          <MenuItem value={item.value} key={item.label}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl required variant="outlined">
                      <InputLabel id="months-label">Months</InputLabel>
                      <Select
                        style={{ width: "13ch" }}
                        labelId="months-label"
                        id="months"
                        onChange={handleMonthChange}
                        value={month}
                        label="Months"
                      >
                        {getMonthsOptions().map((item) => (
                          <MenuItem value={item.label} key={item.label}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
                <Box px="5ch">
                  <div>
                    <input
                      className="checkbox-small"
                      type="checkbox"
                      checked={currentlyWorking}
                      onChange={handleCurrentWorkingStatus}
                    />{" "}
                    <span className="font-size-13">Currently Working</span>
                  </div>
                </Box>
              </Col>
            </Col>

            <Col>
              <Box px="5ch">
                <FormControl required variant="outlined">
                  <InputLabel id="expertise-label">Expertise Level</InputLabel>
                  <Select
                    style={{ width: "25ch" }}
                    labelId="expertise-label"
                    id="expertise"
                    onChange={handleExpertiseChange}
                    value={expertiseLevel}
                    label="Expertise level"
                  >
                    {props.expertiseOptions.map((item) => (
                      <MenuItem value={item.label} key={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSkill} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
