import React, { Component } from 'react';
import _ from 'underscore';
import { SPECIAL_CHAR_REMOVAL_REGEX, PATTERNS, ERROR_CODES } from "../../utils/constants";
import Input from "../common/Input";

export default class Url extends Component {
    constructor(props) {
        super(props);
        this.state = {
            twitterValidationError: false,
            linkedInValidationError: false,
            blogValidationError: false
        };
        _.bindAll(this, 'handleChangeOfBlogUrl', 'handleChangeOfLinkedinUrl', 'handleChangeOfTwitterUrl');
        this.blogInfo = "Share your full Url including the protocol(http/https)";
        this.socialInfo = "Share your full Profile including the protocol(http/https)";
    }

    validateBlogUrl = (event) => {
        const { value: url } = event.target;
        if (url.match(PATTERNS.BLOG) != null || url === "") {
            this.setState({ blogValidationError: false });
            return;
        }
        this.setState({ blogValidationError: true });
    }

    validateTwitterUrl = (event) => {
        const { value: url } = event.target;
        if (url.match(PATTERNS.TWITTER) != null || url === "") {
            this.setState({ twitterValidationError: false });
            return;
        }
        this.setState({ twitterValidationError: true });
    }

    validateLinkedInUrl = (event) => {
        const { value: url } = event.target;
        if (url.match(PATTERNS.LINKEDIN) != null || url === "") {
            this.setState({ linkedInValidationError: false });
            return;
        }
        this.setState({ linkedInValidationError: true });
    }

    isAllUrlsValid = () => (!this.state.linkedInValidationError
        && !this.state.twitterValidationError && !this.state.blogValidationError)

    handleChangeOfBlogUrl(event) {
        const { handleModelChange } = this.props;
        handleModelChange("blog_url",
            event.target.value.replace(SPECIAL_CHAR_REMOVAL_REGEX.BLOG_URL, ""));
    }

    handleChangeOfLinkedinUrl(event) {
        const { handleModelChange } = this.props;
        handleModelChange("linkedin_url",
            event.target.value.replace(SPECIAL_CHAR_REMOVAL_REGEX.LINKEDIN_URL, ""));
    }

    handleChangeOfTwitterUrl(event) {
        const { handleModelChange } = this.props;
        handleModelChange("twitter_url",
            event.target.value.replace(SPECIAL_CHAR_REMOVAL_REGEX.TWITTER_URL, ""));
    }


    render() {
        const { linkedInValidationError, twitterValidationError, blogValidationError } = this.state;
        const {
            blogUrl, twitterUrl, linkedinUrl, handleInputChange
        } = this.props;
        return (
            <div id="url" className="margin-top-2 ">
                <Input
                required = {false}
                    handleChange={handleInputChange}
                    name="blog_url"
                    value={blogUrl}
                    type="text"
                    title="Blog Url"
                    autoComplete={false}
                    placeholder={this.blogInfo}
                    className="url"
                    onBlur={this.validateBlogUrl}
                    validationErrorPresent={blogValidationError}
                    message={ERROR_CODES.BLOG_URL_INVALID}
                />
                <br></br>
                <Input
                        handleChange={handleInputChange}
                        name="twitter_url"
                        value={twitterUrl}
                        type="text"
                        title="Twitter Url"
                        autoComplete={false}
                        pattern={PATTERNS.TWITTER}
                        placeholder={this.socialInfo}
                        className="url"
                        onBlur={this.validateTwitterUrl}
                        validationErrorPresent={twitterValidationError}
                        message={ERROR_CODES.TWITTER_URL_INVALID}
                    />
               <br></br>
                    <Input
                        handleChange={handleInputChange}
                        name="linkedin_url"
                        value={linkedinUrl || ''}
                        type="text"
                        title="Linkedin Url"
                        autoComplete={false}
                        pattern={PATTERNS.LINKEDIN}
                        placeholder={this.socialInfo}
                        className="url"
                        onBlur={this.validateLinkedInUrl}
                        validationErrorPresent={linkedInValidationError}
                        message={ERROR_CODES.LINKEDIN_URL_INVALID}
                    />
               
            </div>
        );
    }
}
