import React from 'react';
import Select from "react-select";
import { Col, Row, FormGroup } from 'react-bootstrap';
import DateSelector from "../../common/DateSelector";
import AppComponent from "../../AppComponent";
import Input from "../../common/Input";
import Label from "../../common/Label";

export default class ProjectDetails extends AppComponent {
    convertToOptions = () => {
        const { customers } = this.props;
        const options = customers.map(customer => ({
            label: customer.get('name'),
            value: customer.get('id')
        }));
        return options;
    };

    handleOnChangeOfInputName = (key, event) => {
        const { handleOnChangeOfDetails } = this.props;
        handleOnChangeOfDetails(key, event.target.value);
    };

    handleCustomerChange = (key, { value }) => {
        const { handleOnChangeOfDetails } = this.props;
        handleOnChangeOfDetails(key, value);
    };

    handleOnChangeOfInputDate = (key, value) => {
        const TentativeStartDate = "tentative_start_date";
        const TentativeEndDate = "tentative_end_date";
        const ActualStartDate = "actual_start_date";
        const ActualEndDate = "actual_end_date";
        const { project, handleOnChangeOfDetails } = this.props;
        if (key === ActualStartDate) {
            if (project.isTentativeDateEmpty(TentativeStartDate)) {
                handleOnChangeOfDetails(TentativeStartDate, value);
            }
        } else if (key === ActualEndDate) {
            if (project.isTentativeDateEmpty(TentativeEndDate)) {
                handleOnChangeOfDetails(TentativeEndDate, value);
            }
        }
        handleOnChangeOfDetails(key, value);
    };


    render() {
        const {
            project, isCustomerSelected
        } = this.props;
        return (
            <React.Fragment>
                <Row className="section">
                    <Col md={3} xs={5}>
                        <Input
                            handleChange={event => this.handleOnChangeOfInputName("project_name", event)}
                            name="name"
                            value={project.get('project_name')}
                            title="Project Name"
                            required
                        />
                    </Col>
                    <Col md={1} xs={5} />
                    <Col md={3} xs={5}>
                        <FormGroup>
                            <Label data-test="customerName" required>Customer Name</Label>
                            <Select
                                options={this.convertToOptions()}
                                onChange={event => this.handleCustomerChange("customer_id", event)}
                                value={project.get('customer_id')}
                                placeholder="Select customer"
                                className="text-font"
                                clearable={false}
                                disabled={isCustomerSelected}
                                data-test="customerName"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="section">
                    <Col md={2} xs={5}>
                        <FormGroup>
                            <Label data-test="tentativeStartDate" required>Tentative Start date</Label>
                            <DateSelector
                                onDateSelected={date => this.handleOnChangeOfInputDate("tentative_start_date", date)}
                                onClear={this.clearDate}
                                startDate={project.get('tentative_start_date')}
                                isButtonBlock={false}
                                data-test="tentativeStartDate"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2} xs={5}>
                        <FormGroup>
                            <Label data-test="tentativeEndDate">Tentative End date</Label>
                            <DateSelector
                                min={project.get('tentative_start_date')}
                                onDateSelected={date => this.handleOnChangeOfInputDate("tentative_end_date", date)}
                                onClear={this.clearDate}
                                startDate={project.get('tentative_end_date')}
                                isButtonBlock={false}
                                data-test="tentativeEndDate"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2} xs={5}>
                        <FormGroup>
                            <Label data-test="actualStartDate">Actual Start date</Label>
                            <DateSelector
                                onDateSelected={date => this.handleOnChangeOfInputDate("actual_start_date", date)}
                                onClear={this.clearDate}
                                startDate={project.get('actual_start_date')}
                                isButtonBlock={false}
                                data-test="actualStartDate"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={2} xs={5}>
                        <FormGroup>
                            <Label data-test="actualEndDate">Actual End date</Label>
                            <DateSelector
                                min={project.get('actual_start_date')}
                                onDateSelected={date => this.handleOnChangeOfInputDate("actual_end_date", date)}
                                onClear={this.clearDate}
                                startDate={project.get('actual_end_date')}
                                isButtonBlock={false}
                                data-test="actualEndDate"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
