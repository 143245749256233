import BaseModel from "../BaseModel";

export default class BaseCollection {
    constructor(collection) {
        this.collection = collection.map((element) => {
            if (!(element instanceof BaseModel)) {
                return (this.getModel(element));
            }
            return (element);
        });
    }

    get(index) {
        return this.collection[index];
    }

    set(index, model) {
        this.collection[index] = model;
    }

    getSize() {
        return this.collection.length;
    }

    setPath(url) {
        this.path = url;
    }

    getCollectionToJson() {
        const collectionJson = [];
        for (let index = 0; index < this.collection.length; index += 1) {
            const element = this.collection[index];
            collectionJson.push(element.getJson());
        }
        return collectionJson;
    }

    add(model) {
        this.collection.push(model);
    }

    update(index, model) {
        this.collection[index] = model;
    }

    getAuthHeaders() {
        return {
            Authorization: window.loggedInUser.auth_token
        };
    }

    map(callbackFunction) {
        const collection = [...this.collection];
        if (collection.length) {
            return collection.map(callbackFunction);
        }
    }

    sortBy(field, order, primer) {
        this.collection.sort((a, b) => primer(a.model[field]) - primer(b.model[field]));
    }

    filter(callbackFunction) {
        const collection = [...this.collection];
        return collection.filter(callbackFunction);
    }

    find(id) {
        if (this.collection.length) {
            return this.collection.find(model => model.get('id') === id);
        }
    }

    iterator() {
        const { collection } = this;
        let currentIndex = -1;
        return {
            next: () => {
                currentIndex += 1;
                return currentIndex < collection.length ? {
                    value: collection[currentIndex],
                    done: false
                } : {
                    value: "Index out of range",
                    done: true
                };
            },
            previous: () => {
                currentIndex -= 1;
                return currentIndex >= 0 ? {
                    value: collection[currentIndex],
                    done: false
                } : {
                    value: "Index out of range",
                    done: true
                };
            }
        };
    }
}
