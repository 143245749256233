import React from 'react';
import { Col } from "react-bootstrap";

const NoUserProjectsFound = () => (
    <React.Fragment>
        <Col xs={1} md={1} />
        <Col xs={5} md={5}>
            <img src="/no_user_projects.svg" alt="No records found" />
        </Col>
        <Col
            xs={4}
            md={4}
            className="no-user-project-found-text"
        >
            <span className="part-1">
Looks like you have not been assigned to any project yet.
Check out all the exciting projects going on right now.
            </span>
            <br />
            <span className="part-2">Contact your staffing manager to know more.</span>
        </Col>
    </React.Fragment>
);

export default NoUserProjectsFound;
