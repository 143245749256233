import React from 'react';

export default class Error403 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            url: ''
        };
        this.login = this.login.bind(this);
    }

    login() {
        this.props.history.push(this.state.url, null);
    }

    componentWillMount() {
        if (window.loggedInUser) {
            this.setState({
                message: "DashBoard",
                url: '/dashboard'
            });
        } else {
            this.setState({
                message: "Login",
                url: '/login'
            });
        }
    }

    render() {
        return (
            <div>
                <h1 className='margin-top-10 margin-left-10'>Sorry</h1>
                <h3 className='margin-top-1 margin-left-10'>Access Denied!</h3>
                <button className='btn btn-primary margin-top-1 margin-left-10' onClick={this.login}>
Go to
                    {this.state.message}
..
                </button>
            </div>
        );
    }
}
