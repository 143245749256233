export const DECIMAL_RADIX = 10;

export const ROLES = {
    ADMIN: "admin",
    USER: "user",
    OWNER: "owner"
};
export const PATTERNS = {
    LINKEDIN: "(?:http(s):\/\/)?(?:www\.)?linkedin\.com\/in\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)",
    TWITTER: "(?:http(s):\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)",
    BLOG: "^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$" // eslint-disable-line max-len
};

export const SPECIAL_CHAR_REMOVAL_REGEX = {
    EMAIL: /[^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}]/g,
    NAME: /[^a-zA-Z0-9\-\&\.\,\*\' ]/g,
    CONTACT_PERSON: /[^a-zA-Z ]/g,
    RESUME_URL: /[^a-zA-Z0-9\-\:\/\.\_]/g,
    BLOG_URL: /[\(\)\{\[\]\<\> \`\~\!\|\\\,\'\"\ }]/g,
    LINKEDIN_URL: /[^a-zA-Z0-9\-\:\/\.\_]/g,
    TWITTER_URL: /[^a-zA-Z0-9\:\/\.\_]/g

};

export const ERROR_CODES = {
    USER_ALREADY_MAPPED: " is already mapped in this project. Please select another user.",
    USER_OVERSTAFFED: "Some users mapped in this project are not available. Please select some other user.",
    INCORRECT_ROLE_START_DATE: "Role start date cannot be less than project start date.",
    INCORRECT_ROLE_END_DATE: "Role end date cannot be greater than project end date.",
    UNKNOWN_ERROR: "Unknown error. Please try again later.",
    INVALID_EMAIL: "Please enter a valid email.",
    MANDATORY_FIELDS: "Please enter all mandatory fields.",
    ROLE_START_AND_END_DATE: "Role start date should be greater than tentative start date and role end date "
        + "should be less then tentative end date.",
    TENTATIVE_END_DATE: "Tentative end date should be greater than tentative start date.",
    ACTUAL_END_DATE: "Actual end date should be greater than actual start date.",
    MAPPED_USER_DATES: "Mapped user start date and end date should be greater than "
        + "and less than equal to project start date and end date respectively.",
    MAPPED_USER_START_DATE: "Mapped user start date should be greater than equal to role date respectively.",
    MAPPED_USER_END_DATE: "Mapped user end date should be less than equal to role date respectively.",
    LINKEDIN_URL_INVALID: "Please enter valid Linkedin url",
    TWITTER_URL_INVALID: "Please enter valid Twitter url",
    BLOG_URL_INVALID: "Please enter valid Blog url",
    EMPTY_MAPPED_USER_DATES: "Mapped user date cannot be empty.",
    POPUP_CLOSED_BY_USER: "popup_closed_by_user",
    VISA_DETAILS_ALREADY_EXISTS: "Visa details already exists. Please choose to edit.",
    MAPPED_USER_DATES_ERROR: "Start date should not be greater than end date",
    CANNOT_ADD_ROLES: "Cannot add more than 10 roles",
    CANNOT_FETCH_ROLES: "Unable to fetch roles.",
    USER_STARTED_WORKING: "Cannot change the date as a mapped user has already started working",
    ALREADY_STARTED_ROLE: "Cannot change the date of already started role to a future date",
    USER_WILL_LIE_OUTSIDE: "Cannot change role end date as a mapped user will lie outside the role end date",
    BACK_DATED_ROLE: "Cannot change role end date as it is a back dated role",
    CANNOT_DELETE_STARTED_ROLE: "Cannot delete already started role",
    CANNOT_DELETE_USER: "Cannot delete user as it has already started working",
    START_DATE_LESS_THAN_END_DATE: "Role start date should be less than end date",
    ROLE_END_DATE_PASSED_CANNOT_ADD_NEW_USER: "Role end date is passed cannot add a new user",
    CANNOT_CHANGE_TENTATIVE_START_AS_SOME_USER_HAS_STARTED_WORKING: "Cannot change tentative "
        + "start as some user mapped has started working",
    CANNOT_CHANGE_TENTATIVE_START_DATE_AS_SOME_USER_MAPPED_WILL_GO_OUT_OF_RANGE: 'Cannot change tentative '
        + 'end date as some user mapped will go out of range',
    CANNOT_CHANGE_TENTATIVE_START_DATE_AS_SOME_ROLE_WILL_GO_OUT_OF_RANGE: 'Cannot change tentative '
        + 'end date as some role will go out of range'
};

export const TIME_INTERVAL = {
    FIFTEEN_HUNDRED: 1500,
    THOUSAND: 1000,
    THREE_THOUSAND: 3000
};

export const SUCCESS_CODES = {
    PROJECT_SUCCESS_MESSAGE: "Project Successfully ",
    CUSTOMER_UPDATE_MESSAGE: "Customer Successfully Updated ",
    CUSTOMER_CREATE_MESSAGE: "Customer successfully created ",
    UPDATE_PROFILE_SUCCESS: "You have successfully updated your profile.",
    UPDATE_ROLE_SUCCESS: "Role updated successfully",
    SKILLS_MERGED_SUCCESS: "Skills successfully merged"
};

export const METHOD_CALLS = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH"
};

export const COLOR_CODES = {
    YELLOW: "#F4E8A0",
    DARK_YELLOW: "#F9CE1F",
    GREY: "#D8D8D8"

};
export const LOADER = {
    COLOR: "#F9CE1F",
    SIZE: "56px",
};

export const NUMBER = {
    ONE: 1
};

export const MESSAGES = ({
    NO_ENTRY: "There was no entry in the field",
    EXPERIENCE_LEFT_EMPTY: "Experience cannot be empty.",
    INVALID_DATES: "The data entered is not proper. Kindly check and re-enter.",
    NO_USERS_WITH_SKILLS: "No users with the selected skills.",
    EMPTY_SKILLS: "Please select skill/s to be searched.",
    CANNOT_DELETE_ALREADY_MAPPED_USER: "Cannot delete user who is already mapped.",
    PROJECT_SKILLS_CANNOT_BE_EMPTY: "Project skills cannot be empty."
});

export const NO_RECORDS_FOUND = value => `No ${value} found.`;

export const MAPS = {
    accessRolesMap: {
        owner: 'Super Admin',
        admin: 'Admin',
        user: 'User'
    },
    accessRolesDescriptionMap: {
        owner: 'Super Admin : Manage Access levels, Customers, Projects, Skills and Roles, Reports',
        admin: 'Admin: Manage Customers, Projects, Skills & Roles, Reports'
    }
};

export const MAX_OF_PROJECT_ROLES_PER_ROLE = 10;
export const SKILLS_PER_PAGE = 10;
export const CAROUSAL_PARTITION_INDEX = 4;

export const TOOlTIPS = {
    ROLES: "You can plan project ramp-up, ramp-down and capacity for individual roles after creating the project.",
    ADD_ROLES: "Add new roles to the Project and plan your project ramp-up."
};

export const AVAILABLE = 'Available';
export const UNAVAILABLE = 'Unavailable';

export const DATE_FORMAT = "DD-MM-YYYY";
export const START_DATE = 'start_date';
export const END_DATE = 'end_date';
export const TOTAL_AVAILABILITY = "100";

export const MAX_SKILLS_CHAR_LENGTH = 60;
export const BLACK_COLOR_CODE = "black";
export const WHITE_COLOR_CODE = "white";
export const ONGOING_PROJECT_STATUS_COLOR = "#F9CE1F";
export const UPCOMING_PROJECT_STATUS_COLOR = "#323451";
export const COMPLETED_PROJECT_STATUS_COLOR = "#b6b6b6";
export const DARK_GRAY_COLOR = "#757575";
export const ASCENDING = "asc";
export const DESCENDING = "desc";
export const COLORSUPTOTHREEVACANCIES = [{ color: '#C6C704' }, { color: '#FACE1E' }, { color: '#0474C7' }];
export const COLORSUPTOSIXVACANCIES = [{ color: '#C6C704' }, { color: '#08426E' }, { color: '#FACE1E' },
    { color: '#D9A70E' }, { color: '#8A8B0E' }, { color: '#0474C7' }];
export const COLORSUPTOTHIRTEENVACANCIES = [{ color: '#565716' }, { color: '#8A8B0E' }, { color: '#C6C704' },
    { color: '#B89B26' }, { color: '#D9A70E' }, { color: '#FACE1E' }, { color: '#FFE185' },
    { color: '#75C4FF' }, { color: '#2697EE' }, { color: '#0474C7' }, { color: '#08426E' },
    { color: '#042137' }, { color: '#000305' }];
export const ALLPROJECTS = 'All Projects';
export const UPCOMINGPROJECT = 'Upcoming Project';
export const ONGOINGPROJECT = 'Ongoing Project';
export const NOPROJECT = "No Project";
