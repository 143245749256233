import axios from 'axios';
import Customer from "../Customer";
import BaseCollection from "./BaseCollection";
import { getApiUrl } from "../../utils/serviceUrls";

export default class Customers extends BaseCollection {
    getModel(attributes) {
        return new Customer(attributes);
    }

    setPath(url) {
        this.path = url;
    }

    getAll(callback) {
        axios.get(getApiUrl("GET_CUSTOMERS"),
            { headers: this.getAuthHeaders() })
            .then((response) => {
                callback(new Customers(response.data));
            });
    }

    getCustomersMap() {
        const customers = this.collection;
        const customersMap = new Map();
        for (let customerIndex = 0; customerIndex < customers.length; customerIndex++) {
            const customer = customers[customerIndex];
            customersMap.set(customer.get('id'), customer.get('name'));
        }
        return customersMap;
    }

    getModelJson(customer) {
        return customer.model;
    }
}
