import React from "react";
import moment from "moment";
import _ from "underscore";
import X from "react-icons/lib/go/x";
import FaCalendar from "react-icons/lib/fa/calendar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { formatDate, getDateRanges, getFutureDateRanges } from "../../utils/helperFunctions";

export default class DateRangeSelector extends React.Component {
    constructor(props) {
        super(props);
        _.bindAll(this, 'handleEvent');
    }

    handleEvent(event, picker) {
        const { onRangeSelected } = this.props;
        if (onRangeSelected) {
            onRangeSelected(formatDate(picker.startDate), formatDate(picker.endDate));
        }
    }

    render() {
        let { startDate, endDate } = this.props;
        const {
            singleDatePicker, onClearDates, isButtonBlocked, min, max, textSize, noClearOption
        } = this.props;
        let label = "Select date";
        if (startDate && endDate) {
            startDate = new moment(startDate);
            endDate = new moment(endDate);
            const start = startDate.format("DD-MM-YYYY");
            const end = endDate.format("DD-MM-YYYY");
            label = `${start} - ${end}`;
        } else if (startDate) {
            startDate = new moment(startDate);
            const start = startDate.format("DD-MM-YYYY");
            label = `${start}`;
        }
        if (singleDatePicker) {
            return (
                <DateRangePicker
                    singleDatePicker
                    showDropdowns
                    minDate={min ? moment(min) : undefined}
                    maxDate={max ? moment(max) : undefined}
                    onApply={this.handleEvent}
                    applyClass="btn-raised apply-btn"
                >
                    <div>
                        <button
                            type="button"
                            className={`btn ${textSize || "text-md"} ${isButtonBlocked ? "btn-block btn-raised" : ""}`}
                        >
                            <span className="text-center">
                                {label}
                                {' '}
                            </span>
                        </button>
                    </div>
                </DateRangePicker>
            );
        }
        return (
            <DateRangePicker
                onApply={this.handleEvent}
                onCancel={onClearDates}
                ranges={min ? getFutureDateRanges() : getDateRanges()}
                minDate={min ? moment() : undefined}
                locale={{ applyLabel: "Apply", cancelLabel: "Cancel", customRangeLabel: "Custom Range" }}
                applyClass="btn-raised apply-btn"
                cancelClass="btn-raised"
            >
                <div>
                    <button
                        type="button"
                        className={`btn ${textSize || "text-md"} ${isButtonBlocked ? "btn-block btn-raised" : ""}`}
                        onClick={e => e.preventDefault()}
                    >
                        <span className="text-center">
                            {label}
                            {' '}
                        </span>
                    </button>
                    { startDate && (noClearOption ? null : (
                        <button type="button" className="clear-button" onClick={onClearDates}>
                            {' '}
                            <X />
                        </button>
                    ))}
                </div>
            </DateRangePicker>
        );
    }
}
