import axios from 'axios';
import BaseModel from "./BaseModel";
import { getApiUrl } from "../utils/serviceUrls";

export default class UserPermission extends BaseModel {
    getConstraints() {
        return {
            role: { presence: { allowEmpty: false } },
            userId: { presence: { allowEmpty: false } }
        };
    }

    updateAccessRole(callback) {
        const url = getApiUrl("UPDATE_PROFILE", { id: this.model.userId });

        axios.put(url, { user: { role: this.model.role } }, { headers: this.getAuthHeaders() })
            .then(() => {
                callback();
            });
    }
}
