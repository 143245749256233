export default class Day {
    static get SUNDAY() {
        return 0;
    }

    static get SATURDAY() {
        return 6;
    }

    static isNonWorkingDay(date) {
        return date.getDay() === Day.SUNDAY || date.getDay() === Day.SATURDAY;
    }

    static getYesterdaysDate() {
        return new Date(Date.now() - 864e5).toISOString().split('T')[0];
    }

    static getTomorrowsDate() {
        return new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString()
            .slice(0, 10);
    }

    static getTodaysDate() {
        return new Date().toISOString().slice(0, 10);
    }

    static getFutureDate(noOfDays) {
        return new Date(new Date().getTime() + (noOfDays * 24 * 60 * 60 * 1000)).toISOString().slice(0, 10);
    }
}
