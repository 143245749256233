import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import React from "react";
import _ from "underscore";
import AppComponent from "../../AppComponent";
import EditProjectRoleGroup from "./editProjectRoleGroup";
import { MAX_OF_PROJECT_ROLES_PER_ROLE } from "../../../utils/constants";
import ProjectRole from "../../../models/ProjectRole";
import ProjectRoleSkill from "../../../models/ProjectRoleSkill";
import Skills from "../../../models/collections/Skills";
import ProjectRolesGroups from "../../../models/collections/ProjectRolesGroups";
import Skill from "../../../models/Skill";
import SkillSelect from "../../common/Select";
import Pill from "../../common/Pill";

export default class EditTable extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            skills: new Skills([]),
            toggle: {},
            groupedProjectRoles: new ProjectRolesGroups([]),
        };
    }

    componentWillMount() {
        this.fetchSkills();
    }

    fetchSkills = () => {
        const { skills } = this.state;
        skills.getSkills(this.skillsSuccessCallback);
    }

    componentWillReceiveProps(nextProps) {
        const { projectRoles } = nextProps;
        const groupedProjectRoles = projectRoles.getProjectRolesGroups();
        this.setState({ groupedProjectRoles });
    }

    skillsSuccessCallback = (response) => {
        this.setState({ skills: new Skills(response) });
    };

    createProjectRoleMap = (projectRoles) => {
        const { roles } = this.props;
        const rolesMap = new Map();
        projectRoles.map((projectRole) => {
            const roleId = projectRole.get('role_id');
            const roleName = roles.find(roleId)
                .get('name');
            rolesMap[roleId] = roleName;
        });
        return rolesMap;
    };

    handleToggle = (index) => {
        const { toggle } = this.state;
        this.setState({
            toggle: {
                ...toggle,
                [index]: !toggle[index]
            }
        });
    };

    skillOptions = projectRoleSkills => projectRoleSkills.map(value => (
        {
            label: value.get('skill')
                .get('skill_type'),
            value: value.get('skill')
                .get('id')
        }));

    getSelectedSkills = (projectRoleSkills) => {
        const skillIds = [];
        projectRoleSkills.map((value) => {
            if (!value.get('_destroy')) {
                skillIds.push(value.get('skill')
                    .get('id'));
            }
        });
        return skillIds;
    };

    handleNumberOfProjectRolesChange = (event, projectSkills, roleId, noOfRoles) => {
        const { projectRoles, project, updateProject } = this.props;
        const noOfRolesToBeAdded = event.value - noOfRoles;
        const projectRoleSkills = [];
        projectSkills.map((projectSkill) => {
            const skill = projectSkill.get('skill');
            const projectRoleSkill = new ProjectRoleSkill({
                skill,
                skill_id: skill.get('id')
            });
            projectRoleSkills.push(projectRoleSkill);
        });
        const roleStartDate = project.get('actual_start_date')
            ? project.get('actual_start_date') : project.get('tentative_start_date');
        const roleEndDate = project.get('actual_end_date')
            ? project.get('actual_end_date') : project.get('tentative_end_date');
        const projectRolesArray = _.range(0, noOfRolesToBeAdded).map(() => new ProjectRole({
            role_id: roleId,
            project_role_skills: projectRoleSkills,
            project_role_users: [],
            role_end_date: roleEndDate,
            role_start_date: roleStartDate,
            capacity: 100
        }));
        projectRoles.addRoles(projectRolesArray);
        updateProject(project);
    };

    asOptions(noOfRoles) {
        return _.range(noOfRoles, MAX_OF_PROJECT_ROLES_PER_ROLE + 1)
            .map(element => (
                {
                    value: element,
                    label: element
                }));
    }

    getSkillToBeUpdated = (skillId) => {
        const { skills } = this.state;
        const skillToBeUpdated = [];
        skills.collection.map((skill) => {
            if (skill.get('id') === skillId) {
                skillToBeUpdated.push(skill);
            }
        });
        return skillToBeUpdated[0];
    };

    addSkillToProjectRole = (skill, rolesGroup) => {
        rolesGroup.map((projectRole) => {
            const projectRoleSkills = projectRole.get('project_role_skills');
            projectRoleSkills.add(skill);
            projectRole.set('project_role_skills', projectRoleSkills);
        });
    };

    deleteSkillFromProjectRole = (skill, rolesGroup) => {
        rolesGroup.map((projectRole) => {
            const projectRoleSkills = projectRole.get('project_role_skills');
            projectRoleSkills.collection.map((projectRoleSkill, index) => {
                if (projectRoleSkill.get('skill')
                    .get('id') === skill.get('id')) {
                    projectRoleSkills.delete(index);
                }
            });
            projectRole.set('project_role_skills', projectRoleSkills);
        });
    };

    onAddItem = (e, { value }, groupedRole) => {
        const { groupedProjectRoles } = this.state;
        const skill = new Skill({ skill_type: value });
        skill.create((response) => {
            const skillToBeAdded = new Skill(response.data);
            this.addSkillToProjectRole(skillToBeAdded, groupedRole);
            this.setState({ groupedProjectRoles });
            this.fetchSkills();
        });
    };

    handleSkillChange = (event, { value }, rolesGroup) => {
        const { groupedProjectRoles } = this.state;
        const skillIdsToBeUpdated = [];
        const skillIdsPresent = [];
        value.map((skillId) => {
            if (typeof (skillId) !== 'string') {
                skillIdsToBeUpdated.push(skillId);
            }
        });
        rolesGroup[0].get('project_role_skills')
            .collection
            .map((projectskill) => {
                if (!projectskill.get('_destroy')) {
                    skillIdsPresent.push(projectskill.get('skill')
                        .get('id'));
                }
            });
        if (skillIdsToBeUpdated.length >= skillIdsPresent.length) {
            const skillId = skillIdsToBeUpdated.filter(id => !skillIdsPresent.includes(id))[0];
            if (skillId) {
                const skill = this.getSkillToBeUpdated(skillId);
                this.addSkillToProjectRole(skill, rolesGroup);
                this.setState({ groupedProjectRoles });
            }
        } else {
            const skillIdArray = skillIdsPresent.filter(id => !skillIdsToBeUpdated.includes(id));
            skillIdArray.map((id) => {
                const skill = this.getSkillToBeUpdated(id);
                this.deleteSkillFromProjectRole(skill, rolesGroup);
            });
            this.setState({ groupedProjectRoles });
        }
    };


    getAllSkills = () => {
        const { skills } = this.state;
        return skills.collection.map(skill => ({
            key: skill.get('skill_type'),
            text: skill.get('skill_type'),
            value: skill.get('id')
        }));
    };

    renderUserRoleSkills = (projectRoleSkills) => {
        const userRoleSkills = projectRoleSkills.map((value, index) => (
            <Pill
                key={index}
                value={(value.get('skill').get('skill_type'))}
            />
        ));
        return userRoleSkills;
    }

    getClassName = (toggle) => {
        const cssClass = toggle ? "mapping-table" : "mapping-table link-pointer";
        return cssClass;
    }

    renderProjectRoleGroups = () => {
        const { projectRoles, project, updateProject } = this.props;
        const { groupedProjectRoles } = this.state;
        const rolesMap = this.createProjectRoleMap(projectRoles);
        const rows = groupedProjectRoles.collection.map((groupedRole, index) => {
            const roleId = groupedRole[0].get('role_id');
            const projectSkills = groupedRole[0].get('project_role_skills').collection;
            const noOfRoles = groupedRole.filter(role => role.get('_destroy') != true).length;
            const options = this.asOptions(noOfRoles);
            const skillsOptions = this.getAllSkills();
            if (noOfRoles > 0) {
                return (
                    <Row key={index}>
                        <Col
                            md={11}
                            className={this.getClassName(this.state.toggle[index])}
                            onClick={this.state.toggle[index] ? null : () => this.handleToggle(index)}
                        >
                            <Row className="role-label">
                                <Col md={2}>
                                    {' '}
                                    {rolesMap[roleId]}
                                </Col>
                                <Col md={1} className="number-of-role">
                                    {this.state.toggle[index] ? (
                                        <Select
                                            options={options}
                                            value={noOfRoles}
                                            onChange={(event) => {
                                                this.handleNumberOfProjectRolesChange(event, projectSkills, roleId,
                                                    noOfRoles);
                                            }}
                                            clearable={false}
                                            data-test="add-roles"
                                            className="text-extra-small"

                                        />
                                    ) : `${noOfRoles}`}
                                </Col>
                                <Col md={3} className="select-box-edit edit-font-size">
                                    {!this.state.toggle[index] ? (
                                        this.renderUserRoleSkills(projectSkills)
                                    ) : (
                                        <SkillSelect
                                            options={skillsOptions}
                                            placeholder="Choose Skill"
                                            search
                                            selection
                                            fluid
                                            allowAdditions
                                            onAddItem={(event,
                                                { value }) => this.onAddItem(event, { value }, groupedRole)}
                                            value={this.getSelectedSkills(projectSkills)}
                                            onChange={(event, { value }) => this.handleSkillChange(event, { value },
                                                groupedRole)}
                                            multiple
                                            data-test="skills"
                                            className="text-extra-small"
                                        />
                                    )}
                                </Col>
                                <Col md={1} className="padding-left-46">
                                    {!this.state.toggle[index] ? (
                                        <span>
                                            <img className="expand-collapse-img" alt="Expand" src="/expand.svg" />
                                        </span>
                                    )
                                        : (
                                            <span onClick={() => this.handleToggle(index)}>
                                                <img
                                                    className="expand-collapse-img"
                                                    alt="Decrease"
                                                    src="/collapse.svg"
                                                />
                                            </span>
                                        )}
                                </Col>
                            </Row>
                            <div className="margin-left-1">
                                <EditProjectRoleGroup
                                    key={roleId}
                                    roleId={roleId}
                                    elements={groupedRole}
                                    project={project}
                                    updateProject={updateProject}
                                    toggle={this.state.toggle[index]}
                                    projectRoles={projectRoles}
                                    noOfRoles={noOfRoles}
                                />
                            </div>
                        </Col>
                    </Row>
                );
            }
        });
        return rows;
    };

    render() {
        return (

            <div className="edit-project-roles">
                {this.renderProjectRoleGroups()}
            </div>

        );
    }
}
