import React from "react";
import { FormGroup } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "28ch",
  },
  url: {
    width: "100%",
  },
  resume: {
    width: "100%",
  },
}));

const Input = (props) => {
  const {
    required,
    name,
    type,
    value,
    handleChange,
    title,
    autoComplete,
    placeholder,
    pattern,
    className,
    onBlur,
    validationErrorPresent,
    message,
  } = props;

  const classes = useStyles();

  return (
    <React.Fragment>
      <FormGroup>
        <TextField
          className={
            className === undefined
              ? classes.root
              : className === "url"
              ? classes.url
              : classes.resume
          }
          required = {required}
          id={name}
          label={title}
          variant="outlined"
          value={value || ""}
          onChange={handleChange}
          autoComplete={autoComplete || undefined}
          pattern={pattern}
          onBlur={onBlur}
          name={name}
        />

        {validationErrorPresent ? (
          <em className="error-indicator">{message}</em>
        ) : (
          ""
        )}
      </FormGroup>
    </React.Fragment>
  );
};

export default Input;
