import React from "react";
import { Row, Col } from "react-bootstrap";
import _ from "underscore";
import Project from "../../../models/Project";
import EditProjectDetails from "./editProjectDetails";
import ProjectRoles from "../../../models/collections/ProjectRoles";
import EditTable from "./editTable";
import { ERROR_CODES, SUCCESS_CODES, TIME_INTERVAL } from "../../../utils/constants";
import AppComponent from "../../AppComponent";
import EditProjectAddRole from "./EditProjectAddRole";
import Roles from "../../../models/collections/Roles";
import { getValueOrDefault } from "../../../utils/helperFunctions";

export default class EditProjectScreen extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            project: new Project({
                project_roles: new ProjectRoles([])
            }),
            roles: new Roles([])
        };
        this.childRef = React.createRef();
    }

    componentWillMount() {
        const { match: { params: { customer_id, id } } } = this.props;
        const { project } = this.state;
        const { roles } = this.state;
        const params = {
            customer_id,
            id
        };
        roles.getRoles(
            (response) => {
                const updatedRoles = new Roles(response);
                this.setState({ roles: updatedRoles });
            }
        );
        project.getProject(params, this.projectSuccessCallback);
    }

    projectSuccessCallback = (response) => {
        const project = new Project(response);
        this.setState({ project });
    };

    updateProject = (project) => {
        this.setState({ project });
    };

    isProjectRoleValid = (projectRole) => {
        const { project } = this.state;
        let isValid = true;
        let errorMessage = "";
        const startDate = getValueOrDefault(project.get('actual_start_date'), project.get('tentative_start_date'));
        if (!(projectRole.isValidStartDate(startDate))) {
            isValid = false;
            errorMessage = ERROR_CODES.START_DATE_LESS_THAN_END_DATE;
        }
        const endDate = getValueOrDefault(project.get('actual_end_date'), project.get('tentative_end_date'));
        if (!(projectRole.isValidEndDate(endDate))) {
            isValid = false;
            errorMessage = ERROR_CODES.INCORRECT_ROLE_END_DATE;
        }
        return { errorMessage, isValid };
    }

    handleSubmit = () => {
        const { project } = this.state;
        const rolesToBeAdded = this.childRef.current.getGroupedProjectRoles();
        let isAddedRoleValid = false;
        if (rolesToBeAdded.length > 0) {
            rolesToBeAdded.map((role) => {
                const noOfTimesToBeAdded = role.noOfRoles;
                const projectRole = role.projectRoles.collection[0];
                const projectRolesArray = _.range(0, noOfTimesToBeAdded).map(() => projectRole);
                const { isValid, errorMessage } = this.isProjectRoleValid(projectRolesArray[0]);
                if (!isValid) {
                    window.scrollTo(0, 0);
                    this.notifyError(errorMessage, { timeOut: TIME_INTERVAL.THREE_THOUSAND });
                    isAddedRoleValid = true;
                    return;
                }
                project.get('project_roles').addRoles(projectRolesArray);
            });
        }
        if (isAddedRoleValid) {
            return;
        }
        const { match: { params: { customer_id, id } } } = this.props;
        const params = {
            customer_id,
            id
        };
        const { isValid, errorMessage } = project.validate();
        if (!isValid) {
            window.scrollTo(0, 0);
            this.notifyError(errorMessage, { timeOut: TIME_INTERVAL.THREE_THOUSAND });
            return;
        }
        project.updateProject(params, this.updateProjectSuccessCallback, this.updateProjectErrorCallback);
    }

    getLastPageUrl(location) {
        return location.state === undefined ? "/dashboard" : location.state.lastPageUrl;
    }

    updateProjectSuccessCallback = (response) => {
        const { location, match } = this.props;
        const lastPageUrl = this.getLastPageUrl(location);
        this.handleResponse(response, match.params.id, "updated", lastPageUrl);
    };

    handleResponse = (response, id, notice, lastPageUrl) => {
        window.scrollTo(0, 0);
        this.notifySuccess(SUCCESS_CODES.PROJECT_SUCCESS_MESSAGE + notice, 'Success',
            { timeOut: TIME_INTERVAL.THOUSAND });
        this.navigateTo(lastPageUrl);
    };

    handleCancel = () => {
        const { location } = this.props;
        const lastPageUrl = this.getLastPageUrl(location);
        this.navigateTo(lastPageUrl);
    }

    render() {
        const {
            project, roles
        } = this.state;
        const projectRoles = project.get('project_roles');
        return (
            <div>
                <Row className="heading-1">
                    Edit Project
                </Row>
                <EditProjectDetails
                    project={project}
                    updateProject={this.updateProject}
                    newRolesToBeAdded={this.childRef.current ? this.childRef.current.getGroupedProjectRoles() : ""}
                />
                <EditProjectAddRole
                    ref={this.childRef}
                    project={project}
                    updateProject={this.updateProject}
                    roles={roles}
                />
                <EditTable
                    project={project}
                    updateProject={this.updateProject}
                    projectRoles={projectRoles}
                    roles={roles}
                />
                <Row className="margin-top-2">
                    <Col md={10}>
                        <button
                            className='btn btn-secondary btn-border-cancel'
                            onClick={this.handleCancel}
                            type="button"
                            data-test="cancel"
                        >
                            Cancel
                        </button>
                    </Col>
                    <Col md={2}>
                        <button
                            className='btn btn-primary margin-top-5 text-small'
                            onClick={this.handleSubmit}
                            type="button"
                            data-test="save"
                        >
                            Save Changes
                        </button>
                    </Col>
                </Row>
            </div>
        );
    }
}
