import { AbilityBuilder, Ability } from '@casl/ability';
import { ROLES } from '../../../utils/constants';

export default function defineAbilityFor() {
    const { rules, can } = AbilityBuilder.extract();
    if (!window.loggedInUser || window.loggedInUser === null) return new Ability(rules);

    const role = window.loggedInUser.role;
    if (role === ROLES.OWNER) {
        can('home', 'home');
        can('my-project', 'my-project');
        can('customer', 'Option');
        can('report', 'Option');
        can('settings', 'Settings');
        can(['search', 'update', 'health-status', 'view-unassigned', 'view-aspirations', 'open-roles'], 'User');
        can(['index', 'show', 'create', 'view'], 'Customer');
        can(['index', 'show', 'create', 'update', 'edit', 'map', 'view-users-project', 'project-report', 'showOnly'],
            'Project');
        can(['index'], 'Skill');
        can('index', 'Settings');
        return new Ability(rules);
    }
    if (role === ROLES.ADMIN) {
        can('home', 'home');
        can('my-project', 'my-project');
        can('customer', 'Option');
        can('report', 'Option');
        can(['search', 'update'], 'User');
        can(['index'], 'Customer');
        can(['index', 'update', 'map', 'show', 'view-users-project'], 'Project');
        can(['search', 'health-status', 'update', 'view-unassigned', 'view-aspirations'], 'User');
        can(['index', 'create', 'update', 'map', 'show', 'view-users-project', 'project-report', 'showOnly'],
            'Project');
        can(['search', 'health-status', 'update', 'view-unassigned', 'view-aspirations', 'open-roles'], 'User');
        can(['index', 'update', 'map', 'show', 'view-users-project', 'project-report', 'showOnly'], 'Project');
        can(['index'], 'Skill');
        can('index', 'Settings');
        return new Ability(rules);
    }

    // when role == developer
    can('home', 'home');
    can('my-project', 'my-project');
    can("update", "User");
    can(['view-users-project', 'showOnly'], 'Project');
    can('index', 'Settings');
    return new Ability(rules);
}
