import React from "react";
import { Col, Row } from "react-bootstrap";
import AppComponent from "../../AppComponent";
import Label from "../../common/Label";
import formatDate from "../../../utils/formatDate";
import { ROLES } from "../../../utils/constants";

export default class ShowProjectDetails extends AppComponent {
    renderProjectStartDate = () => {
        const { project } = this.props;
        const title = project.get('actual_start_date')
            ? "Actual Start Date" : "Tentative Start Date";
        const startDate = project.get('actual_start_date') || project.get('tentative_start_date');
        return (
            <Col md={2}>
                <Label>{title}</Label>
                <Label className="label-border" data-test="startDate">{formatDate(startDate)}</Label>
            </Col>
        );
    }

    renderProjectEndDate = () => {
        const { project } = this.props;
        const title = project.get('actual_end_date')
            ? "Actual End Date" : "Tentative End Date";
        const endDate = project.get('actual_end_date') || project.get('tentative_end_date');
        return (
            <Col md={2}>
                <Label>{title}</Label>
                <Label className="label-border" data-test="endDate">{formatDate(endDate)}</Label>
            </Col>
        );
    }

    getClassName(project, key) {
        const className = project.get(key) ? "label-border text-color-grey" : "label-align-centre";
        return className;
    }

    renderDates = () => {
        const { project } = this.props;
        return (
            <Row className="margin-top-2">
                <Col md={2}>
                    <Label>Actual Start Date</Label>
                    <Label className={this.getClassName(project, "actual_start_date")} data-test="actualStartDate">
                        {project.get('actual_start_date')
                            ? formatDate(project.get('actual_start_date')) : "-" }
                    </Label>
                </Col>
                <Col md={3}>
                    <Label>Actual End Date</Label>
                    <Label className={this.getClassName(project, 'actual_end_date')} data-test="actualEndDate">
                        {project.get('actual_end_date')
                            ? formatDate(project.get('actual_end_date')) : "-" }
                    </Label>
                </Col>
                <Col md={2}>
                    <Label>Tentative Start Date</Label>
                    <Label
                        className={this.getClassName(project, 'tentative_start_date')}
                        data-test="tentativeStartDate"
                    >
                        {project.get('tentative_start_date')
                            ? formatDate(project.get('tentative_start_date')) : "-" }
                    </Label>
                </Col>
                <Col md={2}>
                    <Label>Tentative End Date</Label>
                    <Label className={this.getClassName(project, 'tentative_end_date')} data-test="tentativeEndDate">
                        {project.get('tentative_end_date')
                            ? formatDate(project.get('tentative_end_date')) : "-" }
                    </Label>
                </Col>
            </Row>
        );
    }

    constructEditUrl(project) {
        return `/customers/${project.get('customer_id')}/projects/${project.get('id')}`;
    }

    handleEditClick = () => {
        const { project, lastPageUrl } = this.props;
        const url = this.constructEditUrl(project);
        this.navigateTo(url, { customerID: project.get('customer_id'), lastPageUrl });
    }

    constructMapUrl(project) {
        return `/customers/${project.get('customer_id')}/projects/map/${project.get('id')}`;
    }

    handleMapClick = () => {
        const { project, lastPageUrl } = this.props;
        const url = this.constructMapUrl(project);
        this.navigateTo(url, { customerID: project.get('customer_id'), lastPageUrl });
    }

    render() {
        const { project } = this.props;
        return (
            <div>
                <Row className="heading-1">
                    <Col md={9} xs={5} data-test="projectName">
                        {project.get('project_name')}
                        <span className="status-span" data-test="project-status">
&#8226;
                            {" "}
                            {project.get('project_status')}
                        </span>
                    </Col>

                    {window.loggedInUser.role === ROLES.USER
                        ? ("")
                        : (
                            <Col md={3} xs={5} className="action-btn align-center">
                                <button
                                    type="button"
                                    title="Edit"
                                    onClick={this.handleEditClick}
                                    className="btn margin-right-reduce"
                                    data-test="editProject"
                                >
                                    <span>
                                        <img src="/pencil.svg" alt="pencil" className="edit-img" />
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    title="Map"
                                    onClick={this.handleMapClick}
                                    className="btn margin-left-5"
                                    data-test="projectMapping"
                                >
                                    <span>
                                        <img src="/mapping.svg" alt="team" className="map-img" />
                                    </span>
                                </button>
                            </Col>
                        )}
                </Row>
                <Row className="section">
                    <Col md={3} xs={5}>
                        <Label className="label-margin-right" displayInline>
                            Customer Name
                        </Label>
                        <Label className="text-color-grey" data-test="customerName" displayInline>
                            {project.get('customer') ? project.get('customer').name : ""}
                        </Label>
                    </Col>
                </Row>
                {window.loggedInUser.role === ROLES.USER
                    ? (
                        <Row className="margin-top-2">
                            {this.renderProjectStartDate()}
                            {this.renderProjectEndDate()}
                        </Row>
                    )
                    : this.renderDates()
                }
            </div>
        );
    }
}
