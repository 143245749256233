import React, { Component } from 'react';
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/es/Col";
import DisplaySkill from "./displaySkill";
import SkillTable from "./skillTable";
import Users from "../../models/collections/Users";
import { MESSAGES } from "../../utils/constants";
import Day from "../../utils/day";

const toastr = require("toastr");

export default class ReportSkill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSkills: [],
            users: new Users([]),
            isInitialState: true,
            endDate: Day.getYesterdaysDate(),
            selectedSkillsLabel: []
        };
        this.handleChangeInState = this.handleChangeInState.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.getUsersWithSkillsSuccessCallback = this.getUsersWithSkillsSuccessCallback.bind(this);
        this.convertSkillsToParams = this.convertSkillsToParams.bind(this);
    }

    getUsersWithSkillsSuccessCallback(response) {
        const users = new Users(response);
        this.handleChangeInState('users', users);
        this.handleChangeInState('isInitialState', false);
    }

    convertSkillsToParams(skills) {
        const params = skills.map(skill => skill.label);
        return params;
    }

    handleSearchClick() {
        const { selectedSkills, endDate, users } = this.state;
        if (selectedSkills.length > 0) {
            const selectedSkillsLabel = this.convertSkillsToParams(selectedSkills);
            this.setState({ selectedSkillsLabel });
            users.getUsersWithSkills(
                { skills: selectedSkillsLabel, date: { endDate } },
                this.getUsersWithSkillsSuccessCallback
            );
        } else {
            toastr.error(MESSAGES.EMPTY_SKILLS);
        }
    }

    handleChangeInState(key, value) {
        this.setState({
            [key]: value
        });
    }

    render() {
        const {
            selectedSkills, endDate, users, isInitialState, selectedSkillsLabel
        } = this.state;
        return (
            <div className='col-md-10'>
                <div className="align-center"><span className="heading-1">Skills</span></div>
                <Row>
                    <Col md={1} />
                    <Col md={10}>
                        <div className='margin-top-4'>
                            <DisplaySkill
                                handleChangeInState={this.handleChangeInState}
                                selectedSkills={selectedSkills}
                                handleSearchClick={this.handleSearchClick}
                                endDate={endDate}
                            />
                        </div>
                    </Col>
                </Row>
                <div>
                    <SkillTable
                        handleChangeInState={this.handleChangeInState}
                        users={users}
                        isInitialState={isInitialState}
                        selectedSkillsLabel={selectedSkillsLabel}
                    />
                </div>
            </div>
        );
    }
}
