import React, { Component } from "react";
import _ from "underscore";
import Select from "@material-ui/core/Select";
import { getMonthsOptions, getYearsOptions } from "../../utils/helperFunctions";
import { DECIMAL_RADIX } from "../../utils/constants";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";

export default class WorkingFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      years: 0,
      months: 0,
      yearsOptions: getYearsOptions(),
      monthsOptions: getMonthsOptions(),
    };
    _.bindAll(
      this,
      "handleChangeOfTotalExperienceDate",
      "convertTotalExperienceToYears",
      "handleMonthsChange",
      "handleYearsChange"
    );
  }

  componentWillMount() {
    const { totalExperience } = this.props;
    const { years, months } = this.convertTotalExperienceToYears(
      totalExperience
    );
    this.setState({ years, months });
  }

  componentWillReceiveProps(nextProps) {
    const { totalExperience } = this.props;
    const { totalExperience: nextPropsTotalExperience } = nextProps;
    if (nextPropsTotalExperience !== totalExperience) {
      const { years, months } = this.convertTotalExperienceToYears(
        nextPropsTotalExperience
      );
      this.setState({ years, months });
    }
  }

  handleMonthsChange(event) {
    const value = event ? event.target.value : "";
    const { years } = this.state;
    this.setState({
      months: value,
    });
    this.handleChangeOfTotalExperienceDate(years, value);
  }

  handleYearsChange(event) {
    const value = event ? event.target.value : "";
    const { months } = this.state;
    this.setState({
      years: value,
    });
    this.handleChangeOfTotalExperienceDate(value, months);
  }

  handleChangeOfTotalExperienceDate(years, months) {
    const totalExperience =
      parseInt(12 * years, DECIMAL_RADIX) + parseInt(months * 1, DECIMAL_RADIX);
    const { handleModelChange } = this.props;
    handleModelChange("total_experience", totalExperience);
  }

  convertTotalExperienceToYears(totalExperience) {
    const years = parseInt(totalExperience / 12, DECIMAL_RADIX);
    const months = totalExperience % 12;
    return {
      years,
      months,
    };
  }

  render() {
    const { yearsOptions, years, monthsOptions, months } = this.state;
    return (
      <div>
        <Box display="flex" flexDirection="row">
          <FormControl required variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Experience(Years)
            </InputLabel>
            <Select
              style={{ width: "25ch" }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={years}
              onChange={this.handleYearsChange}
              label="Experience(Years)"
            >
              {yearsOptions.map((item) => (
                <MenuItem value={item.value} key={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl required variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              Experience(Months)
            </InputLabel>
            <Select
              style={{ width: "25ch" }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={months}
              onChange={this.handleMonthsChange}
              label="Experience(Months)"
            >
              {monthsOptions.map((item) => (
                <MenuItem value={item.value} key={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
    );
  }
}
