import $ from "jquery";
import axios from 'axios';
import BaseModel from "./BaseModel";
import { METHOD_CALLS } from "../utils/constants";
import { getApiUrl } from "../utils/serviceUrls";

export default class Skill extends BaseModel {
    constructor(attributes) {
        super(attributes);
        this.path = '';
    }

    get(key) {
        return super.get(key);
    }

    fetch(options, successCallback) {
        const errorCallback = {};
        const success = (response) => {
            this.model = new Skill(response).model;
            if (successCallback) {
                return successCallback(response);
            }
        };
        return $.ajax({
            url: this.path,
            method: METHOD_CALLS.GET,
            headers: { ...super.getAuthHeaders(), options },
            success,
            error: errorCallback
        });
    }

    enableSkill(callback) {
        this.execute("ENABLE_SKILL", callback);
    }

    disableSkill(callback) {
        this.execute("DISABLE_SKILL", callback);
    }

    execute(action, callback) {
        const success = (response) => {
            this.model = new Skill(response).model;
            callback(response);
        };
        $.ajax({
            url: getApiUrl(action, { id: this.model.id }),
            method: METHOD_CALLS.PUT,
            headers: super.getAuthHeaders(),
            success
        });
    }

    create(successCallback) {
        axios.post(getApiUrl("GET_SKILLS"),
            { skill: this.model },
            { headers: this.getAuthHeaders() })
            .then((response) => {
                successCallback(response);
            })
            .catch(error => console.error(error));
    }
}
