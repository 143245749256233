import React from 'react';
import Select from 'react-select';
import { Col, Row } from "react-bootstrap";
import DateRangeSelector from "../../common/DateRangeSelector";
import UserDataTable from "./userDataTable";
import UserStatistics from "../../../models/UserStatistics";
import Skills from "../../../models/collections/Skills";

export default class ViewUnassignedPeople extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: "",
            endDate: "",
            userData: new UserStatistics(),
            options: [],
            selectedSkills: [],
            skills: new Skills([]),
            showTable: false
        };
    }

    componentWillMount() {
        const { skills } = this.state;
        skills.getSkills(this.getAvailableSkillsSuccessCallback);
    }

    getAvailableSkillsSuccessCallback = (response) => {
        const skills = new Skills(response);
        this.setState({ skills });
        this.convertResponseToOptions(response);
    }

    convertResponseToOptions = (response) => {
        const options = response.map(element => ({ label: element.skill_type, value: element.id }));
        this.setState({ options });
    }

    dateFunction = (startDate, endDate) => {
        this.setState({ startDate, endDate });
    }

    handleSearchClick = () => {
        const {
            startDate, endDate, selectedSkills, userData
        } = this.state;
        if (!startDate && !endDate && !selectedSkills.length) {
            return;
        }
        const skills = this.convertSkillsToParams(selectedSkills);
        userData.getUnassignedPeople({ startDate, endDate, skills },
            (response) => {
                const updatedUserStatistics = new UserStatistics(response);
                this.setState({ userData: updatedUserStatistics, showTable: true });
            });
    }

    clearDate = () => {
        const startDate = "";
        const endDate = "";
        this.setState({ startDate, endDate });
    }

    handleChangeInState = (key, value) => {
        this.setState({
            [key]: value
        });
    }

    handleChangeInSkill = (event) => {
        this.handleChangeInState('selectedSkills', event);
    }

    convertSkillsToParams(skills) {
        const params = skills.map(skill => skill.label);
        return params;
    }

    render() {
        const {
            startDate, endDate, userData, options, selectedSkills, showTable
        } = this.state;
        return (
            <>
                <Row className="show-grid">
                    <Col md={6}>
                        <span className="heading-1">People’s Availability</span>
                    </Col>
                </Row>
                <Row className="margin-top-2 text-medium font-weight-bold">
                    <Col md={5}>
                        <Row>
                            <Col md={3} className="padding-top-2">
                                Select skills:
                            </Col>
                            <Col md={8} className="select-actions">
                                <Select
                                    multi
                                    options={options}
                                    onChange={this.handleChangeInSkill}
                                    value={selectedSkills}
                                    classNamePrefix="select"
                                    autosize={false}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <Row>
                            <Col md={4} className="padding-top-2">
                              Available from:
                            </Col>
                            <Col md={8}>
                                <DateRangeSelector
                                    onRangeSelected={this.dateFunction}
                                    onClearDates={this.clearDate}
                                    min
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2} className="margin-left-2">
                        <button
                            type="button"
                            className="btn btn-primary text-small btn-padding"
                            onClick={this.handleSearchClick}
                        >
                            Search
                        </button>
                    </Col>
                </Row>
                {showTable ? (
                    <>
                        <Row className="margin-top-2">
                            <Col md={12} className="text-align-right font-weight-bold text-medium padding-right-5">
                    Count:
                                <span>
                                    {' '}
                                    {userData.model.length}
                                </span>
                            </Col>
                        </Row>
                        <UserDataTable
                            data={userData}
                            lastPageUrl={this.props.location.pathname}
                            history={this.props.history}
                        />
                    </>
                ) : (
                    <span className="availability-message">
                    Select skills or a date range to find the person / team of your choice.
                    </span>
                )}
            </>
        );
    }
}
