import axios from "axios";
import BaseCollection from "./BaseCollection";
import UserStatistics from '../UserStatistics';
import { getApiUrl } from "../../utils/serviceUrls";

export default class UsersStatistics extends BaseCollection {
    getModel(attributes) {
        return new UserStatistics(attributes);
    }

    setPath(url) {
        this.path = url;
    }

    fetchUsersAspirations(successCallback) {
        axios.get(getApiUrl("GET_USERS_ASPIRATIONS"), { headers: super.getAuthHeaders() })
            .then((response) => {
                this.collection = new UsersStatistics(response.data).collection;
                if (successCallback) {
                    return successCallback(response.data);
                }
            });
    }
}
