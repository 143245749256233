import React from "react";
import { Col, Row } from "react-bootstrap";
import Select from 'react-select';
import X from "react-icons/lib/go/x";
import AppComponent from "../../AppComponent";
import { numberOfRoles } from "../../../utils/helperFunctions";
import SkillSelect from "../../common/Select";
import DateSelector from "../../common/DateSelector";

export default class EditProjectRoleGroupRow extends AppComponent {
    getRoleName = (roleId) => {
        const { roles } = this.props;
        const role = roles.collection.find(role => role.get('id') === roleId);
        return role.get('name');
    }

    getSelectedSkills = () => {
        const { projectRoles } = this.props;
        const projectRoleSkills = projectRoles.collection[0].get('project_role_skills');
        const activeProjectSkills = projectRoleSkills.filter(value => !value.get('_destroy'));
        const skillIds = activeProjectSkills.map(x => x.get('skill').get('id'));
        return skillIds;
    }

    getAllSkills = () => {
        const { skills } = this.props;
        return skills.collection.map(skill => ({
            key: skill.get('skill_type'),
            text: skill.get('skill_type'),
            value: skill.get('id')
        }));
    };

    getSkillToBeUpdated = (skillId) => {
        const { skills } = this.props;
        return skills.collection.find(skill => skill.get('id') === skillId);
    };

    addSkillToProjectRole = (skill) => {
        const {
            projectRoles, groupedProjectRoles
        } = this.props;
        groupedProjectRoles.map((role) => {
            if (role.projectRoles === projectRoles) {
                role.projectRoles.collection.map((projectRole) => {
                    const projectRoleSkills = projectRole.get('project_role_skills');
                    projectRoleSkills.add(skill);
                    projectRole.set('project_role_skills', projectRoleSkills);
                });
            }
        });
    }

    deleteSkillFromProjectRole = (skill) => {
        const {
            projectRoles, groupedProjectRoles
        } = this.props;
        groupedProjectRoles.map((role, index) => {
            if (role.projectRoles === projectRoles) {
                const projectRoleSkills = projectRoles.collection[0].get('project_role_skills');
                projectRoleSkills.collection.map((projectRoleSkill, skillIndex) => {
                    if (projectRoleSkill.get('skill')
                        .get('id') === skill.get('id')) {
                        projectRoleSkills.delete(skillIndex);
                    }
                });
                projectRoles.collection[0].set('project_role_skills', projectRoleSkills);
            }
            groupedProjectRoles[index].projectRoles = projectRoles;
        });
    };

    handleSkillChange = (event, { value }) => {
        const { groupedProjectRoles, projectRoles, updateGroupedProjectRoles } = this.props;
        const skillIdsToBeUpdated = [];
        value.map((skillId) => {
            if (typeof (skillId) !== 'string') {
                skillIdsToBeUpdated.push(skillId);
            }
        });
        const filteredProjectSkills = projectRoles.collection[0].get('project_role_skills')
            .collection
            .filter(projectskill => !projectskill.get('_destroy'));
        const skillIdsPresent = filteredProjectSkills.map(projectSkill => projectSkill.get('skill').get('id'));
        if (skillIdsToBeUpdated.length >= skillIdsPresent.length) {
            const skillId = skillIdsToBeUpdated.filter(id => !skillIdsPresent.includes(id))[0];
            if (skillId) {
                const skill = this.getSkillToBeUpdated(skillId);
                this.addSkillToProjectRole(skill);
                updateGroupedProjectRoles(groupedProjectRoles);
            }
        } else {
            const skillIdArray = skillIdsPresent.filter(id => !skillIdsToBeUpdated.includes(id));
            skillIdArray.map((id) => {
                const skill = this.getSkillToBeUpdated(id);
                this.deleteSkillFromProjectRole(skill);
            });
            this.setState({ groupedProjectRoles });
        }
    }

    handleRoleStartDateChange = (date) => {
        const {
            projectRoles, groupedProjectRoles, updateGroupedProjectRoles
        } = this.props;
        const filteredRoles = groupedProjectRoles.find(role => (role.projectRoles === projectRoles));
        filteredRoles.projectRoles.collection.map((projectRole) => {
            projectRole.set('role_start_date', date);
        });
        updateGroupedProjectRoles(groupedProjectRoles);
    }

    handleRoleEndDateChange = (date) => {
        const {
            projectRoles, groupedProjectRoles, updateGroupedProjectRoles
        } = this.props;
        const filteredRoles = groupedProjectRoles.find(role => (role.projectRoles === projectRoles));
        filteredRoles.projectRoles.collection.map((projectRole) => {
            projectRole.set('role_end_date', date);
        });
        updateGroupedProjectRoles(groupedProjectRoles);
    }

    handleNoOfRolesChange = ({ value }) => {
        const {
            projectRoles, groupedProjectRoles, updateGroupedProjectRoles
        } = this.props;
        groupedProjectRoles.map((role) => {
            if (role.projectRoles === projectRoles) {
                role.noOfRoles = value;
            }
        });
        updateGroupedProjectRoles(groupedProjectRoles);
    }

    deleteProjectRole = () => {
        const {
            projectRoles, groupedProjectRoles, updateGroupedProjectRoles
        } = this.props;
        groupedProjectRoles.map((role, index) => {
            if (role.projectRoles === projectRoles) {
                groupedProjectRoles.splice(index, 1);
            }
        });
        updateGroupedProjectRoles(groupedProjectRoles);
    }

    render() {
        const {
            projectRoles, noOfRoles, project
        } = this.props;
        return (
            <Row className="add-new-role text-small">
                <Col md={2} className="text-medium">
                    {this.getRoleName(projectRoles.collection[0].get('role_id'))}
                </Col>
                <Col md={1}>
                    <Select
                        options={numberOfRoles()}
                        value={noOfRoles}
                        onChange={this.handleNoOfRolesChange}
                        data-test="manage-role-count"
                        clearable={false}
                    />
                </Col>
                <Col md={3}>
                    <SkillSelect
                        options={this.getAllSkills()}
                        placeholder="Choose Skill"
                        search
                        selection
                        fluid
                        allowAdditions
                        onChange={this.handleSkillChange}
                        value={this.getSelectedSkills()}
                        multiple
                        id="select-skills"
                    />
                </Col>
                <Col md={2} className="padding-left-0">
                    <DateSelector
                        onDateSelected={this.handleRoleStartDateChange}
                        startDate={projectRoles.collection[0].get('role_start_date')}
                        min={project.get('actual_start_date')
                            ? project.get('actual_start_date') : project.get('tentative_start_date')}
                        max={project.get('actual_end_date')
                            ? project.get('actual_end_date') : project.get('tentative_end_date')}
                        data-test="role-start-date"

                    />
                </Col>
                <Col md={2} className="padding-left-0">
                    <DateSelector
                        onDateSelected={this.handleRoleEndDateChange}
                        startDate={projectRoles.collection[0].get('role_end_date')}
                        min={project.get('actual_start_date')
                            ? project.get('actual_start_date') : project.get('tentative_start_date')}
                        max={project.get('actual_end_date')
                            ? project.get('actual_end_date') : project.get('tentative_end_date')}
                        data-test="role-end-date"

                    />
                </Col>
                <Col md={1}>
                    <button
                        title="Delete role"
                        type="button"
                        className="btn delete-btn"
                        onClick={this.deleteProjectRole}
                        data-test="delete-user"
                    >
                        <X />
                    </button>
                </Col>
            </Row>
        );
    }
}
