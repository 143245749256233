import axios from "axios";
import HttpStatus from "http-status-codes";

const hideElement = (elementId) => {
    const element = document.getElementById(elementId);
    const cssClass = "d-none";
    element.classList.add(cssClass);
};
const showElement = (elementId) => {
    const element = document.getElementById(elementId);
    const cssClass = "d-none";
    element.classList.remove(cssClass);
};

export default {
    setupInterceptors: () => {
        axios.interceptors.request.use(
            (config) => {
                hideElement("component-wrapper");
                showElement("loader-background");
                const newConfig = config;
                if (window.loggedInUser) {
                    newConfig.headers.Authorization = `${window.loggedInUser.auth_token}`;
                }
                return newConfig;
            },
            error => Promise.reject(error)
        );

        axios.interceptors.response.use(
            (response) => {
                hideElement("loader-background");
                showElement("component-wrapper");
                return response;
            },
            (error) => {
                hideElement("loader-background");
                showElement("component-wrapper");
                if (!error.response) {
                    console.log('Error occured while processing request from server side.');
                    return Promise.reject(error);
                }
                if (error.response.status === HttpStatus.FORBIDDEN
                  || error.response.status === HttpStatus.UNAUTHORIZED) {
                    window.clearStorage();
                    window.location.assign('/login');
                }
                return Promise.reject(error);
            }
        );
    }
};
