import React, { Component } from "react";
import Select from "react-select";

export default class ListUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedName: ''
        };
    }

    handleChangeOfName = (event) => {
        const selectedName = event ? event.value : '';
        this.setState({ selectedName });
        this.props.handleChangeInParentComponent(selectedName);
    }

    getOptions = values => values.map(value => ({
        label: `${value.model.name} (${value.model.username})`,
        value: value.model.id
    }));

    render() {
        const { users } = this.props;
        const { selectedName } = this.state;

        return (
            <Select
                multi={false}
                options={this.getOptions(users)}
                value={selectedName}
                onChange={this.handleChangeOfName}
                placeholder="Search member"
                className="email-field"
            />
        );
    }
}
