import React from 'react';
import _ from 'underscore';
import { Col } from 'react-bootstrap';
import UserProjectsGrid from "./UserProjectsGrid";
import UserProjectDetails from "./UserProjectDetails";
import Day from "../../utils/day";
import AppComponent from "../AppComponent";
import Projects from "../../models/collections/Projects";
import {
    COMPLETED_PROJECT_STATUS_COLOR,
    ONGOING_PROJECT_STATUS_COLOR,
    UPCOMING_PROJECT_STATUS_COLOR
} from "../../utils/constants";

export default class UserProjectSummaryContainer extends AppComponent {
    constructor() {
        super();
        this.state = {
            userProjectList: [],
            userProjects: new Projects([])
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!_.isEqual(nextProps.userProjects, prevState.userProjects)) {
            return { userProjects: nextProps.userProjects };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState.userProjects, this.state.userProjects)) {
            const userProjects = this.state.userProjects;
            this.setState({ userProjects }, () => {
                this.setUserProjectList();
            });
        }
    }

    setUserProjectList = () => {
        let userProjectList = this.props.userProjects.map((project) => {
            const endDate = project.get('actual_end_date') || project.get('tentative_end_date');
            const startDate = project.get('actual_start_date') || project.get('tentative_start_date');
            const teamSize = project.get('team_size');
            const { status, statusColor } = this.getStatusAndStatusColor(startDate, endDate);
            const { roles } = this.evaluateRolesAndTeamSize(project);
            const skills = this.getSkills(project);
            return {
                project_id: project.get('id'),
                customer_id: project.get('customer_id'),
                name: project.get('project_name'),
                status,
                startDate,
                endDate,
                teamSize,
                roles,
                skills: _.uniq(skills.flat()),
                statusColor
            };
        });
        userProjectList = _.sortBy(userProjectList, userProject => userProject.status).reverse();
        this.setState({ userProjectList, selectedProject: userProjectList[0] });
    };

    getStatusAndStatusColor = (startDate, endDate) => {
        let status = "On going project";
        let statusColor = ONGOING_PROJECT_STATUS_COLOR;
        if (startDate > Day.getTodaysDate()) {
            status = "Upcoming project";
            statusColor = UPCOMING_PROJECT_STATUS_COLOR;
        } else if (endDate < Day.getTodaysDate()) {
            status = "Completed project";
            statusColor = COMPLETED_PROJECT_STATUS_COLOR;
        }
        return { status, statusColor };
    }

    getSkills = (project) => {
        const projectRoles = project.get('project_roles');
        return projectRoles.map((projectRole) => {
            const projectRoleSkills = projectRole.get('project_role_skills');
            const projectSkills = projectRoleSkills.map(
                projectRoleSkill => projectRoleSkill.get('skill').get('skill_type')
            );
            return projectSkills;
        });
    };

    evaluateRolesAndTeamSize = (project) => {
        const roles = [];
        const groups = project.get('project_roles').getGroupedProjectRolesByRoleId();
        for (const [roleId, projectRoles] of groups) {
            const roleName = this.getRoleName(roleId);
            roles.push({ name: roleName, size: projectRoles.length });
        }
        return { roles };
    };

    getRoleName = roleId => this.props.roles.find(roleId).get('name');

    componentDidMount() {
        this.setUserProjectList();
    }

    handleProjectSelect = (selectedProject) => {
        this.setState({ selectedProject });
    };

    render() {
        const {
            startDate, endDate, history, location
        } = this.props;
        const { userProjectList, selectedProject } = this.state;
        return (
            <React.Fragment>
                <UserProjectsGrid
                    userProjectList={userProjectList}
                    startDate={startDate}
                    endDate={endDate}
                    handleProjectSelect={this.handleProjectSelect}
                    history={history}
                    location={location}
                />
                <Col xs={1} md={1} />
                {selectedProject
                    && (
                        <UserProjectDetails
                            selectedProject={selectedProject}
                            valueKey="size"
                            labelKey="name"
                            history={history}
                            location={location}
                        />
                    )
                }
            </React.Fragment>
        );
    }
}
