import React from "react";
import moment from "moment";
import AppComponent from "../../AppComponent";
import formatDate from "../../../utils/formatDate";
import ShowMappedUser from "./ShowMappedUser";
import { ROLES } from "../../../utils/constants";
import Day from "../../../utils/day";


export default class ShowProjectRoleRow extends AppComponent {
    projectRoleUser = () => {
        const { projectRole } = this.props;
        let userName = "-";
        const users = projectRole.get('project_role_users').collection;
        const roleStartDate = projectRole.get('role_start_date');
        const roleEndDate = projectRole.get('role_end_date');
        const today = Day.getTodaysDate();
        const usersRoleOnsetDaysDiffrence = this.calculateRoleOnsetDiffrence(users, today);
        const currentlyRunningRole = moment(today).isBetween(roleStartDate, roleEndDate, null, '[]');
        const upcommingRole = moment(roleStartDate).isAfter(today);
        const closedRole = moment(roleEndDate).isBefore(today);
        if (currentlyRunningRole) {
            userName = this.findClosestMappedUser(usersRoleOnsetDaysDiffrence);
        }
        if (upcommingRole) {
            userName = this.findNearFutureMappedUser(usersRoleOnsetDaysDiffrence);
        }
        if (closedRole) {
            userName = this.findLastMappedUser(usersRoleOnsetDaysDiffrence);
        }
        return userName;
    }

    findLastMappedUser(usersRoleOnsetDaysDiffrence) {
        let userName = "";
        let minValue = 0;
        usersRoleOnsetDaysDiffrence.forEach((user) => {
            if (user.days < 0) {
                if (minValue === 0) {
                    minValue = user.days;
                }
                if (minValue <= 0) {
                    if (user.days >= minValue) {
                        minValue = user.days;
                        userName = user.name;
                    }
                }
            }
        });
        return userName;
    }

    findNearFutureMappedUser(usersRoleOnsetDaysDiffrence) {
        let userName = "";
        let minValue = 0;
        usersRoleOnsetDaysDiffrence.forEach((user) => {
            if (user.days > 0) {
                if (minValue >= 0) {
                    if (minValue === 0) {
                        minValue = user.days;
                    }
                    if (user.days <= minValue) {
                        minValue = user.days;
                        userName = user.name;
                    }
                }
                if (minValue < 0) {
                    minValue = user.days;
                }
            }
        });
        return userName;
    }

    findClosestMappedUser(usersRoleOnsetDaysDiffrence) {
        let userName = "";
        let minValue = 0;
        usersRoleOnsetDaysDiffrence.forEach((user) => {
            if (user.days === 0) {
                userName = user.name;
                return userName;
            }
            if (user.days > 0) {
                if (minValue >= 0) {
                    if (user.days < minValue) {
                        minValue = user.days;
                        userName = user.name;
                    }
                    if (minValue === 0) {
                        userName = user.name;
                        minValue = user.days;
                    }
                }
                if (minValue < 0) {
                    minValue = user.days;
                }
            }
            if (user.days < 0) {
                if (minValue < 0) {
                    if (user.days > minValue) {
                        minValue = user.days;
                        userName = user.name;
                    }
                }
            }
        });
        return userName;
    }

    calculateRoleOnsetDiffrence(users, today) {
        const usersWithDaysDiffrence = [];
        users.forEach((user) => {
            const startDate = user.get('start_date');
            const endDate = user.get('end_date');
            if (!user.get('shadow') && user.get('start_date')) {
                const name = user.get('user').name;
                if (moment(today).isBetween(startDate, endDate, null, '[]')) {
                    usersWithDaysDiffrence.push({ name, days: 0 });
                } else if (moment(startDate).isAfter(today)) {
                    usersWithDaysDiffrence.push({
                        name,
                        days: moment(startDate).diff(today, 'days')
                    });
                } else {
                    usersWithDaysDiffrence.push({
                        name,
                        days: moment(endDate).diff(today, 'days')
                    });
                }
            }
        });
        return usersWithDaysDiffrence;
    }

    renderUserMatchedSkill(projectRoleSkills, userSkills) {
        const matchedSkill = [];
        projectRoleSkills.forEach((projectRoleSkill) => {
            const roleSkillId = projectRoleSkill.get('skill').get('id');
            userSkills.forEach((userSkill) => {
                if (roleSkillId === userSkill.id) {
                    matchedSkill.push(projectRoleSkill.get('skill').get('skill_type'));
                }
            });
        });
        return matchedSkill;
    }

    renderMappedUser = () => {
        const { projectRole } = this.props;
        const projectRoleSkills = projectRole.get('project_role_skills').collection;

        if (window.loggedInUser.role === ROLES.USER) {
            return (
                <ShowMappedUser
                    roleStartDate={formatDate(projectRole.get('role_start_date'))}
                    roleEndDate={formatDate(projectRole.get('role_end_date'))}
                    capacity={`${projectRole.get('capacity')}%`}
                    employee={this.projectRoleUser()}
                />
            );
        }
        const mappedUser = projectRole.get('project_role_users').collection.map((projectRoleUser, index) => (
            <ShowMappedUser
                key={projectRoleUser.get('id')}
                roleStartDate={formatDate(projectRoleUser.get('start_date'))}
                roleEndDate={formatDate(projectRoleUser.get('end_date'))}
                capacity={index ? "" : (`${projectRole.get('capacity')}%`)
                }
                employee={projectRoleUser.get('user').name}
                shadow={projectRoleUser.get('shadow')}
                mapPercentage={this.getMappedPercentage(projectRoleUser)}
                skills={this.renderUserMatchedSkill(projectRoleSkills, projectRoleUser.get('user').skills)}
            />
        ));
        return mappedUser;
    }

    getMappedPercentage(projectRoleUser) {
        return (projectRoleUser.get('mapped_percentage') == 0)
            ? "Unavailable"
            : `${projectRoleUser.get('mapped_percentage')}%`;
    }

    render() {
        const { projectRole } = this.props;
        const numberOfUserMapped = projectRole.get('project_role_users').collection.length;
        return (numberOfUserMapped ? this.renderMappedUser() : (
            <ShowMappedUser
                roleStartDate={formatDate(projectRole.get('role_start_date'))}
                roleEndDate={formatDate(projectRole.get('role_end_date'))}
                capacity={`${projectRole.get('capacity')}%`}
                employee="-"
            />
        ));
    }
}
