import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import _ from "underscore";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { getApiUrl } from '../utils/serviceUrls';
import AppComponent from "./AppComponent";
import {
    ERROR_CODES, TIME_INTERVAL
} from "../utils/constants";
import Loader from "./common/Loader";
import jwt from "jsonwebtoken";

const toastr = require("toastr");

export default class Login extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            errorMessage: '',
            isAuthenticated: false,
            user: null,
            token: ''
        };
        _.bindAll(this, 'handleLogin', 'setChangedValueOfUsername', 'setValueOfChangedPassword',
            'handleGoogleResponse', 'onFailure');
    }

    componentDidMount() {
        if (window.loggedInUser) this.navigateTo('/dashboard');
    }

    setValueOfChangedPassword(event) {
        this.setState({
            password: (event.target.value),
            errorMessage: ''
        });
    }

    setChangedValueOfUsername(event) {
        this.setState({
            username: (event.target.value),
            errorMessage: ''
        });
    }

    onFailure(error) {
        if (error.error !== ERROR_CODES.POPUP_CLOSED_BY_USER) {
            console.log({ error });
            this.notifyError(error.error, TIME_INTERVAL.THREE_THOUSAND);
        }
    }

    handleLogin(data) {
        const respondedUser = data.data;
        if (respondedUser === false) {
            this.setState({ errorMessage: "Please Enter a Valid Username and Password" });
        } else {
            window.loggedInUser = respondedUser;
            Object.defineProperty(window.loggedInUser, 'auth_token', {
                writable: false
            });
            Object.defineProperty(window.loggedInUser, 'username', {
                writable: false
            });
            Object.defineProperty(window.loggedInUser, 'role', {
                writable: false
            });
            Object.defineProperty(window.loggedInUser, 'id', {
                writable: false
            });
            window.localStorage.setItem('token', respondedUser.auth_token);
            window.localStorage.setItem('organizationId', respondedUser.organization_id);
            this.navigateTo('/dashboard');
        }
    }

    handleGoogleResponse(response) {
        if (response.credential == null) {
            toastr.error("Something went wrong");
            return;
        }
        const token = response.credential; // This is the JWT token
        const decodedToken = jwt.decode(token, { complete: true }); // Decodes the token
        const googleId = decodedToken.payload.sub; // The user's unique identifier (formerly googleId)
        const name = decodedToken.payload.name; // The user's unique identifier (formerly googleId)
        const email = decodedToken.payload.email; // The user's unique identifier (formerly googleId)

        if (!email.includes('@gmail')) {
            axios.post(getApiUrl("LOGIN_BY_GOOGLE"), {
                googleId,
                name,
                email
            }).then((data) => {
                this.handleLogin(data);
            });
        } else {
            toastr.error("Please login with your company email");
        }
    }

    render() {
        const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID || process.env.REACT_APP_GOOGLE_CLIENT_ID;
        console.log("GOOGLE_CLIENT_ID:", GOOGLE_CLIENT_ID);
        return (
            <>
                <div id="loader-background" className="d-none">
                    <Loader />
                </div>
                <Row className="login-container" id="component-wrapper">
                    <Col md={5} className="login-image vertical-divider">
                        <img src="/login-page.svg " alt="Office" />
                    </Col>
                    <Col md={5} className="google-login-container">
                        <section>
                            <h2>Find Your winning team with ease!</h2>
                            <h4>Login with your business email address</h4>
                        </section>
                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                            <GoogleLogin
                                onSuccess={this.handleGoogleResponse}
                                onError={this.onFailure}
                                render={({ onClick }) => (
                                    <button onClick={onClick} className="google-button">
                                        <img src="/google-icon.svg" alt="google-icon" />
                                        Sign in with Google
                                    </button>
                                )}
                            />
                        </GoogleOAuthProvider>
                    </Col>
                </Row>
            </>
        );
    }
}
