import React from 'react';
import Select from 'react-select';
import { Row, Col } from 'react-bootstrap';
import _ from 'underscore';
import Skills from "../../models/collections/Skills";
import DateSelector from "../common/DateSelector";

export default class DisplaySkill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            skills: new Skills([])
        };
        _.bindAll(this, 'handleChangeInSkill', 'getAvailableSkillsSuccessCallback',
            'convertResponseToOptions', 'handleDateChange');
    }

    componentWillMount() {
        const { skills } = this.state;
        skills.getSkills(this.getAvailableSkillsSuccessCallback);
    }

    getAvailableSkillsSuccessCallback(response) {
        const skills = new Skills(response);
        this.setState({ skills });
        this.convertResponseToOptions(response);
    }

    convertResponseToOptions(response) {
        const options = response.map(element => ({ label: element.skill_type, value: element.id }));
        this.setState({ options });
    }

    handleDateChange(date) {
        const { handleChangeInState } = this.props;
        handleChangeInState('endDate', date);
    }

    handleChangeInSkill(event) {
        const { handleChangeInState } = this.props;
        handleChangeInState('selectedSkills', event);
    }

    render() {
        const { options } = this.state;
        const { endDate, handleSearchClick, selectedSkills } = this.props;
        return (
            <div>
                <Row className="sub-heading">
                    <Col xs={2} md={2}>
                        Select skills:
                        <span><i className='red-color'>*</i></span>
                    </Col>
                    <Col xs={4} md={4} className="select-actions">
                        <Select
                            multi
                            options={options}
                            onChange={this.handleChangeInSkill}
                            value={selectedSkills}
                            classNamePrefix="select"
                            autosize={false}
                        />
                    </Col>
                    <Col xs={2} md={2}>
                        Available from:
                        <span><i className='red-color'>*</i></span>
                    </Col>
                    <Col xs={2} md={2}>
                        <DateSelector
                            onDateSelected={this.handleDateChange}
                            onClear={this.clearDate}
                            startDate={endDate}
                        />
                    </Col>
                    <Col xs={1} md={1}>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSearchClick}
                        >
                            Search
                        </button>
                    </Col>
                </Row>
            </div>
        );
    }
}
