import React from 'react';

const PageHeading = (props) => {
    const { heading } = props;
    return (
        (
            <div className="align-center">
                <span className="heading-1">
                    {heading}
                </span>
            </div>

        )
    );
};

export default PageHeading;
