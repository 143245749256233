import React, { Component } from 'react';
import Col from "react-bootstrap/es/Col";
import { Row } from "react-bootstrap";
import SelectProjectToReport from './selectProjectToReport';
import Users from '../../../models/collections/Users';
import Roles from '../../../models/collections/Roles';
import DisplayBasicDetails from './displayBasicDetails';
import PageHeading from "../../common/PageHeading";


export default class ProjectReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProject: null,
            projectDetails: null,
            rolesName: {},
            allUsers: [],
            from: undefined,
            to: undefined
        };
        this.handleChangeInState = this.handleChangeInState.bind(this);
        this.onDateRangeSelected = this.onDateRangeSelected.bind(this);
    }


    componentWillMount() {
        this.getRoles();
        this.getUsers();
    }

    onDateRangeSelected(from, to) {
        this.setState({ from, to });
    }

    getUsers() {
        const users = new Users([]);
        users.getAllUsers((response) => {
            const allUsers = {};
            response.map((user) => {
                allUsers[user.get('id')] = {
                    name: user.get('name'),
                    username: user.get('username'),
                };
            });
            this.setState({ allUsers });
        });
    }

    getRoles() {
        const rolesName = {};
        const roles = new Roles([]);
        roles.getRoles((response) => {
            response.map((role) => {
                rolesName[role.id] = role.name;
            });
            this.setState({ rolesName });
        });
    }

    handleChangeInState(key, value) {
        this.setState({ [key]: value });
    }

    render() {
        return (
            <div className='col-md-10'>
                <PageHeading
                    heading="Project Report"
                />
                <Row>
                    <Col md={1} />
                    <Col md={10}>
                        <div className='margin-top-4'>
                            <SelectProjectToReport
                                handleChangeInState={this.handleChangeInState}
                                {...this.state}
                                onDateRangeSelected={this.onDateRangeSelected}
                            />
                        </div>
                    </Col>
                </Row>
                <div>
                    <DisplayBasicDetails
                        handleChangeInState={this.handleChangeInState}
                        {...this.state}
                    />
                </div>
            </div>
        );
    }
}
