import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  textarea: {
    resize: "both",
  },
}));

export default function AspirationBox(props) {
  const classes = useStyles();

  const handleChangeOfAspiration = (event) => {
    props.handleModelChange("aspiration", event.target.value);
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <div>
        <TextField
          id="aspriration"
          rows={8}
          multiline
          fullWidth
          variant="outlined"
          value={props.aspiration}
          onChange={handleChangeOfAspiration}
          inputProps={{ className: classes.textarea }}
        />
      </div>
    </form>
  );
}
