import React from 'react';
import {
    Col, Row
} from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import { DARK_GRAY_COLOR } from "../../utils/constants";

export default class UserProjectsGrid extends React.PureComponent {
    render() {
        const columns = [{
            dataField: 'name',
            text: 'Name',
            style: () => ({ color: DARK_GRAY_COLOR })
        }, {
            dataField: 'status',
            text: 'Status',
            style: (cell, row) => ({ color: row.statusColor })
        }];
        const { userProjectList, handleProjectSelect } = this.props;
        const selectedRowName = userProjectList[0] ? userProjectList[0].name : "";
        const selectRowProperties = {
            mode: "radio",
            clickToSelect: true,
            bgColor: 'rgb(246, 248, 250)',
            selected: [selectedRowName],
            hideSelectColumn: true,
            onSelect: handleProjectSelect
        };
        return (
            <Col xs={4} md={4} className="user-projects-grid">
                <Row>
                    <Col xs={12} md={12}>
                        <BootstrapTable
                            keyField='name'
                            data={userProjectList}
                            selectRow={selectRowProperties}
                            columns={columns}
                            bordered={false}
                            hover
                            headerClasses="header"
                        />
                    </Col>
                </Row>
            </Col>

        );
    }
}
