import React, { Component } from 'react';
import DonutChart from 'react-google-charts';
import { Col, Row } from "react-bootstrap";
import Day from "../../utils/day";
import DateSelector from "../common/DateSelector";
import {
    BLACK_COLOR_CODE,
    COLORSUPTOSIXVACANCIES,
    COLORSUPTOTHIRTEENVACANCIES,
    COLORSUPTOTHREEVACANCIES, WHITE_COLOR_CODE
} from "../../utils/constants";
import DateRangeSelector from "../common/DateRangeSelector";


export default class HealthStatusPieChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignedPeople: 0,
            unassignedPeople: 0,
            tentativeAssigned: 0,
            asOnDate: Day.getYesterdaysDate()
        };
        this.MSG_ON_EMPTY_SUBMISSION = "Kindly Select the date to get the health status";
        this.COLORS = ['#F4E8A0', "#F9CE1F", "#D8D8D8"];
        this.customisedLegend = this.customisedLegend.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { asOnDate, userStatistics } = nextProps;
        const { userStatistics: prevPropsUserStatistics, asOnDate: prevPropsAsOnDate } = this.props;
        if (userStatistics === prevPropsUserStatistics && asOnDate === prevPropsAsOnDate) {
            return;
        }
        this.setState({
            isDateEmpty: false,
            assignedPeople: userStatistics.get('assignedUsersCount'),
            unassignedPeople: userStatistics.get('unassignedUsersCount'),
            tentativeAssigned: userStatistics.get('tentativelyAssignedCount'),
            asOnDate
        });
    }

    handleDateChange(date) {
        const isDateEmpty = date === "";
        this.setState({ isDateEmpty });
        if (isDateEmpty) {
            return;
        }
        const { handleDateChange } = this.props;
        handleDateChange(date);
    }

    customisedLegend({ payload }) {
        return (
            <div>
                <ul>
                    {
                        payload.map((entry, index) => (
                            <li
                                className="legend-width"
                                key={`item-${entry.value + index}`}
                                type="round"
                                style={{ color: `${entry.color}` }}
                            >
                                <Row>
                                    <Col xs={6} md={6}><span className="legend-left-column">{entry.value}</span></Col>
                                    <Col xs={4} md={4}>
                                        <span className="legend-right-column margin-right-6">
                                            {entry.payload.value}
                                        </span>
                                    </Col>
                                </Row>
                            </li>
                        ))
                    }
                </ul>
            </div>
        );
    }

  getColors = (chartData) => {
      if (chartData.length <= 4) {
          return COLORSUPTOTHREEVACANCIES;
      }
      if (chartData.length <= 7) {
          return COLORSUPTOSIXVACANCIES;
      }

      return COLORSUPTOTHIRTEENVACANCIES;
  }

  returnThePieChart() {
      const {
          assignedPeople, unassignedPeople, tentativeAssigned, isDateEmpty
      } = this.state;
      const chartData = [
          ["name", "size"],
          ["Assigned", assignedPeople],
          ["Unassigned", unassignedPeople],
          ["Tentatively Assigned", tentativeAssigned]
      ];
      const PIE_SLICE_TXT_COLOR = chartData.length === 2 ? BLACK_COLOR_CODE : WHITE_COLOR_CODE;
      const colors = chartData ? this.getColors(chartData) : [{}];

      if (assignedPeople === 0
            && unassignedPeople === 0
            && tentativeAssigned === 0) {
          return (
              <div>
                  <p
                      className="error-messages"
                  >
                      {isDateEmpty ? this.MSG_ON_EMPTY_SUBMISSION : " "}
                  </p>
              </div>
          );
      }
      return (
          <div>
              <DonutChart
                  width="100%"
                  height="22em"
                  chartType="PieChart"
                  loader={<div>Loading Chart</div>}
                  data={chartData}
                  options={{
                      title: '',
                      pieHole: 0.4,
                      backgroundColor: "rgb(246, 248, 250)",
                      legend: { position: 'bottom', maxLines: 2 },
                      pieSliceTextStyle: { color: PIE_SLICE_TXT_COLOR },
                      slices: colors
                  }}
                  rootProps={{ 'data-testid': '3' }}
              />
          </div>
      );
  }

  render() {
      const pieChart = this.returnThePieChart();
      const { asOnDate } = this.state;
      return (
          <div>
              <Row>
                  <Col xs={6} md={6}>
                      <div><span className="heading-2">Health Status</span></div>
                  </Col>
                  <Col xs={6} md={6} className="text-align-right">
                      <DateRangeSelector
                          singleDatePicker
                          onRangeSelected={this.handleDateChange}
                          onClear={this.clearDate}
                          startDate={asOnDate}
                          className={`health-status-date-input
                                                        form-control
                                                        ${asOnDate === "" ? "field-with-error" : ""}`}
                          textSize="text-small"
                      />
                  </Col>
              </Row>
              <Row className="margin-top-2">
                  <Col xs={12} md={12}>
                      <div>
                          {pieChart}
                      </div>
                  </Col>
              </Row>
          </div>
      );
  }
}
