import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Row, Col } from 'react-bootstrap';
import _ from 'underscore';
import DateSelector from "../common/DateSelector";
import { ERROR_CODES } from "../../utils/constants";

export default class VisaEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            errorInModal: '',
            editVisaCountry: 'default',
            editVisaType: 'default',
            editVisaValidity: this.props.visa.validity
        };
        this.MESSAGE_EMPTY_FIELD = "Please enter all mandatory fields.";
        _.bindAll(this, 'onCloseModal', 'onOpenModal', 'passParametersFromSameComponent', 'rowEditCheck');
    }

    onOpenModal() {
        this.handleStateAsissngment('open', true);
    }

    onCloseModal() {
        this.handleStateAsissngment('open', false);
    }

    passParametersFromSameComponent(key, value) {
        this.handleStateAsissngment('errorInModal', "");
        this.removeInvalidFromId("edit-visa-type");
        this.handleStateAsissngment(key, value);
    }

    handleStateAsissngment(key, value) {
        this.setState({
            [key]: value
        });
    }

    addInvalidToId(id) {
        document.getElementById(id).classList.add('is-invalid');
    }

    removeInvalidFromId(id) {
        document.getElementById(id).classList.remove('is-invalid');
    }

    rowEditCheck(skillObjectEdited) {
        const tempId = `${skillObjectEdited.country}-${skillObjectEdited.type_of_visa}-${skillObjectEdited.validity}`;
        const currentCountry = this.state.editVisaCountry === "default"
            ? skillObjectEdited.country : this.state.editVisaCountry;
        const currentType = this.state.editVisaType === "default"
            ? skillObjectEdited.type_of_visa : this.state.editVisaType;
        const currentValidity = this.state.editVisaValidity === "default"
            ? skillObjectEdited.validity : this.state.editVisaValidity;
        if (currentValidity === "" || currentType === "") {
            this.handleStateAsissngment('errorInModal', this.MESSAGE_EMPTY_FIELD);
            if (currentValidity === "" && currentType === "") {
                this.addInvalidToId('edit-visa-validity');
                return this.addInvalidToId("edit-visa-type");
            }
            if (currentType === "") {
                return this.addInvalidToId("edit-visa-type");
            }
            return this.addInvalidToId("edit-visa-validity");
        }
        if (this.props.checkAvailability(`${currentCountry}-${currentType}-${currentValidity}`, skillObjectEdited.id)) {
            return this.handleStateAsissngment('errorInModal', ERROR_CODES.VISA_DETAILS_ALREADY_EXISTS);
        }
        this.rowEditVisa(skillObjectEdited, tempId, currentCountry, currentType, currentValidity);
    }

    rowEditVisa(currentObject, tempId, country, type, validity) {
        currentObject.country = country;
        currentObject.type_of_visa = type;
        currentObject.validity = validity;
        this.handleStateAsissngment('editVisaCountry', 'default');
        this.handleStateAsissngment('editVisaType', 'default');
        this.handleStateAsissngment('editVisaValidity', 'default');
        this.onCloseModal();
        this.props.rowEditVisa(currentObject, tempId);
    }

    render() {
        return (
            <button
                type="button"
                className="btn btn-warning width-25"
                onClick={this.onOpenModal}
            >

                <Modal
                    little
                    className="width-80"
                    closeIconSize={20}
                    open={this.state.open}
                    onClose={this.onCloseModal}
                    onOverlayClick={this.onCloseModal}
                >
                    <h2>Update Visa Details</h2>
                    <div className="mandatory">{this.state.errorInModal}</div>
                    <Row>
                        <Col xs={4} md={4}>
                            <label>Country</label>
                            <select
                                defaultValue={this.props.visa.country}
                                className="form-control"
                                onChange={event => this.passParametersFromSameComponent('editVisaCountry',
                                    event.target.value)}
                            >
                                {this.props.country}
                            </select>
                        </Col>
                        <Col xs={4} md={4}>
                            <label>Type</label>
                            <input
                                id="edit-visa-type"
                                defaultValue={this.props.visa.type_of_visa}
                                className="form-control"
                                onChange={event => this.passParametersFromSameComponent('editVisaType',
                                    event.target.value)}
                            />
                        </Col>
                        <Col xs={3} md={3}>
                            <label>Validity</label>
                            <DateSelector
                                onDateSelected={date => this.passParametersFromSameComponent('editVisaValidity', date)}
                                startDate={this.state.editVisaValidity}
                                isButtonBlock={false}
                                textSize="text-sm"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <button
                                className="btn btn-primary"
                                onClick={event => this.rowEditCheck(this.props.visa, event)}
                            >
Update Row
                            </button>
                        </Col>
                    </Row>
                </Modal>
                <img className="height-20" src="./pencil.svg" alt="edit skill" />
            </button>
        );
    }
}
