import React from 'react';
import { Col, Row } from "react-bootstrap";
import DateRangeSelector from "../common/DateRangeSelector";


export default function OpenRoles(props) {
    const onRangeSelected = (startDate, endDate) => {
        const { handleDateRangeChange } = props;
        handleDateRangeChange(startDate, endDate);
    };
    const redirectToOpenRoles = (roleName, roleId) => {
        const {
            history, lastPageUrl, startDate, endDate
        } = props;
        const url = `/open-roles`;
        history.push(`${url}`, {
            lastPageUrl, startDate, endDate, roleName, roleId
        });
    };

    const getRow = (roleName, roleCount, roleId) => (
        <Row
            key={roleName}
            className="link-pointer table-row"
            onClick={() => redirectToOpenRoles(roleName, roleId)}
        >
            <Col md={8}>
                {roleName}
            </Col>
            <Col md={4} className="text-align-center">
                {roleCount}
            </Col>
        </Row>
    );


    const openRolesData = () => {
        if (!props.openRoles) {
            return;
        }
        const { openRoles } = props;
        if (openRoles.length === 0) {
            const emptyOpenRole = getRow("-", "-", "-");
            return emptyOpenRole;
        }
        openRoles.sort((a, b) => b.project_role_count - a.project_role_count);
        const openRolesRows = openRoles.map(openRole => (
            getRow(openRole.role_name, openRole.project_role_count, openRole.role_id)
        ));

        return openRolesRows;
    };

    const { startDate, endDate } = props;
    return (
        <>
            <Row>
                <Col md={1} />
                <Col md={6}><span className="heading-2">Open Roles</span></Col>
                <Col md={5} className="text-align-right">
                    <DateRangeSelector
                        onRangeSelected={onRangeSelected}
                        min
                        startDate={startDate}
                        endDate={endDate}
                        noClearOption
                        textSize="text-small"
                    />
                </Col>
            </Row>
            <Row className="open-role-table margin-top-7 text-medium">
                <Col md={1} />
                <Col md={11}>
                    <Row>
                        <Col md={8}>Role</Col>
                        <Col md={4}>People Required</Col>
                    </Row>
                </Col>
                <Col md={1} />
                <Col md={11} className="table-body text-medium">
                    {openRolesData()}
                </Col>
            </Row>
        </>
    );
}
