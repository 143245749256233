import React, { Component } from 'react';
import LeftArrow from "react-icons/lib/fa/caret-left";
import RightArrow from 'react-icons/lib/fa/caret-right';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import formatDate from '../../utils/formatDate';

function CarouselArrow(props) {
    const { onClick, side, children } = props;
    return (
        <a
            href="#"
            className={`carousel-arrow carousel-arrow-${side}`}
            onClick={onClick}
        >
            {children}
        </a>
    );
}

function Card(props) {
    const groups = props.slide.map((slide) => {
        const startDate = slide.get('actual_start_date') || slide.get('tentative_start_date');
        const endDate = slide.get('actual_end_date') || slide.get('tentative_end_date');
        return (
            <Col xs={3} md={3} key={slide.get('id')}>
                <div className="active-project-card">
                    <div className="margin-left-2 margin-top-5">
                        <Row>
                            <Col xs={9} md={9}>
                                <span className="title">
                                    <Link
                                        to={`/customers/${slide
                                            .get('customer').id}/view-projects/${slide.get('id')}`}
                                        className="btn-link"
                                    >
                                        {slide.get('project_name')}
                                    </Link>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}><span>{slide.get('customer').name}</span></Col>
                        </Row>
                        <Row className="dates-row">
                            <Col xs={9} md={9}>
                                <span className="dates">
                                    {formatDate(startDate)}
                                    {' '}
                                    -
                                    {' '}
                                    {formatDate(endDate)}
                                </span>
                            </Col>
                            <Col className="title padding-left-10px" xs={3} md={3}>
                                <span className="black-color">{`${slide.get('team_size')} `}</span>
                                <img className="team-img" src="./team.svg" alt="team" />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Col>
        );
    });
    return groups;
}

function CarouselSlide(props) {
    const { index, activeIndex } = props;
    return (
        <li
            className={
                index === activeIndex
                    ? "carousel-slide carousel-slide-active"
                    : "carousel-slide"
            }
        >
            <Row>
                <Card {...props} />
            </Row>
        </li>
    );
}

// Carousel wrapper component
export default class Carousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        };
    }

    goToSlide = (index) => {
        this.setState({
            activeIndex: index
        });
    }

    goToPrevSlide = (e) => {
        e.preventDefault();
        let { activeIndex: index } = this.state;
        const { slides } = this.props;
        const slidesLength = slides.length;
        if (index < 1) {
            index = slidesLength;
        }
        index -= 1;
        this.setState({
            activeIndex: index
        });
    }

    goToNextSlide = (e) => {
        e.preventDefault();
        let { activeIndex: index } = this.state;
        const { slides } = this.props;
        const slidesLength = slides.length - 1;
        if (index === slidesLength) {
            index = -1;
        }
        index += 1;
        this.setState({
            activeIndex: index
        });
    }

    render() {
        const { activeIndex } = this.state;
        const { slides } = this.props;
        return (
            <div className="carousel">
                <CarouselArrow onClick={e => this.goToPrevSlide(e)} side="left">
                    <LeftArrow />
                </CarouselArrow>
                <ul>
                    {slides.map((slide, index) => (
                        <CarouselSlide
                            key={`carousel-slide-${index + 1}`}
                            index={index}
                            activeIndex={activeIndex}
                            slide={slide}
                        />
                    ))}
                </ul>
                <CarouselArrow onClick={e => this.goToNextSlide(e)} side="right">
                    <RightArrow />
                </CarouselArrow>
            </div>
        );
    }
}
