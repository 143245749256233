import React from 'react';
import _ from 'underscore';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UsersStatistics from "../../../models/collections/UsersStatistics";
import AppComponent from "../../AppComponent";
import Projects from "../../../models/collections/Projects";

const ASCENDING_ORDER = "asc";

export default class UsersAspirationsReport extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            usersStatistics: new UsersStatistics([]),
            headings: []
        };
        _.bindAll(this, 'getUsersAspirationsSuccessCallback', 'getUsersAspirationsErrorCallback',
            'getHeadings', 'renderUsersAspirations', 'renderHeadings', 'renderProjects');
    }

    componentWillMount() {
        const { usersStatistics } = this.state;
        usersStatistics.fetchUsersAspirations(this.getUsersAspirationsSuccessCallback);
    }

    renderProjects(userProjects) {
        const projects = userProjects.map((userProject, index) => (
            <span key={userProject.get('id')}>
                {index > 0 ? ", " : ""}
                <Link to={`/customers/${userProject.get('customer_id')}/projects/map/${userProject.get('id')}`}>
                    {userProject.get('name')}
                </Link>
            </span>
        ));
        return <span key={userProjects.toString()}>{projects}</span>;
    }

    renderHeadings() {
        return (
            <tr key="user-aspirations-headings" className="table-header">
                <th>Id</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Assigned Project</th>
                <th>Aspiration</th>
            </tr>
        );
    }

    renderUsersAspirations() {
        const { usersStatistics } = this.state;
        const order = ASCENDING_ORDER;
        usersStatistics.sortBy('id', order, parseInt);
        const users = [];
        for (let index = 0; index < usersStatistics.getSize(); index += 1) {
            const userStatistics = usersStatistics.get(index);
            const projects = new Projects(userStatistics.get('projects'));
            const userId = userStatistics.get('id');
            users.push(
                <tr key={userId} className="table-data break-word">
                    <td>{index + 1}</td>
                    <td>{userStatistics.get('name')}</td>
                    <td>{userStatistics.get('designation') || "N/A"}</td>
                    <td>{this.renderProjects(projects)}</td>
                    <td className="width-30 text-transform-none">
                        {userStatistics.get('aspiration') || "N/A"}
                    </td>
                </tr>
            );
        }
        return users;
    }

    getUsersAspirationsSuccessCallback(response) {
        const usersStatistics = new UsersStatistics(response);
        this.setState({ usersStatistics, headings: this.getHeadings() });
    }

    getUsersAspirationsErrorCallback(error) {
        console.error("error: ", error);
    }

    getHeadings() {
        return [
            { text: 'Id', column_name: 'id' },
            { text: 'Name', column_name: 'name' },
            { text: 'Aspiration', column_name: 'aspiration' },
            { text: 'Designation', column_name: 'designation' },
            { text: 'Projects', column_name: 'projects' }
        ];
    }

    render() {
        return (
            <div className="col-md-10">
                <div className="heading-1">Aspirations</div>
                <div className="margin-top-3">
                    <center>
                        <Table bordered responsive>
                            <thead>
                                {this.renderHeadings()}
                            </thead>
                            <tbody>
                                {this.renderUsersAspirations()}
                            </tbody>
                        </Table>
                    </center>
                </div>
            </div>

        );
    }
}
