import React from 'react';
import {
    Col, Row
} from "react-bootstrap";
import DonutChart from 'react-google-charts';
import {
    BLACK_COLOR_CODE,
    COLORSUPTOSIXVACANCIES, COLORSUPTOTHIRTEENVACANCIES,
    COLORSUPTOTHREEVACANCIES,
    WHITE_COLOR_CODE
} from "../../utils/constants";
import ProjectDetails from "./ProjectDetails";

export default class UserProjectDetails extends React.PureComponent {
    convertToChartData = () => {
        const {
            selectedProject, labelKey, valueKey
        } = this.props;
        const { roles = [] } = selectedProject;
        const chartData = [[labelKey, valueKey]];
        for (let i = 0; i < roles.length; i += 1) {
            chartData.push([roles[i][labelKey], roles[i][valueKey]]);
        }
        return chartData;
    };

    getColors = (chartData) => {
        if (chartData.length <= 4) {
            return COLORSUPTOTHREEVACANCIES;
        }
        if (chartData.length <= 7) {
            return COLORSUPTOSIXVACANCIES;
        }

        return COLORSUPTOTHIRTEENVACANCIES;
    }

    render() {
        const {
            selectedProject, history, location
        } = this.props;
        const chartData = this.convertToChartData();
        const PIE_SLICE_TXT_COLOR = chartData.length === 2 ? BLACK_COLOR_CODE : WHITE_COLOR_CODE;
        const colors = chartData ? this.getColors(chartData) : [{}];
        return (
            <Col xs={6} md={6} className="user-project-details">
                <Row>
                    <Col xs={7} md={7} className="user-project-piechart">
                        <DonutChart
                            width="100%"
                            height="18em"
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={chartData}
                            options={{
                                title: '',
                                pieHole: 0.4,
                                backgroundColor: "rgb(246, 248, 250)",
                                legend: { position: 'bottom', maxLines: 2 },
                                pieSliceTextStyle: { color: PIE_SLICE_TXT_COLOR },
                                slices: colors
                            }}
                            rootProps={{ 'data-testid': '3' }}
                        />
                    </Col>
                    <Col xs={5} md={5} className="legends">
                        <ProjectDetails selectedProject={selectedProject} history={history} location={location} />
                    </Col>
                </Row>
            </Col>
        );
    }
}
