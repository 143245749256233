import moment from "moment";
import BaseCollection from "./BaseCollection";
import ProjectRole from "../ProjectRole";
import { MESSAGES } from "../../utils/constants";
import ProjectRolesGroups from "./ProjectRolesGroups";

export default class ProjectRoles extends BaseCollection {
    getModel(attributes) {
        return new ProjectRole(attributes);
    }

    getModelToJson(projectRole) {
        return projectRole.model;
    }

    getProjectRolesGroups() {
        const projectRolesMap = new ProjectRolesGroups([]);
        this.collection.map((model) => {
            if (!model._destroy) {
                const skillIds = model.get('project_role_skills').getSkillIds();
                const insertAt = projectRolesMap.findIndexOfProjectRoleWith({
                    roleId: model.get('role_id'),
                    projectRoleSkills: skillIds
                });
                if (insertAt !== -1) {
                    const projectRolesGroup = projectRolesMap.get(insertAt);
                    projectRolesGroup.push(model);
                    projectRolesMap.set(insertAt, projectRolesGroup);
                } else {
                    projectRolesMap.add([]);
                    projectRolesMap.get([projectRolesMap.getSize() - 1]).push(model);
                }
            }
        });
        return projectRolesMap;
    }

    getGroupedProjectRolesByRoleId() {
        const projectRolesMap = new Map();
        this.collection.map((model) => {
            if (!model.get('_destroy')) {
                if (projectRolesMap.has(model.get('role_id'))) {
                    projectRolesMap.get(model.get('role_id')).push(model);
                } else {
                    projectRolesMap.set(model.get('role_id'), []);
                    projectRolesMap.get(model.get('role_id')).push(model);
                }
            }
        });
        return projectRolesMap;
    }

    getProjectRolesMap() {
        const projectRolesMap = new Map();
        this.collection.map((collection, index) => {
            const { model } = this.collection[index];
            if (!model._destroy) {
                if (projectRolesMap.has(model.role_id)) {
                    projectRolesMap.set(model.role_id, projectRolesMap.get(model.role_id) + 1);
                } else {
                    projectRolesMap.set(model.role_id, 1);
                }
            }
        });
        return projectRolesMap;
    }

    remove(index) {
        const projectRole = this.collection[index];
        if (!projectRole.get('id')) {
            this.collection.splice(index, 1);
            return;
        }
        projectRole.model._destroy = true;
        this.collection[index] = projectRole;
    }

    addProjectRole(roleId, roleStartDate = "", roleEndDate = "") {
        const projectRole = new ProjectRole({
            role_id: roleId,
            project_role_skills: [],
            project_role_users: [],
            role_end_date: roleEndDate,
            role_start_date: roleStartDate
        });
        this.collection.push(projectRole);
    }

    destroy(role) {
        const projectRoles = this.collection;
        projectRoles.map((projectRole, index) => {
            if (projectRole === role) {
                if (role.get('id') === undefined) {
                    projectRoles.splice(index, 1);
                    return;
                }
                projectRole.set('_destroy', true);
                return true;
            }
        });
    }

    addBulk(projectRole, numberOfProjectRolesToBeAdded) {
        const counter = numberOfProjectRolesToBeAdded;
        for (let index = 0; index < counter; index += 1) {
            this.collection.push(projectRole);
        }
    }

    addRoles(projectRoles) {
        projectRoles.map(projectRole => this.collection.push(projectRole));
    }

    removeBulk(projectRole, numberOfProjectRolesToBeRemoved) {
        const projectRolesGroups = this.getProjectRolesGroups();
        const projectRoleIndex = projectRolesGroups.findIndexOfProjectRoleWith({
            roleId: projectRole.get('role_id'),
            projectRoleSkills: projectRole.get('project_role_skills').getSkillIds()
        });
        this.collection.splice(projectRoleIndex, numberOfProjectRolesToBeRemoved);
    }

    validate(startDate, endDate) {
        let modelState = {};
        for (let index = 0; index < this.collection.length; index += 1) {
            const projectRoleUsers = this.collection[index].get('project_role_users');
            modelState = projectRoleUsers.validate(startDate, endDate);
            if (!modelState.isValid) {
                return modelState;
            }
        }
        return modelState;
    }

    areProjectRoleSkillsEmpty() {
        for (let index = 0; index < this.collection.length; index += 1) {
            const projectRole = this.collection[index];
            if (projectRole.areProjectRoleSkillsEmpty()) {
                return { isValid: false, errorMessage: MESSAGES.PROJECT_SKILLS_CANNOT_BE_EMPTY };
            }
        }
        return { isValid: true, errorMessage: "" };
    }

    changeRoleStartDates(startDate, projectEndDate) {
        const projectRoles = this.collection;
        projectRoles.map((projectRole) => {
            const projectRoleUsers = projectRole.get('project_role_users');
            const roleEndDate = new moment(projectRole.get('role_end_date')) >= new moment(startDate)
                ? projectRole.get('role_end_date') : projectEndDate;
            projectRoleUsers.changeUsersStartDate(startDate, roleEndDate);
            const roleStartDate = new moment(projectRole.get('role_start_date'));
            if (roleStartDate < new moment(startDate)) {
                projectRole.set('role_start_date', startDate);
                projectRole.set('role_end_date', roleEndDate);
            }
        });
    }

    changeRoleEndDates(endDate) {
        const projectRoles = this.collection;
        projectRoles.map((projectRole) => {
            const roleEndDate = new moment(projectRole.get('role_end_date'));
            if (roleEndDate > new moment(endDate)) {
                projectRole.set('role_end_date', endDate);
            }
            const projectRoleUsers = projectRole.get('project_role_users');
            if (projectRoleUsers.getSize() > 0) {
                projectRoleUsers.changeUsersEndDate(endDate);
            }
        });
    }
}
