import React, { Component } from "react";
import Moment from "react-moment";
import $ from "jquery";
import _ from "underscore";
import VisaValidation from "./visaValidations";
import VisaEdit from "./visaEdit";
import { getApiUrl } from "../../utils/serviceUrls";
import DateSelector from "../common/DateSelector";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "../common/Input";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import VisaTable from "./VisaTable";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F9CE1F",
    },
  },
});

export default class Visa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorInTable: "",
      visaType: "",
      visaCountry: "",
      visaValidity: "",
      country: [],
      currentInvalidFields: [],
      visaTypeErrorText : false,
    };
    this.invalidField = {};
    this.FIELD_ARE_EMPTY = "All fields are mandatory.";
    this.ERR_DUPLICATE_ENTRY = "There was a duplicate entry";
    _.bindAll(
      this,
      "handleInsertRow",
      "passParametersFromSameComponent",
      "checkAvailability"
    );
  }

  componentWillMount() {
    $.ajax({
      url: getApiUrl("COUNTRIES"),
      success: (response) => {
        const countries = [];
        for (const key in response) {
          countries.push(
            <option key={response[key].name}>{response[key].name}</option>
          );
        }
        this.setState({ country: countries });
      },
    });
  }

  
  handleStateAsissngment(key, value) {
    this.setState({
      [key]: value,
    });
  }

  passParametersFromSameComponent(key, value) {
    this.handleStateAsissngment("errorInTable", "");
    this.handleStateAsissngment(key, value);
  }

  convertVisaPropToHtml() {
    const visaHtml = [];
    const { visa, rowEditVisa, handleDeleteVisa } = this.props;
    for (const key in visa) {
      visaHtml.push(
        <tr
          className="visa-edit-modal"
          key={`${visa[key].country}-${visa[key].type_of_visa}-${visa[key].validity}`}
        >
          <td className="width-25">{visa[key].country}</td>
          <td className="width-25">{visa[key].type_of_visa}</td>
          <td className="width-25">
            <Moment format="DD-MM-YYYY">
              {visa[key].validity !== "" ? visa[key].validity : null}
            </Moment>
          </td>
          <td className="width-25">
            <VisaEdit
              country={this.state.country}
              visa={visa[key]}
              checkAvailability={this.checkAvailability}
              rowEditVisa={rowEditVisa}
            />
            <button
              type="button"
              className="width-25 btn btn-danger"
              onClick={(event) =>
                handleDeleteVisa(
                  `${visa[key].country}-${visa[key].type_of_visa}-${visa[key].validity}`,
                  event
                )
              }
            >
              {" "}
              <img
                className="height-20"
                src="./bin.svg"
                alt="edit skill"
              />{" "}
            </button>
          </td>
        </tr>
      );
    }

    return visaHtml;
  }

  checkAvailability(uniqcode) {
    const { visa } = this.props;
    for (const key in visa) {
      if (
        `${visa[key].country}-${visa[key].type_of_visa}-${visa[key].validity}` ===
        uniqcode
      )
        return true;
    }
    return false;
  }

  handleInsertRow() {
    const {
      visaCountry,
      visaValidity,
      visaType,
      currentInvalidFields,
      visaTypeErrorText
    } = this.state;
    const visa = {
      country: visaCountry,
      validity: visaValidity,
      type_of_visa: visaType,
    };
    if (visaType === "") {
      this.setState({
        visaTypeErrorText: true,
      });
      return;
    }
    const validation = new VisaValidation(visa, this.state);
    validation.removeInvalidId(currentInvalidFields);
    const isError = validation.validate();
    const invalidField = validation.getInvalidColumnsId();
    this.handleStateAsissngment("currentInvalidFields", invalidField);
    const newInvalidField = {};
    if (!isError) {
      for (const field in invalidField)
        newInvalidField[invalidField[field]] = 1;
      this.invalidField = newInvalidField;
      return this.handleStateAsissngment("errorInTable", this.FIELD_ARE_EMPTY);
    }
    const havingSimilar = validation.checkAvailability(visa, this.props.visa);
    if (havingSimilar) {
      this.invalidField.country = 1;
      return this.handleStateAsissngment(
        "errorInTable",
        this.ERR_DUPLICATE_ENTRY
      );
    }
    this.handleStateAsissngment("visaCountry", "");
    this.handleStateAsissngment("visaType", "");
    this.handleStateAsissngment("visaValidity", "");
    this.handleStateAsissngment("visaTypeErrorText", false);
    this.props.handleAddVisa(visa);
  }

  render() {
    const { visa, error } = this.props;
    const {
      errorInTable,
      visaCountry,
      country,
      visaType,
      visaValidity,
      visaTypeErrorText,
    } = this.state;
    const visaHtml = this.convertVisaPropToHtml(visa);
    return (
      <div id="visa">
        <div className="mandatory margin-top-2 margin-left-6">
          {error ? "" : errorInTable}
        </div>

        <Grid
          container
          spacing={4}
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <Grid item sm>
            <FormControl variant="outlined">
              <InputLabel id="country-label">Country</InputLabel>

              <Select
                style={{ width: "28ch" }}
                labelId="country-label"
                id="Country"
                label="Country"
                onChange={(event) =>
                  this.passParametersFromSameComponent(
                    "visaCountry",
                    event.target.value
                  )
                }
                value={visaCountry}
              >
                {country.map((item) => (
                  <MenuItem value={item.key} key={item.key}>
                    {item.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm>
            <TextField
              required={true}
              //style={width='100%'}
              id="visa"
              label="Visa-Type(H1-B etc)"
              variant="outlined"
              handleChange={this.handleInputChange}
              onChange={(event) =>
                this.passParametersFromSameComponent(
                  "visaType",
                  event.target.value
                )
              }
              error={this.state.visaTypeErrorText}
              helperText={
                this.state.visaTypeErrorText ? "This field is required" : ""
              }
              value={visaType}
            />
          </Grid>

          <Grid item sm>
            <TextField
              id="date"
              label="Visa-Validity"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) =>
                this.passParametersFromSameComponent(
                  "visaValidity",
                  event.target.value
                )
              }
              value={visaValidity}
            />
          </Grid>

          <Grid item sm>
            <MuiThemeProvider theme={theme}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={this.handleInsertRow}
              >
                Add
              </Button>
            </MuiThemeProvider>
          </Grid>
        </Grid>
        <br />
        <VisaTable
          visaDetails={visa}
          handleDeleteVisa={this.props.handleDeleteVisa}
          handleEditVisa={this.props.rowEditVisa}
          countries={country}
        />
      </div>
    );
  }
}
