import moment from "moment";
import BaseCollection from "./collections/BaseCollection";

const propertyMappings = {
    project_roles: 'project_roles_attributes',
    project_role_users: 'project_role_users_attributes',
    project_role_skills: 'project_role_skills_attributes',
    skill: 'skill_attributes',
    user: 'user_attributes'
};

Object.defineProperty(Array.prototype, "getJsonArray", {
    enumerable: false,
    writable: false,
    value() {
        const collection = [...this];
        const transformed = collection.map(element => element.getJsonObject());
        return transformed;
    }
});

Object.defineProperty(Object.prototype, "getJsonObject", {
    enumerable: false,
    writable: false,
    value() {
        const returnJSON = { ...this };
        for (const key in returnJSON) {
            if (Array.isArray(returnJSON[key])) {
                returnJSON[key] = returnJSON[key].getJsonArray();
            }
        }
        return renameKeys(propertyMappings, returnJSON);
    }
});

const renameKeys = (propertyMappings, model) => Object
    .keys(model)
    .reduce((accumulator, key) => ({
        ...accumulator,
        ...{ [propertyMappings[key] || key]: model[key] }
    }), {});

export default class BaseModel {
    constructor(attributes) {
        this.model = attributes || {};
    }

    set(key, value) {
        this.model[key] = value;
    }

    get(key) {
        return this.model[key];
    }

    getMoment(key) {
        return new moment(this.model[key]);
    }

    setPath(path) {
        this.path = path;
    }

    getAuthHeaders() {
        return {
            Authorization: window.loggedInUser.auth_token
        };
    }

    getJson() {
        const returnJSON = { ...this.model };
        for (const key in returnJSON) {
            if (returnJSON[key] instanceof BaseCollection) {
                returnJSON[key] = returnJSON[key].getCollectionToJson();
            } else if (Array.isArray(returnJSON[key])) {
                returnJSON[key] = returnJSON[key].getJsonArray();
            } else if (returnJSON[key] instanceof BaseModel) {
                returnJSON[key] = returnJSON[key].model;
            }
        }
        return renameKeys(propertyMappings, returnJSON);
    }

    getConstraints() {
        return { };
    }

    validate() {
        const validate = require("validate.js");
        return validate(this.model, this.getConstraints());
    }

    getValueOrDefault(value, defaultValue) {
        if (value) {
            return value;
        }
        return defaultValue;
    }
}
