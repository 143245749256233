import React from "react";
import { Col, Row } from "react-bootstrap";
import Select from 'react-select';
import AppComponent from "../../AppComponent";
import ProjectRoles from "../../../models/collections/ProjectRoles";
import EditProjectRoleGroupRow from "./EditProjectRoleGroupRow";
import Skills from "../../../models/collections/Skills";
import { TOOlTIPS } from "../../../utils/constants";
import TooltipWithImage from "../../common/Tooltip";

const INFO_ICON = "/info-icon.png";

export default class EditProjectAddRole extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            groupedProjectRoles: [],
            skills: new Skills([])
        };
    }

    componentWillMount() {
        this.fetchSkills();
    }

    renderProjectRoleRowHeaders = () => {
        const { groupedProjectRoles } = this.state;
        if (groupedProjectRoles.length) {
            return (
                <Row className="add-new-role">
                    <Col md={2} />
                    <Col md={1} />
                    <Col md={3}>Required Skill</Col>
                    <Col md={2}>Start Date</Col>
                    <Col md={2}>End Date</Col>
                </Row>
            );
        }
    }

    getGroupedProjectRoles = () => this.state.groupedProjectRoles;

    fetchSkills = () => {
        const { skills } = this.state;
        skills.getSkills(this.skillsSuccessCallback);
    }

    skillsSuccessCallback = (response) => {
        this.setState({ skills: new Skills(response) });
    };

    asOptions = () => {
        const { roles } = this.props;
        return roles.collection.map(role => (
            { label: role.get('name'), value: role.get('id') }
        ));
    }

    updateGroupedProjectRoles = (groupedProjectRoles) => {
        this.setState({ groupedProjectRoles });
    }

    handleRoleChange = ({ value }) => {
        const { groupedProjectRoles } = this.state;
        const { project } = this.props;
        const roleStartDate = project.get('actual_start_date')
            ? project.get('actual_start_date') : project.get('tentative_start_date');
        const roleEndDate = project.get('actual_end_date')
            ? project.get('actual_end_date') : project.get('tentative_end_date');
        const projectRoles = new ProjectRoles([]);
        projectRoles.addProjectRole(value, roleStartDate, roleEndDate);
        groupedProjectRoles.push({ projectRoles, noOfRoles: 1 });
        this.setState({ selectedRole: "" });
    }

    renderProjectRoleRows = () => {
        const { groupedProjectRoles, skills } = this.state;
        const { roles } = this.props;
        const rows = groupedProjectRoles.map((groupedRole) => {
            const projectRoles = groupedRole.projectRoles;
            if (projectRoles.getSize()) {
                return (
                    <EditProjectRoleGroupRow
                        projectRoles={projectRoles}
                        groupedProjectRoles={groupedProjectRoles}
                        noOfRoles={groupedRole.noOfRoles}
                        roles={roles}
                        skills={skills}
                        updateGroupedProjectRoles={this.updateGroupedProjectRoles}
                        project={this.props.project}
                    />
                );
            }
        });
        return rows;
    }

    render() {
        const { selectedRole, groupedProjectRoles } = this.state;
        return (
            <div>
                <Row className="role-attributes">
                    <Col md={2} className="text-medium-large">
                    Roles&nbsp;
                        <TooltipWithImage src={INFO_ICON} tooltipText={TOOlTIPS.ADD_ROLES} />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Select
                            placeholder="Add new role"
                            options={this.asOptions()}
                            onChange={this.handleRoleChange}
                            value={selectedRole}
                            data-test="add-new-role"
                        />
                    </Col>
                </Row>
                <div className={`${groupedProjectRoles.length ? "mapping-table col-md-11 width-84" : ""}`}>
                    {this.renderProjectRoleRowHeaders()}
                    {this.renderProjectRoleRows()}
                </div>
            </div>
        );
    }
}
