import React, { Component } from 'react';
import Select from 'react-select';
import Users from "../../models/collections/Users";

export default class SalesExecutive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            executiveList: []
        };
        this.handleChangeOfName = this.handleChangeOfName.bind(this);
    }

    componentWillMount() {
        const users = new Users([]);
        users.getWithSalesRole((response) => {
            this.setState({
                executiveList: response.data
            });
        });
    }

    handleChangeOfName(event) {
        const { handleChangeInParentComponent } = this.props;
        handleChangeInParentComponent('sales_executive', event.value);
    }

    render() {
        const { executiveList } = this.state;
        const { sales_executive } = this.props;
        let salesFieldColor;
        if (this.props.inputFieldColor) {
            salesFieldColor = this.props.inputFieldColor.sales_executive;
        }
        return (
            <div>
                <div>
                    <label className="labels">
                        Sales Executive:
                        <span>
                            <i
                                className='field-indicator-color'
                            >
*
                            </i>
                        </span>
                    </label>
                </div>
                <div id='sales-executive'>
                    <Select
                        multi={false}
                        options={executiveList}
                        value={sales_executive || ''}
                        onChange={this.handleChangeOfName}
                        className={`${salesFieldColor === "field-with-error" ? "sales-error" : null} full-width`}
                    />
                </div>
            </div>
        );
    }
}
