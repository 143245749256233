import $ from "jquery";
import axios from "axios";
import Skill from "../Skill";
import BaseCollection from "./BaseCollection";
import { getApiUrl } from "../../utils/serviceUrls";

export default class Skills extends BaseCollection {
    getModel(attributes) {
        return new Skill(attributes);
    }

    hasSkill(skillToBeChecked) {
        return this.collection.some(
            skill => skill.get('skill_type') === skillToBeChecked.get('skill').get('skill_type')
        );
    }

    fetchAvailableSkills({ method, headers: customerHeaders }, successCallback, errorCallback) {
        const defaultHeaders = {
            Authorization: window.loggedInUser.auth_token
        };

        const headers = { ...defaultHeaders, ...customerHeaders };

        const success = (response) => {
            this.collection = new Skills(response).collection;
            if (successCallback) {
                return successCallback(response);
            }
        };
        return $.ajax({
            url: this.path,
            method,
            headers,
            success,
            error: errorCallback
        });
    }

    getSkills(callback) {
        const url = getApiUrl("GET_SKILLS");
        axios.get(url,
            { headers: this.getAuthHeaders() })
            .then(response => callback(response.data));
    }

    getPaginatedSkills(params, callback) {
        const url = getApiUrl("GET_PAGINATED_SKILLS", params);
        axios.get(url,
            { headers: this.getAuthHeaders() })
            .then(response => callback(response.data));
    }

    getUsersWithPrivilegedRole(callback) {
        axios.get(getApiUrl("GET_ADMIN_AND_OWNER_USERS"),
            { headers: super.getAuthHeaders() })
            .then((response) => {
                callback(new Users(response.data));
            });
    }

    mergeSkills(dataToBeMerged, preferredId, callback) {
        const url = getApiUrl("MERGE_SKILLS");
        axios.post(url, { selected_ids: Object.keys(dataToBeMerged), preferred_id: preferredId },
            { headers: this.getAuthHeaders() })
            .then(callback);
    }
}
