import React from "react";
import { Row, Col } from "react-bootstrap";
import ShowProjectRoleRow from "./ShowProjectRoleRow";
import ProjectRoles from "../../../models/collections/ProjectRoles";
import AppComponent from "../../AppComponent";
import { ROLES } from "../../../utils/constants";

export default class ShowProjectRoleGroup extends AppComponent {
    renderHeaders = () => (
        <Col md={11}>
            <Row className="mapping-table-data margin-bottom-15px">
                <Col md={2}>Start Date</Col>
                <Col md={2} className="margin-left-negative-5">End Date</Col>
                <Col md={2} className="margin-left-negative-5">Required Capacity</Col>
                <Col md={2}>Mapped Employee</Col>
                {window.loggedInUser.role === ROLES.USER
                    ? ""
                    : (
                        <React.Fragment>
                            <Col md={3}>Matched Skills</Col>
                            <Col md={2}>Mapped Capacity</Col>
                        </React.Fragment>
                    )}
            </Row>
        </Col>
    )

    renderProjectRoleRows = () => {
        const { elements } = this.props;
        const activeRoles = elements.filter(projectRole => projectRole.get("_destroy") !== true);
        const filteredRoles = new ProjectRoles(activeRoles);
        const row = filteredRoles.map(element => (
            <ShowProjectRoleRow
                key={element.get('id')}
                projectRole={element}
            />
        ));
        return row;
    }

    render() {
        const { toggle, noOfRoles } = this.props;
        if (toggle && noOfRoles) {
            return (
                <div>
                    {this.renderHeaders()}
                    {this.renderProjectRoleRows()}
                </div>
            );
        }

        return (
            <div />
        );
    }
}
