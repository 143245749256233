import React from 'react';
import { Table } from 'react-bootstrap';
import formatDate from "../../../utils/formatDate";

export default function OpenRoleTable(props) {
    const handleMapClick = (customerId, projectId) => {
        const { history, lastPageUrl } = props;
        const url = `/customers/${customerId}/projects/map/${projectId}`;
        history.push(`${url}`, { customerID: customerId, lastPageUrl });
    };

    const getRows = (data) => {
        const rows = data.map(openRole => (
            <tr key={openRole.project_role_id}>
                <td className="width-15">{openRole.project_name}</td>
                <td className="width-20">{openRole.role_name}</td>
                <td className="width-25">{openRole.role_skills.join(', ')}</td>
                <td className="width-10">
                    { formatDate(openRole.role_start_date)}
                </td>
                <td className="width-10">
                    { formatDate(openRole.role_end_date)}
                </td>
                <td className="width-10">
                    {openRole.project_role_id.length}
                </td>
                <td className="width-10">
                    <button
                        type="button"
                        title="Map"
                        onClick={() => handleMapClick(openRole.customer_id, openRole.project_id)}
                        className="btn margin-left-5"
                    >
                        <span>
                            <img src="/mapping.svg" alt="team" />
                        </span>
                    </button>
                </td>
            </tr>
        ));
        return rows;
    };


    const renderData = () => {
        const { data } = props;
        const rows = getRows(data.model);
        return rows;
    };


    return (
        <Table bordered responsive className="margin-top-1">
            <thead>
                <tr className="table-header">
                    <th className="width-15">Project name</th>
                    <th className="width-20">Role</th>
                    <th className="width-25">Skills</th>
                    <th className="width-10">Start date</th>
                    <th className="width-10">End date</th>
                    <th className="width-10">No. people</th>
                    <th className="width-10">Action</th>
                </tr>
            </thead>
            <tbody>
                {renderData()}
            </tbody>
        </Table>
    );
}
