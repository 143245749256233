import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import _ from "underscore";
import SkillSection from "./skills";
import Certification from "./certification";
import Url from "./urls";
import VisaDetails from "./visa";
import WorkingFrom from "./workingFrom";
import Profile from "./profile";

import { SUCCESS_CODES, TIME_INTERVAL } from "../../utils/constants";
import User from "../../models/User";
import Input from "../common/Input";
import Fab from "@material-ui/core/Fab";
import NavigationIcon from "@material-ui/icons/Navigation";
import SaveDetails from "./saveDetails";
import AspirationBox from "./aspirationBox";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const toastr = require("toastr");

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F9CE1F",
    },
  },
});

export default class ManageProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      errorMessage: "",
      errorColumns: [],
      user: new User({}),
    };

    this.ERROR_MSG =
      "Please Fill the Required Field or Enter a Valid Entry in Highlighted field";
    _.bindAll(
      this,
      "handleClick",
      "handleDeleteVisa",
      "rowEditSkill",
      "rowEditVisa",
      "getProfileSuccessCallback",
      "getProfileErrorCallback",
      "handleAddSkill",
      "handleAddVisa",
      "handleDeleteSkill",
      "updateUserProfileSuccessCallback",
      "updateUserProfileErrorCallback"
    );
  }

  componentWillMount() {
    const { user } = this.state;
    user.getProfile(
      { id: window.loggedInUser.id },
      this.getProfileSuccessCallback,
      this.getProfileErrorCallback
    );
  }

  getProfileErrorCallback(error) {
    console.error("error:", error);
  }

  getProfileSuccessCallback(response) {
    const user = new User(response);
    this.setState({ user });
  }

  handleDeleteSkill(uniqueKey) {
    const { user } = this.state;
    user.deleteSkill(uniqueKey);
    this.setState({ user });
  }


  //TODO: Fix this for deleting certificate
  handleCertificateDeletion(uniqueKey) {
    const { user } = this.state;
    user.deleteSkill(uniqueKey);
    this.setState({ user });
  }

  handleAddSkill(newSkill) {
    const { user } = this.state;
    user.addSkill(newSkill);
    this.setState({ user });
  }

  rowEditSkill(visa, uniqueKey) {
    const { user } = this.state;
    user.editSkill(visa, uniqueKey);
    this.setState({ user });
  }

  handleDeleteVisa(uniqueKey) {
    const { user } = this.state;
    user.deleteVisa(uniqueKey);
    this.setState({ user });
  }

  handleAddVisa(visa) {
    const { user } = this.state;
    user.addVisa(visa);
    this.setState({ user });
  }

  rowEditVisa(visa, uniqueKey) {
    const { user } = this.state;
    user.editVisa(visa, uniqueKey);
    this.setState({ user });
  }

  addUserIdAndSkillId(userSkills) {
    const updatedUserSkills = [];
    for (const key in userSkills) {
      const userSkill = { ...userSkills[key] };
      userSkill.skill_id = userSkill.skill.id;
      userSkill.user_id = window.loggedInUser.id;
      userSkill.skill_attributes = userSkill.skill;
      delete userSkill.skill;
      updatedUserSkills.push(userSkill);
    }
    return updatedUserSkills;
  }

  updateUserProfileSuccessCallback() {
    toastr.options = { closeButton: true };
    toastr.success(SUCCESS_CODES.UPDATE_PROFILE_SUCCESS);
    const { history } = this.props;
    history.push({
      pathname: "/dashboard",
      state: { notice: SUCCESS_CODES.UPDATE_PROFILE_SUCCESS },
    });
  }

  updateUserProfileErrorCallback(error) {
    console.error("error: ", error);
    const { errorColumns } = this.state;
    for (const key in errorColumns) {
      document
        .getElementById(errorColumns[key])
        .classList.remove("field-with-error");
    }
    const errorKeys = [];
    for (const key in error.responseJSON.errors) {
      errorKeys.push(key);
      document.getElementById(key).classList.add("field-with-error");
    }
    window.scrollTo(0, 0);
    this.setState({
      errorColumns: errorKeys,
    });
    toastr.error(this.ERROR_MSG, "Error", {
      timeOut: TIME_INTERVAL.FIFTEEN_HUNDRED,
    });
  }

  handleModelChange = (key, value) => {
    const { user } = this.state;
    user.set(key, value);
    this.setState({
      user,
    });
  };

  handleInputChange = (event) => {
    const { id: key, value } = event.target;
    const { user } = this.state;
    user.set(key, value);
    this.setState({
      user,
    });
  };

  handleClick() {
    if (
      this.refs.url_component
        ? !this.refs.url_component.isAllUrlsValid()
        : false
    ) {
      return;
    }
    const { user } = this.state;
    user.addUserIdAndSkillId();
    const { isValid, errorMessage } = user.validate();
    if (!isValid) {
      toastr.error(errorMessage, "Error", {
        timeOut: TIME_INTERVAL.FIFTEEN_HUNDRED,
      });
      return;
    }
    user.updateProfile(
      this.updateUserProfileSuccessCallback,
      this.updateUserProfileErrorCallback
    );
  }

  render() {
    const { errorMessage, id, user } = this.state;

    const userSkills = user.get("users_experience")
      ? user.get("users_experience").filter((eachSkill) => !eachSkill._destroy)
      : [];

    const visa = user.get("visa_details")
      ? user.get("visa_details").filter((eachVisa) => !eachVisa._destroy)
      : [];

    return (
      <div>
        <form className="form my-profile">
          <Col md={10} className="list-page">
            <Row>
              <Col md={12}>
                <div className="profiletitles">Basic Information</div>
              </Col>
            </Row>

            <Row>
              <Col md={2}></Col>
            </Row>

            <Row className="form-row">
              <Col md={2}>
                <div>
                  <Profile />
                </div>
              </Col>
              {/* //:TODO: UI done , need to implement backend for it. */}
              {/* <Col className="ml-5 align-self-center align-self-start">
                <div>
                  <Row className="d-flex align-items-start">
                    <div>
                      <UploadAvatar />
                    </div>
  
                    <div>
                      <p className="mt-4 ml-3 profile-page-text-medium">
                        Clear selected image
                      </p>
                    </div>
                  </Row>
                </div>
                <Row>
                  <Col md={12}>
                    <p className="mt-3 upload-instruction">
                      Please upload a .jpg or a .png file not exceeding 5MB
                    </p>
                  </Col>
                </Row> */}
              {/* </Col> */}
            </Row>
            <br></br>
            <br></br>
            <Row>
              <Col md={3}>
                <Input
                  handleChange={this.handleInputChange}
                  name="name"
                  value={user.get("name")}
                  type="text"
                  title="Full Name"
                  required
                />
              </Col>
              <Col md={1} />
              <Col md={6}>
                <WorkingFrom
                  handleModelChange={this.handleModelChange}
                  totalExperience={user.get("total_experience")}
                />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Input
                  handleChange={this.handleInputChange}
                  name="designation"
                  value={user.get("designation")}
                  type="text"
                  title="Designation"
                  required
                  placeholder="Enter your designation"
                />
              </Col>
              <Col md={1} />
              <Col md={7}>
                <Input
                  handleChange={this.handleInputChange}
                  name="resume_url"
                  value={user.get("resume_url")}
                  type="text"
                  title="Resume Link"
                  className="resume"
                  placeholder="Paste your resume link here"
                />
              </Col>
            </Row>
            <br></br>
            <hr></hr>
            <Row className="manage-profile-section form-row">
              <Col md={11}>
                <span className="profiletitles"> Achievements </span>
                <br></br>
                <br></br>
                <br></br>
                <Certification
                  handleModelChange={this.handleModelChange}
                  certification={user.get("certification")}
                  handleDelete={this.handleCertificateDeletion}
                />
              </Col>
            </Row>
            <br></br>
            <hr></hr>

            <Row className="manage-profile-section form-row">
              <Col md={11}>
                <span>
                  <label className="profiletitles"> Skills </label>
                  <span>
                    <i className="field-indicator-color">*</i>
                  </span>
                </span>

                <MuiThemeProvider theme={theme}>
                  <SkillSection
                    error={errorMessage}
                    handleAddSkill={this.handleAddSkill}
                    handleRemovalOfSkill={this.handleDeleteSkill}
                    rowEditSkill={this.rowEditSkill}
                    usersSkill={userSkills}
                    id={id}
                  />
                </MuiThemeProvider>
              </Col>
            </Row>

            <br></br>

            <hr></hr>
            <Row className="manage-profile-section form-row">
              <Col md={11}>
                <div className="profiletitles">Passport Details</div>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Input
                  handleChange={this.handleInputChange}
                  name="passport_no"
                  value={user.get("passport_no")}
                  type="text"
                  title="Passport No"
                  autoComplete={false}
                  placeholder="Enter your passport number"
                />
              </Col>
            </Row>
            <br></br>
            <hr></hr>
            <div className="manage-profile-section form-row">
              <Col md={11}>
                <span className="profiletitles"> Visa Details </span>
                <div>
                  <VisaDetails
                    error={errorMessage}
                    handleAddVisa={this.handleAddVisa}
                    handleDeleteVisa={this.handleDeleteVisa}
                    rowEditVisa={this.rowEditVisa}
                    visa={visa}
                  />
                </div>
              </Col>
            </div>
            <br></br>
            <hr></hr>
            <div className="manage-profile-section form-row">
              <Col md={11}>
                <h1 className="profiletitles">Aspirations</h1>
                <div>
                  <AspirationBox
                    handleModelChange={this.handleModelChange}
                    aspiration={user.get("aspiration")}
                  />
                </div>
              </Col>
            </div>
            <br></br>
            <hr></hr>
            <div className="manage-profile-section form-row">
              <Col md={11}>
                <span className="profiletitles"> Other Details </span>
                <br />
                <br />
                <Url
                  handleModelChange={this.handleModelChange}
                  linkedinUrl={user.get("linkedin_url")}
                  twitterUrl={user.get("twitter_url")}
                  blogUrl={user.get("blog_url")}
                  handleInputChange={this.handleInputChange}
                  ref="url_component"
                />
              </Col>
            </div>
          </Col>
        </form>
        <MuiThemeProvider theme={theme}>
          <SaveDetails handleClick={this.handleClick} />
        </MuiThemeProvider>
      </div>
    );
  }
}
