import React from "react";
import { Row, Col } from "react-bootstrap";
import Roles from "../../../models/collections/Roles";
import ProjectRoleGroup from "./projectRoleGroup";
import Pill from "../../common/Pill";

export default class ProjectMappingTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: new Roles([]),
            toggle: {}
        };
    }

    componentWillMount() {
        const { roles } = this.state;
        roles.getRoles(
            (response) => {
                const updatedRoles = new Roles(response);
                this.setState({ roles: updatedRoles });
            }
        );
    }

    createProjectRoleMap = (projectRoles) => {
        const { roles } = this.state;
        const rolesMap = new Map();
        if (roles.collection.length) {
            projectRoles.map((projectRole) => {
                const roleId = projectRole.get('role_id');
                const roleName = roles.find(roleId).get('name');
                rolesMap[roleId] = roleName;
            });
        }
        return rolesMap;
    };

    handleToggle = (index) => {
        const { toggle } = this.state;
        this.setState({
            toggle: {
                ...toggle,
                [index]: !toggle[index]
            }
        });
    };

    renderUserRoleSkills = (projectRoleSkills) => {
        const userRoleSkills = projectRoleSkills.map((value, index) => (
            <Pill
                key={index}
                value={(value.get('skill').get('skill_type'))}
            />
        ));
        return userRoleSkills;
    }

    getClassName = (toggle) => {
        const cssClass = toggle ? "mapping-table" : "mapping-table link-pointer";
        return cssClass;
    }

    renderProjectRoleGroups = () => {
        const { projectRoles, project, updateProject } = this.props;
        const groupedProjectRoles = projectRoles.getProjectRolesGroups();
        const rolesMap = this.createProjectRoleMap(projectRoles);
        const rows = groupedProjectRoles.collection.map((groupedRole, index) => {
            const roleId = groupedRole[0].get('role_id');
            const projectSkills = groupedRole[0].get('project_role_skills').collection;
            return (
                <Row key={index}>
                    <Col
                        md={11}
                        className={this.getClassName(this.state.toggle[index])}
                        onClick={this.state.toggle[index] ? null : () => this.handleToggle(index)}
                        data-test="tile"
                    >
                        <Row className="role-label">
                            <Col md={2}>
                                {' '}
                                {rolesMap[roleId]}
                            </Col>
                            <Col md={1}>
                                {(`0${groupedRole.length}`).slice(-2)}
                            </Col>
                            <Col md={3} className="user-skill-names">
                                {!this.state.toggle[index] ? (this.renderUserRoleSkills(projectSkills)) : ""}
                            </Col>
                            <Col md={1} className="padding-left-46">
                                {!this.state.toggle[index] ? (
                                    <span>
                                        <img className="expand-collapse-img" alt="Expand" src="/expand.svg" />
                                    </span>
                                )
                                    : (
                                        <span onClick={() => this.handleToggle(index)}>
                                            <img className="expand-collapse-img" alt="Decrease" src="/collapse.svg" />
                                        </span>
                                    )}
                            </Col>
                        </Row>
                        <div className="margin-left-2">
                            <ProjectRoleGroup
                                key={index}
                                roleId={roleId}
                                elements={groupedRole}
                                project={project}
                                updateProject={updateProject}
                                toggle={this.state.toggle[index]}
                            />
                        </div>
                    </Col>
                </Row>
            );
        });
        return rows;
    };

    render() {
        return (

            <>
                {this.renderProjectRoleGroups()}
            </>

        );
    }
}
