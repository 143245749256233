import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { Button, Modal } from 'semantic-ui-react';
import Users from "../../models/collections/Users";
import ListUsers from "./listUsers";
import { MAPS, SUCCESS_CODES, TIME_INTERVAL } from "../../utils/constants";
import UserPermission from "../../models/UserPermission";

const toastr = require("toastr");

export default class PopupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: new Users([]),
            selectedUserId: null,
            selectedRole: null
        };
    }

    componentWillMount() {
        const { users } = this.state;
        users.getAllUsers((users) => {
            this.setState({ users });
        });
    }

    onClose = () => {
        this.setState({ selectedRole: null });
        this.props.closePopup();
    };

    onRoleChange = (event) => {
        const selectedRole = event ? event.value : '';
        this.setState({ selectedRole });
    };

    handleChangeInParentComponent = (id) => {
        this.setState({ selectedUserId: id });
    };

    getOptions = values => values.map(value => ({ label: MAPS.accessRolesMap[value], value, }));

    handleSave = () => {
        const model = new UserPermission({
            role: this.state.selectedRole,
            userId: this.state.selectedUserId
        });

        const errors = model.validate();

        if (errors) {
            this.setState({ errors });
            return;
        }

        const { fetchUsers } = this.props;
        model.updateAccessRole(() => {
            toastr.success(SUCCESS_CODES.UPDATE_ROLE_SUCCESS, 'Success', { timeOut: TIME_INTERVAL.THOUSAND });
            this.onClose();
            fetchUsers();
        });
    };

    renderErrorInstructions = () => {
        if (!this.state.errors) {
            return;
        }
        return (
            <Row>
                <Col md={12} className="margin-top-1 text-danger">
                    <em>Please select member and role.</em>
                </Col>
            </Row>
        );
    };

    render() {
        const { users, selectedRole } = this.state;
        const { showPopup } = this.props;
        return (
            <Modal
                open={showPopup}
            >
                <Modal.Header>
                    <h2>
                    Add Member
                    </h2>
                </Modal.Header>
                <Modal.Content image>
                    <Modal.Description>
                        <Row>
                            <Col md={6}>
                                <ListUsers
                                    users={users}
                                    handleChangeInParentComponent={this.handleChangeInParentComponent}
                                />
                            </Col>
                            <Col md={6}>
                                <Select
                                    multi={false}
                                    options={this.getOptions(["owner", "admin"])}
                                    onChange={this.onRoleChange}
                                    placeholder="Select role"
                                    value={selectedRole}
                                    className="role-field"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} />
                            <Col md={6} className="access-roles-description">
                                <em>{selectedRole ? MAPS.accessRolesDescriptionMap[selectedRole] : ""}</em>
                            </Col>
                        </Row>
                        {this.renderErrorInstructions()}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Row>
                        <Col md={6} >
                            <button className='btn btn-secondary btn-border-secondary float-left' onClick={this.onClose} type="button">
                      Close
                            </button>
                        </Col>
                        <Col md={6}>
                            <button className='btn btn-primary  text-small' onClick={this.handleSave} type="button">
                      Add
                            </button>
                        </Col>
                    </Row>
                </Modal.Actions>
            </Modal>
        );
    }
}
