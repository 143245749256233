import React from 'react';
import SquareDown from "react-icons/lib/fa/caret-square-o-down";

export default class SortHeader extends React.PureComponent {
    render() {
        const {
            title, sortOrder, onSort, key, className
        } = this.props;
        let icon;

        switch (sortOrder) {
        case 'asc':
            icon = (
                <span>
                    <img alt="Ascending" src="/sort-arrow-asc.png" />
                </span>
            );
            break;

        case 'desc':
            icon = (
                <span>
                    <img alt="Descending" src="/sort-arrow-desc.png" />
                </span>
            );
            break;

        default:
            icon = <SquareDown />;
            break;
        }

        return (
            <th onClick={onSort} key={key} className={className}>
                <div>
                    {title }
                    {' '}
                    {icon}
                </div>
            </th>
        );
    }
}
