import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import moment from "moment";
import * as _ from "underscore";
import AppComponent from "../../AppComponent";
import Label from "../../common/Label";
import DateSelector from "../../common/DateSelector";
import Day from "../../../utils/day";
import { ERROR_CODES } from "../../../utils/constants";

export default class EditProjectDetails extends AppComponent {
    changeProjectName = (event) => {
        const { project, updateProject } = this.props;
        project.set('project_name', event.target.value);
        updateProject(project);
    }

    handleDateChange(key, value) {
        const { project, updateProject } = this.props;
        project.set(key, value);
        updateProject(project);
    }

    handleTentativeStartDateChange = (value) => {
        const { project } = this.props;
        const actualStartDate = project.get('actual_start_date');
        const projectEndDate = project.get('actual_end_date')
            ? project.get('actual_end_date') : project.get('tentative_end_date');
        let canUpdateValue = true;
        if (!actualStartDate) {
            canUpdateValue = this.changeProjectRoleAndUserStartDates('tentative_start_date', value);
            this.changeStartDateForNewRolesToBeAdded(value, projectEndDate);
        }
        if (canUpdateValue) {
            this.handleDateChange("tentative_start_date", value);
        }
    }

    changeProjectRoleAndUserStartDates = (key, startDate) => {
        const { project } = this.props;
        const todaysDate = new moment(Day.getTodaysDate());
        const minimumStartDate = this.getMinimumStartDateOfUsers();
        if (minimumStartDate <= todaysDate) {
            this.notifyError(ERROR_CODES.CANNOT_CHANGE_TENTATIVE_START_AS_SOME_USER_HAS_STARTED_WORKING);
            return false;
        }
        project.changeStartDate(key, startDate);
        return true;
    }

    getMinimumStartDateOfUsers = () => {
        const { project } = this.props;
        const projectRoles = project.get('project_roles');
        const minimumStartDates = [];
        projectRoles.collection.map((projectRole) => {
            const projectRoleUsers = projectRole.get('project_role_users');
            if (projectRoleUsers.getSize() > 0) {
                minimumStartDates.push(projectRoleUsers.getStartDateOfUserWhoStartedFirst());
            }
        });
        return _.min(minimumStartDates);
    }

    changeStartDateForNewRolesToBeAdded =(startDate, projectEndDate) => {
        const { newRolesToBeAdded } = this.props;
        newRolesToBeAdded.map((role) => {
            role.projectRoles.changeRoleStartDates(startDate, projectEndDate);
        });
    }

    handleTentativeEndDateChange = (value) => {
        const { project } = this.props;
        const actualEndDate = project.get('actual_end_date');
        const tentativeEndDate = project.get('tentative_end_date');
        let canUpdateValue = true;
        if (!actualEndDate && !tentativeEndDate) {
            this.handleDateChange("tentative_end_date", value);
        }
        if (!actualEndDate) {
            const projectRoles = project.get('project_roles');
            if (projectRoles.getSize() > 0) {
                canUpdateValue = this.changeProjectRoleAndUserEndDates('tentative_end_date', value);
                this.changeEndDatesForNewRolesToBeAdded(value);
            }
        }
        if (canUpdateValue) {
            this.handleDateChange("tentative_end_date", value);
        }
    }

    changeEndDatesForNewRolesToBeAdded =(endDate) => {
        const { newRolesToBeAdded } = this.props;
        newRolesToBeAdded.map((role) => {
            role.projectRoles.changeRoleEndDates(endDate);
        });
    }

    changeProjectRoleAndUserEndDates = (key, endDate) => {
        const { project } = this.props;
        const projectRoles = project.get('project_roles');
        const maximumStartDateArray = this.getMaximumStartDateForUsers();
        const maximumStartDate = _.max(maximumStartDateArray);
        if (maximumStartDate > new moment(endDate)) {
            this.notifyError(ERROR_CODES.CANNOT_CHANGE_TENTATIVE_START_DATE_AS_SOME_USER_MAPPED_WILL_GO_OUT_OF_RANGE);
            return false;
        }
        let maximumRoleStartDate = new moment(projectRoles.collection[0].get('role_start_date'));
        projectRoles.collection.map((projectRole) => {
            if (new moment(projectRole.get('role_start_date')) > maximumRoleStartDate) {
                maximumRoleStartDate = new moment(projectRole.get('role_start_date'));
            }
        });
        if (new moment(endDate) < maximumRoleStartDate) {
            this.notifyError(ERROR_CODES.CANNOT_CHANGE_TENTATIVE_START_DATE_AS_SOME_ROLE_WILL_GO_OUT_OF_RANGE);
            return false;
        }
        const todaysDate = new moment(Day.getTodaysDate());
        if (new moment(endDate) < todaysDate && project.get('project_roles').getSize() > 0) {
            this.notifyError(ERROR_CODES.CANNOT_CHANGE_TENTATIVE_START_DATE_AS_SOME_ROLE_WILL_GO_OUT_OF_RANGE);
            return false;
        }
        project.changeEndDate(key, endDate);
        return true;
    }

    getMaximumStartDateForUsers = () => {
        const { project } = this.props;
        const projectRoles = project.get('project_roles');
        const maximumStartDateArray = [];
        projectRoles.collection.map((projectRole) => {
            const projectRoleUsers = projectRole.get('project_role_users');
            if (projectRoleUsers.getSize() > 0) {
                maximumStartDateArray.push(projectRoleUsers.getStartDateOfUserWhoStartedLast());
            }
        });
        return maximumStartDateArray;
    }

    handleActualStartDateChange = (value) => {
        const { project } = this.props;
        const projectEndDate = project.get('actual_end_date')
            ? project.get('actual_end_date') : project.get('tentative_end_date');
        let canUpdateValue = true;
        canUpdateValue = this.changeProjectRoleAndUserStartDates('actual_start_date', value);
        this.changeStartDateForNewRolesToBeAdded(value, projectEndDate);
        if (canUpdateValue) {
            this.handleDateChange("actual_start_date", value);
        }
    }

    handleActualEndDateChange = (value) => {
        let canUpdateValue = true;
        const { project } = this.props;
        const projectRoles = project.get('project_roles');
        if (projectRoles.getSize() > 0) {
            canUpdateValue = this.changeProjectRoleAndUserEndDates('actual_end_date', value);
            this.changeEndDatesForNewRolesToBeAdded(value);
        }
        if (canUpdateValue) {
            this.handleDateChange("actual_end_date", value);
        }
    }

    render() {
        const { project } = this.props;
        return (
            <div>
                <Row className="section">
                    <Col md={3} xs={5}>
                        <FormGroup>
                            <Label data-test="projectName" className="labels">Project Name</Label>
                            <input
                                className="text-font input-field"
                                value={project.get('project_name')}
                                onChange={this.changeProjectName}
                                data-test="project-name"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={1} xs={5} />
                    <Col md={3} xs={5}>
                        <FormGroup>
                            <Label data-test="customerName" className="labels">Customer Name</Label>
                            <input
                                className="text-font input-field"
                                value={project.get('customer') ? project.get('customer').name : ""}
                                disabled
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="margin-top-2">
                    <Col md={2}>
                        <Label>Tentative Start date</Label>
                        <DateSelector
                            startDate={project.get('tentative_start_date')}
                            onDateSelected={this.handleTentativeStartDateChange}
                            id="tentative-start-date"
                        />
                    </Col>
                    <Col md={2}>
                        <Label>Tentative End date</Label>
                        <DateSelector
                            startDate={project.get('tentative_end_date')}
                            onDateSelected={this.handleTentativeEndDateChange}
                            min={project.get('actual_start_date') ? project.get('actual_start_date')
                                : project.get('tentative_start_date')}
                            id="tentative-end-date"
                        />
                    </Col>
                    <Col md={2}>
                        <Label>Actual Start date</Label>
                        <DateSelector
                            startDate={project.get('actual_start_date')}
                            onDateSelected={this.handleActualStartDateChange}
                            id="actual-start-date"
                        />
                    </Col>
                    <Col md={2}>
                        <Label>Actual End date</Label>
                        <DateSelector
                            startDate={project.get('actual_end_date')}
                            onDateSelected={this.handleActualEndDateChange}
                            id="actual-end-date"
                            min={project.get('actual_start_date') ? project.get('actual_start_date')
                                : project.get('tentative_start_date')}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
