import React, { Component } from 'react';
import ReactGA from 'react-ga';

export default function withTracker(WrappedComponent, options = {}) {
    const trackPath = (pathname) => {
        ReactGA.set({
            pathname,
            ...options
        });
        ReactGA.pageview(pathname);
    };

    const HOC = class extends Component {
        componentDidMount() {
            const pathname = this.props.location.pathname;
            trackPath(pathname);
        }

        componentWillReceiveProps(nextProps) {
            const previousPath = this.props.location.pathname;
            const currentPath = nextProps.location.pathname;

            if (previousPath !== currentPath) {
                trackPage(currentPath);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
}
