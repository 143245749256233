import React from "react";
import { Col, Row } from "react-bootstrap";
import X from "react-icons/lib/go/x";
import moment from "moment";
import DateSelector from "../../common/DateSelector";
import Day from "../../../utils/day";
import AppComponent from "../../AppComponent";
import {
    END_DATE, ERROR_CODES, START_DATE, TIME_INTERVAL
} from "../../../utils/constants";

export default class EditProjectRoleRow extends AppComponent {
    tryDeleteProjectRole = (projectRole) => {
        const { projectRoles, project, updateProject } = this.props;
        if (!projectRole.canDelete()) {
            this.notifyError(ERROR_CODES.CANNOT_DELETE_STARTED_ROLE);
            return;
        }
        projectRoles.destroy(projectRole);
        project.set('project_roles', projectRoles);
        updateProject(project);
    }

    handleRoleStartDateChange = (date) => {
        const { projectRole, updateProject, project } = this.props;
        const dateToBeChanged = new moment(date);
        const projectRoleUsers = projectRole.get('project_role_users');
        let isDateChanged = true;
        const todaysDate = new moment(Day.getTodaysDate());
        if (projectRoleUsers.getSize() > 0) {
            const minimumStartDate = this.getMinimumDate(START_DATE, projectRoleUsers);
            const minimumEndDate = this.getMinimumDate(END_DATE, projectRoleUsers);
            isDateChanged = this.changeStartDateForMappedUser(minimumStartDate, date, minimumEndDate);
        }
        if (dateToBeChanged < todaysDate && isDateChanged) {
            this.notifyError(ERROR_CODES.ALREADY_STARTED_ROLE);
        }
        if (isDateChanged) {
            projectRole.set('role_start_date', date);
            updateProject(project);
        }
    }

    changeStartDateForMappedUser = (minimumStartDate, date, minimumEndDate) => {
        const { projectRole } = this.props;
        const dateToBeChanged = new moment(date);
        const todaysDate = new moment(Day.getTodaysDate());
        const projectRoleUsers = projectRole.get('project_role_users');
        if (dateToBeChanged <= minimumStartDate) {
            projectRole.set('role_start_date', date);
        } else if (minimumStartDate < todaysDate) {
            this.notifyError(ERROR_CODES.USER_STARTED_WORKING);
            return false;
        } else {
            projectRoleUsers.collection.map((projectRoleUser) => {
                const startDate = new moment(projectRoleUser.get('start_date'));
                if (startDate <= dateToBeChanged && dateToBeChanged <= minimumEndDate) {
                    projectRoleUser.set('start_date', date);
                }
            });
            projectRole.set('project_role_users', projectRoleUsers);
        }
        return true;
    }

    getMinimumDate = (key, projectRoleUsers) => {
        let minimumDate = new moment(projectRoleUsers.collection[0].get(key));
        projectRoleUsers.collection.map((projectRoleUser) => {
            const date = new moment(projectRoleUser.get(key));
            if (date <= minimumDate) {
                minimumDate = date;
            }
        });
        return minimumDate;
    }

    handleRoleEndDateChange = (date) => {
        const { projectRole, updateProject, project } = this.props;
        const dateToBeChanged = new moment(date);
        const projectRoleUsers = projectRole.get('project_role_users');
        let isDateChanged = true;
        const todaysDate = new moment(Day.getTodaysDate());
        if (projectRoleUsers.getSize() > 0) {
            const maximumStartDate = this.getMaximumStartDate(projectRoleUsers);
            isDateChanged = this.changeEndDateForMappedUser(maximumStartDate, date, projectRole);
        }
        const alreadyPresentRole = projectRole.get('id');
        const endDate = new moment(projectRole.get('role_end_date'));
        if (dateToBeChanged < todaysDate && alreadyPresentRole) {
            this.notifyError(ERROR_CODES.BACK_DATED_ROLE,
                TIME_INTERVAL.THREE_THOUSAND);
            isDateChanged = false;
        } else if (endDate < todaysDate && alreadyPresentRole) {
            this.notifyError(ERROR_CODES.BACK_DATED_ROLE,
                TIME_INTERVAL.THREE_THOUSAND);
            isDateChanged = false;
        }
        if (isDateChanged) {
            projectRole.set('role_end_date', date);
            updateProject(project);
        }
    }

    changeEndDateForMappedUser = (maximumStartDate, date, projectRole) => {
        const dateToBeChanged = new moment(date);
        const todaysDate = new moment(Day.getTodaysDate());
        const projectRoleUsers = projectRole.get('project_role_users');
        if (dateToBeChanged < todaysDate) {
            this.notifyError(ERROR_CODES.BACK_DATED_ROLE);
        } else if (dateToBeChanged >= maximumStartDate) {
            projectRoleUsers.collection.map((projectRoleUser) => {
                projectRoleUser.set('end_date', date);
            });
            projectRole.set('project_role_users', projectRoleUsers);
            return true;
        } else {
            this.notifyError(ERROR_CODES.USER_WILL_LIE_OUTSIDE);
        }
        return false;
    }

    getMaximumStartDate = (projectRoleUsers) => {
        let maximumStartDate = new moment(projectRoleUsers.collection[0].get('start_date'));
        projectRoleUsers.collection.map((projectRoleUser) => {
            const startDate = new moment(projectRoleUser.get('start_date'));
            if (startDate >= maximumStartDate) {
                maximumStartDate = startDate;
            }
        });
        return maximumStartDate;
    }

    handleCapacityChange = (event) => {
        const { projectRole, updateProject, project } = this.props;
        projectRole.set('capacity', event.target.value);
        updateProject(project);
    }

    render() {
        const { projectRole, project } = this.props;
        return (
            <Col md={11}>
                <Row className="mapping-table-data">
                    <Col md={2} className="padding-left-0">
                        <DateSelector
                            onDateSelected={this.handleRoleStartDateChange}
                            min={project.get('actual_start_date')
                                ? project.get('actual_start_date') : project.get('tentative_start_date')}
                            max={project.get('actual_end_date')
                                ? project.get('actual_end_date') : project.get('tentative_end_date')}
                            startDate={projectRole.get('role_start_date')}
                            data-test="role-start-date"

                        />
                    </Col>
                    <Col md={2} className="padding-left-0">
                        <DateSelector
                            onDateSelected={this.handleRoleEndDateChange}
                            min={project.get('actual_start_date')
                                ? project.get('actual_start_date') : project.get('tentative_start_date')}
                            max={project.get('actual_end_date')
                                ? project.get('actual_end_date') : project.get('tentative_end_date')}
                            startDate={projectRole.get('role_end_date')}
                            data-test="role-end-date"
                        />
                    </Col>
                    <Col md={2}>
                        <input
                            type="range"
                            min="0"
                            step="10"
                            max="100"
                            className="slider custom-slider"
                            value={projectRole.get('capacity') || 100}
                            onChange={this.handleCapacityChange}
                            data-test="capacity-slider"
                        />
                    </Col>
                    <Col md={2}>
                        {projectRole.get('capacity') || 100}
                        {' '}
%
                    </Col>
                    <Col md={1}>
                        <button
                            title="Delete role"
                            type="button"
                            className="btn"
                            onClick={() => this.tryDeleteProjectRole(projectRole)}
                            data-test="delete-project-role"
                        >
                            <X />
                        </button>
                    </Col>
                </Row>
            </Col>
        );
    }
}
