import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import _ from 'underscore';
import UserProjectsGrid from './userProjectsGrid';
import Projects from "../../../models/collections/Projects";

export default class ViewProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allowAccess: false,
            projects: new Projects([])
        };
        _.bindAll(this, 'getCurrentUserProjectsSucessCallback', 'getCurrentUserProjectErrorCallback');
    }

    componentWillMount() {
        const { projects } = this.state;
        projects.getCurrentUserProjects(this.getCurrentUserProjectsSucessCallback);
    }

    getCurrentUserProjectErrorCallback(error) {
        console.error("error: ", error);
    }

    getCurrentUserProjectsSucessCallback(response) {
        const projects = new Projects(response);
        this.setState({ projects, allowAccess: true });
    }

    render() {
        const { allowAccess, projects } = this.state;
        if (!allowAccess) {
            return (<div />);
        }

        const headings = [
            { text: 'Name', column_name: 'projectName' },
            { text: 'Customer', column_name: 'customerName' },
            { text: 'Role', column_name: 'roleAssignedTo' },
            { text: 'Skill worked', column_name: 'skillWorkedOn' },
            { text: 'Mapped', column_name: 'mappedPercentage' },
            { text: 'From', column_name: 'assignedFrom' },
            { text: 'To', column_name: 'assignedTo' },
            { text: 'Shadow', column_name: 'shadow' },
            { text: 'Project skills', column_name: 'projectSkills' }
        ];

        return (
            <div>
                <Row>
                    <Col xs={12} md={12}>
                        <div><span className="heading-1">My Projects</span></div>
                    </Col>
                </Row>
                <div className="margin-top-3">
                    <UserProjectsGrid
                        heading={headings}
                        dataToPrint={projects}
                        history={this.props.history}
                        lastPageUrl={this.props.history.location.pathname}
                    />
                </div>
            </div>
        );
    }
}
