import React from 'react';
import Select from 'react-select';
import {
    Row, Col, FormGroup
} from 'react-bootstrap';
import AppComponent from "../../AppComponent";
import ProjectRoleGroupRow from "./ProjectRoleGroupRow";
import Skills from "../../../models/collections/Skills";
import BaseCollection from "../../../models/collections/BaseCollection";
import Label from "../../common/Label";
import { TOOlTIPS } from "../../../utils/constants";
import TooltipWithImage from "../../common/Tooltip";
import ProjectRoles from "../../../models/collections/ProjectRoles";

const INFO_ICON = "/info-icon.png";

export default class ProjectRoleDetails extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            allSkills: new Skills([]),
            projectRoleGroups: []
        };
    }

    componentDidMount() {
        this.fetchSkills();
    }

    fetchSkills = () => {
        const skills = new Skills([]);
        const _this = this;
        skills.getSkills((response) => {
            const allSkills = new Skills(response);
            _this.setState({ allSkills });
            _this.convertAllSkillsToOptions(allSkills);
        });
    };

    convertAllSkillsToOptions = (skills) => {
        let options = [];
        if (skills !== null && skills instanceof BaseCollection) {
            options = skills.map(skill => ({
                key: skill.get('skill_type'),
                text: skill.get('skill_type'),
                value: skill.get('id')
            }));
        }
        this.setState({ options });
    };

    convertRolesToOptions = () => {
        const { roles } = this.props;
        const options = roles.map(role => ({ label: role.get('name'), value: role.get('id') }));
        return options;
    };

    handleRoleChange = ({ value }) => {
        const { projectRoleGroups } = this.state;
        const updatedProjectRoles = new ProjectRoles([]);
        updatedProjectRoles.addProjectRole(value);
        projectRoleGroups.push({ numberOfProjectRoles: 1, projectRoles: updatedProjectRoles });
        this.setState({ currentRole: "", projectRoleGroups });
    };

    deleteProjectRoleGroup = (index) => {
        const { projectRoleGroups } = this.state;
        projectRoleGroups.splice(index, 1);
        this.setState({ projectRoleGroups });
    };

    updateProjectRoleGroups = () => {
        const { projectRoleGroups } = this.state;
        this.setState({ projectRoleGroups });
    };

    renderProjectRoles() {
        const { project } = this.props;
        const { options, allSkills, projectRoleGroups } = this.state;
        const rows = projectRoleGroups.map((projectRoleGroup, index) => {
            const projectRoles = projectRoleGroup.projectRoles;
            if (!projectRoles.getSize()) {
                return "";
            }
            return (
                <ProjectRoleGroupRow
                    key={`${projectRoles.get(0).get('role_id')} ${index}`}
                    roleId={projectRoles.get(0).get('role_id')}
                    projectRoleGroup={projectRoleGroup}
                    project={project}
                    skillsOptions={options}
                    allSkills={allSkills}
                    fetchSkills={this.fetchSkills}
                    deleteProjectRoleGroup={this.deleteProjectRoleGroup}
                    updateProjectRoleGroups={this.updateProjectRoleGroups}
                    index={index}
                />
            );
        });
        return rows;
    }

    renderProjectRoleHeaders = () => {
        const { projectRoles } = this.props;
        if (projectRoles.getSize()) {
            return (
                <Row>
                    <Col md={2} />
                    <Col md={1}><Label data-test="serialNumber">No.</Label></Col>
                    <Col md={3}><Label data-test="skill" required>Required Skill</Label></Col>
                    <Col md={2}><Label data-test="startDate">Start Date</Label></Col>
                    <Col md={2}><Label data-test="endDate">End Date</Label></Col>
                    <Col md={2}><Label data-test="capacity">Capacity</Label></Col>
                </Row>
            );
        }
    }

    render() {
        const {
            currentRole
        } = this.state;
        return (
            <React.Fragment>
                <Row className="section">
                    <Col md={3}>
                        <FormGroup>
                            <Label displayInline>
                                Roles&nbsp;
                            </Label>
                            <TooltipWithImage src={INFO_ICON} tooltipText={TOOlTIPS.ROLES} />
                            <Select
                                options={this.convertRolesToOptions()}
                                onChange={this.handleRoleChange}
                                value={currentRole}
                                placeholder="Select new role"
                                data-test="roles"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <div>
                    {this.renderProjectRoleHeaders()}
                </div>
                <div>
                    {this.renderProjectRoles()}
                </div>
            </React.Fragment>
        );
    }
}
