import _ from 'underscore';
import moment from 'moment';
import { MAX_OF_PROJECT_ROLES_PER_ROLE } from "./constants";

export function replace(regex, value) {
    return value.replace(regex, "");
}

export function getMonthsOptions() {
    return _.range(0, 12).map(element => ({ value: element, label: element }));
}

export function getYearsOptions() {
    return _.range(0, 25).map(element => ({ value: element, label: element }));
}

export function numberOfRoles() {
    return _.range(1, MAX_OF_PROJECT_ROLES_PER_ROLE + 1).map(element => ({ value: element, label: element }));
}

export function getExpertiseLevel() {
    return ['Beginner', 'Intermediate', 'Advance'];
}

export const isEmptyString = string => string === "";

// TODO:  Move it to project model class
export function getValueOrDefault(value, defaultValue) {
    if (value) {
        return value;
    }
    return defaultValue;
}

export function formatDate(date) {
    return moment(moment(date, "DD/MM/YYYY")).format('YYYY/MM/DD');
}

export function getDateRanges() {
    return {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month')]
    };
}

export function getFutureDateRanges() {
    return {
        Today: [moment(), moment()],
        Tomorrow: [moment().add(1, 'days'), moment().add(1, 'days')],
        'Next 7 Days': [moment(), moment().add(6, 'days')],
        'Next 30 Days': [moment(), moment().add(29, 'days')],
        'Next Month': [moment().add(1, 'month').startOf('month'),
            moment().add(1, 'month').endOf('month')]
    };
}

export function isNumberNegative(number) {
    return Math.sign(number) === -1;
}

export function convertToPositive(number) {
    return Math.abs(number);
}
