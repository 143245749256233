import React from 'react';
import { Col, Row } from "react-bootstrap";
import $ from 'jquery';
import _ from 'underscore';
import AppComponent from "../AppComponent";
import { CAROUSAL_PARTITION_INDEX, NO_RECORDS_FOUND } from "../../utils/constants";
import HealthStatusPieChart from "./HealthStatusPieChart";
import ControlledCarousel from "./Carousel";
import Projects from "../../models/collections/Projects";
import Users from "../../models/collections/Users";
import UserStatistics from "../../models/UserStatistics";
import Day from "../../utils/day";
import OpenRoles from "./OpenRoles";
import FirstTimeUser from "../Slider/FirstTimeUser";

export default class DashBoard extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            project: new Projects([]),
            unassignedUsers: new Users([]),
            startDate: Day.getTodaysDate(),
            endDate: Day.getTodaysDate(),
            customersMap: new Map(),
            users: new Users([]),
            slides: [],
            asOnDate: Day.getYesterdaysDate(),
            userStatistics: new UserStatistics(),
            firstTimeUser: true,
            openRoles: undefined
        };
        _.bindAll(this, 'getActiveProjectsSuccessCallback', 'populateData', 'handleDateChange');
    }

    handleDateChange(date) {
        this.setState({
            asOnDate: date
        });
        const { asOnDate: params } = this.state;
        this.getUsersStatistics(params);
    }

    handleDateRangeChange = (startDate, endDate) => {
        this.setState({ startDate, endDate });
        this.getOpenRoles({ startDate, endDate });
    }

    getUsersStatistics = (params) => {
        const { userStatistics } = this.state;
        userStatistics.getUsersStatistics({ asOnDate: params },
            (response) => {
                const updatedUserStatistics = new UserStatistics(response);
                this.setState({ userStatistics: updatedUserStatistics });
            });
    };

    getOpenRoles = (params) => {
        const { userStatistics } = this.state;
        userStatistics.getOpenRoles({ startDate: params.startDate, endDate: params.endDate },
            (response) => {
                this.setState({ openRoles: response });
            });
    };

    getActiveProjectsSuccessCallback=(response) => {
        const project = new Projects(response);
        const slides = project.convertArrayTo2DArray(CAROUSAL_PARTITION_INDEX);
        this.setState({ project, slides });
    }

    componentWillMount() {
        this.setState({ firstTimeUser: window.loggedInUser.first_time_user });
        this.populateData();
    }

    populateData() {
        const { project } = this.state;
        const apiParams = $.param({ date: new Date().toDateString() });
        project.getActiveProjects(apiParams,
            this.getActiveProjectsSuccessCallback);
        const { asOnDate: params, startDate, endDate } = this.state;
        this.getUsersStatistics(params);
        this.getOpenRoles({ startDate, endDate });
    }

    renderActiveProjects(containsActiveProjects) {
        let caraousalPlaceholder = <React.Fragment></React.Fragment>;
        let message = NO_RECORDS_FOUND("Active Projects");

        if (containsActiveProjects) {
            caraousalPlaceholder = (
                <div className="carousel-container margin-top-5">
                    <ControlledCarousel
                        slides={this.state.slides}
                    />
                </div>
            );
            message = 'Active Projects';
        }

        return (
            <React.Fragment>
                <Row className="divider margin-top-3">
                    <Col className="margin-top-2" xs={12} md={12}>
                        <span className="heading-2">{message}</span>
                    </Col>
                </Row>
                {caraousalPlaceholder}
            </React.Fragment>
        );
    }

    closeOnBoardingSlider=() => {
        this.setState({ firstTimeUser: false });
        window.loggedInUser.first_time_user = false;
    }

    render() {
        const {
            project, userStatistics, asOnDate, firstTimeUser
        } = this.state;
        return (
            <div>
                <FirstTimeUser open={firstTimeUser} closeOnBoardingSlider={this.closeOnBoardingSlider} />
                <div className="list-page col-md-12">
                    <Row>
                        <Col xs={6} md={6} className="overflow-hidden">
                            <HealthStatusPieChart
                                userStatistics={userStatistics}
                                asOnDate={asOnDate}
                                handleDateChange={this.handleDateChange}
                            />
                        </Col>
                        <Col xs={6} md={6}>
                            <OpenRoles
                                handleDateRangeChange={this.handleDateRangeChange}
                                openRoles={this.state.openRoles}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                lastPageUrl={this.props.location.pathname}
                                history={this.props.history}
                            />
                        </Col>
                    </Row>
                    {this.renderActiveProjects(project.getSize())}
                </div>
            </div>
        );
    }
}
