import HttpStatus from 'http-status-codes';
import axios from 'axios';
import BaseModel from "./BaseModel";
import { ERROR_CODES } from '../utils/constants';
import { getApiUrl } from "../utils/serviceUrls";

export default class Customer extends BaseModel {
    getRequestPayload() {
        return {
            customer: this.model
        };
    }

    isMandatoryField(field) {
        return field !== 'notes';
    }

    isMandatoryFieldEmpty(field) {
        return (this.model[field] === ''
            || (Object.keys(this.model[field]).length === 0 && this.model[field].constructor === Object)
        );
    }

    validate() {
        let isValid = true;
        const inputFieldColor = {
            name: '', contact_person: '', email: '', sales_executive: {}
        };
        let errorMessage = "";
        for (const field in this.model) {
            if (this.isMandatoryField(field) && this.isMandatoryFieldEmpty(field)) {
                isValid = false;
                inputFieldColor[field] = "field-with-error";
            }
        }

        if (!isValid) {
            errorMessage = ERROR_CODES.MANDATORY_FIELDS;
            return { errorMessage, inputFieldColor, isValid };
        }

        isValid = this.validateEmail();
        if (!isValid) {
            errorMessage = ERROR_CODES.INVALID_EMAIL;
            inputFieldColor.email = "field-with-error";
        }
        return { errorMessage, inputFieldColor, isValid };
    }

    validateEmail() {
        const EMAIL_REGEX = /[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+/i;
        return EMAIL_REGEX.test(this.model.email);
    }

    getCustomer(id, successCallback) {
        const apiUrl = getApiUrl("GET_CUSTOMER", { id });
        axios.get(apiUrl, { headers: super.getAuthHeaders() })
            .then((response) => {
                this.model = new Customer(response.data).model;
                successCallback(response.data);
            });
    }

    create(successCallback, errorCallback) {
        axios.post(getApiUrl("GET_CUSTOMERS"), { customer: this.model }, { headers: this.getAuthHeaders() })
            .then((response) => {
                if (response.status !== HttpStatus.CREATED) {
                    console.log(`Looks like there was a problem. Status Code: ${response.status}`);
                    return;
                }
                successCallback();
            })
            .catch(error => errorCallback(error));
    }

    update(successCallback, errorCallback) {
        axios.put(getApiUrl("GET_CUSTOMER", { id: this.model.id }), { customer: this.model })
            .then((response) => {
                if (response.status !== HttpStatus.NO_CONTENT) {
                    console.log(`Looks like there was a problem. Status Code: ${response.status}`);
                    return;
                }
                successCallback();
            })
            .catch(error => errorCallback(error));
    }
}
