import React, { Component } from 'react';
import Moment from 'react-moment';
import PrintUsersWorkedOnThisProject from './printUsersWorkedOnThisProject';
import { DATE_FORMAT } from "../../../utils/constants";

export default class DisplayBasicDetails extends Component {
    handleChangeInSelect(key, event) {
        this.setState({ [key]: event.target.value });
    }

    render() {
        const { projectDetails, rolesName, allUsers } = this.props;
        if (projectDetails) {
            return (
                <div>
                    <div className='margin-top-2 margin-left-16'>
                        <table className='width-80 actions'>
                            <tbody>
                                <tr>
                                    <td>
                                    Customer Name:
                                    </td>
                                    <td className='upper-case-text'>
                                        {projectDetails.customer.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    Actual Start Date:
                                    </td>
                                    <td>
                                        {projectDetails.actual_start_date ? (
                                            <Moment
                                                format={DATE_FORMAT}
                                            >
                                                {projectDetails.actual_start_date}
                                            </Moment>
                                        ) : "N/A"}
                                    </td>
                                    <td>
                                    Tentative Start Date:
                                    </td>
                                    <td>
                                        {projectDetails.tentative_start_date ? (
                                            <Moment
                                                format={DATE_FORMAT}
                                            >
                                                {projectDetails.tentative_start_date}
                                            </Moment>
                                        ) : "N/A"}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    Actual End Date:
                                    </td>
                                    <td>
                                        {projectDetails.actual_end_date ? (
                                            <Moment
                                                format={DATE_FORMAT}
                                            >
                                                {projectDetails.actual_end_date}
                                            </Moment>
                                        ) : "N/A"}
                                    </td>
                                    <td className='align-left'>
                                    Tentative End Date:
                                    </td>
                                    <td>
                                        {projectDetails.tentative_end_date ? (
                                            <Moment
                                                format={DATE_FORMAT}
                                            >
                                                {projectDetails.tentative_end_date}
                                            </Moment>
                                        ) : "N/A"}
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                    <div className='width-80 margin-top-2 container'>
                        <PrintUsersWorkedOnThisProject
                            projectDetails={projectDetails}
                            selectedDateDetails={this.props}
                            rolesName={rolesName}
                            allUsers={allUsers}
                        />
                    </div>
                </div>
            );
        }
        return (<div />);
    }
}
