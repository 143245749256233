import React from 'react';
import { Col, Row } from "react-bootstrap";
import _ from 'underscore';
import ShowProject from "../project/list/showProject";
import { getApiUrl } from "../../utils/serviceUrls";
import { SUCCESS_CODES } from "../../utils/constants";
import Customer from "../../models/Customer";
import SalesExecutive from "./salesExecutive";
import AppComponent from "../AppComponent";

const toastr = require("toastr");

export default class ViewCustomer extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            customer: new Customer({
                name: '', contact_person: '', email: '', sales_executive: {}, notes: ""
            }),
            allowAccess: false,
            notice: " "
        };
        _.bindAll(this,
            'changeState',
            'handleUpdate',
            'getCustomerSuccessCallBack',
            'updateCustomerSuccessCallback',
            'updateCustomerErrorCallback',
            'handleChangeInParentComponent');
    }

    componentWillMount() {
        const { customer } = this.state;
        const { match: { params: { id } } } = this.props;
        const { location: { state: { notice } = { notice: "" } } } = this.props;
        customer.getCustomer(id, this.getCustomerSuccessCallBack);
        if (this.props.location.statxe !== undefined) {
            this.setState({ notice });
        }
    }

    getCustomerSuccessCallBack(response) {
        const customer = new Customer(response);
        this.setState({ customer, allowAccess: true });
    }

    updateCustomerSuccessCallback() {
        toastr.success(SUCCESS_CODES.CUSTOMER_UPDATE_MESSAGE);
        this.props.history.push('/customers');
    }

    updateCustomerErrorCallback(error) {
        console.error("error: ", error);
    }

    handleChangeInParentComponent(key, value) {
        const { customer } = this.state;
        customer.set('sales_executive', value);
        this.setState({ customer, errorMessage: '' });
    }

    handleUpdate() {
        const { customer } = this.state;
        const { isValid, inputFieldColor, errorMessage } = customer.validate();
        if (!isValid) {
            this.setState({
                inputFieldColor
            });
            this.setState({
                notice: errorMessage
            });
            toastr.error(errorMessage);
            return;
        }
        customer.setPath(getApiUrl("GET_CUSTOMER", { id: customer.get('id') }));
        customer.update(this.updateCustomerSuccessCallback, this.updateCustomerErrorCallback);
    }

    changeState(event) {
        const { customer } = this.state;
        const key = event.target.name;

        if (key === 'name') {
            customer.set(key, event.target.value.replace(/[^a-zA-Z0-9\-\&\.\,\*\' ]/g, ""));
        } else if (key === "contact_person") {
            customer.set(key, event.target.value.replace(/[^a-zA-Z ]/g, ""));
        } else if (key === "email") {
            customer.set(key, event.target.value.replace(/[^[\w.%+-]+@[\w.-]+\.[a-zA-Z]{2,6}]/g, ""));
        } else {
            customer.set(key, event.target.value);
        }

        this.setState({ customer });
    }

    redirectToPreviousPage = () => {
        this.navigateTo(this.props.location.state.lastPageUrl, this.props.location.state);
    };

    render() {
        const {
            customer, allowAccess, notice, inputFieldColor
        } = this.state;
        const { location, history } = this.props;
        if (!allowAccess) return (<div />);

        return (
            <div>
                <div className="col-md-11">
                    <center>
                        <p>
                            <span className='red-color'>
                                {' '}
                                {notice}
                                {' '}
                            </span>
                        </p>
                    </center>
                    <Row className="show-grid">
                        <Col xs={10} md={11}>
                            <div><span className="heading-1">Update Customer</span></div>
                        </Col>
                    </Row>
                    <div className="margin-top-3">
                        <Row className="show-grid">
                            <Col xs={6} md={4}>
                                <div><label className="labels">Customer Name:</label></div>
                                <div>
                                    <input
                                        value={customer.get('name')}
                                        name="name"
                                        onChange={event => this.changeState(event)}
                                        className={`customer-name-update
                                            ${!customer.get('name') ? "field-with-error" : ""}`}
                                    />
                                </div>
                            </Col>
                            <Col xs={0} md={2} />
                            <Col xs={6} md={4}>
                                <div><label className="labels">Contact Person:</label></div>
                                <div>
                                    <input
                                        value={customer.get('contact_person')}
                                        name="contact_person"
                                        onChange={event => this.changeState(event)}
                                        className={`contact-person-update
                                            ${!customer.get('contact_person') ? "field-with-error" : ""}`}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row className="margin-top-1 show-grid">
                            <Col xs={6} md={4}>
                                <div><label className="labels">Contact Person Email:</label></div>
                                <div>
                                    <input
                                        value={customer.get('email')}
                                        name="email"
                                        onChange={event => this.changeState(event)}
                                        className={`customer-email-update
                                            ${!customer.get('email') ? "field-with-error" : ""}`}
                                    />
                                </div>
                            </Col>
                            <Col xs={0} md={2} />
                            <Col xs={6} md={4}>
                                <div className="col" />
                                <div>
                                    <SalesExecutive
                                        sales_executive={customer.get('sales_executive')}
                                        handleChangeInParentComponent={this.handleChangeInParentComponent}
                                        inputFieldColor={inputFieldColor}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="margin-top-1 show-grid">
                            <Col xs={6} md={6}>
                                <div><label className="labels">Notes</label></div>
                                <div>
                                    <textarea
                                        className="customer-notes-update"
                                        name="notes"
                                        value={customer.get('notes')}
                                        onChange={event => this.changeState(event)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="margin-top-3 text-align-right">
                            <Col md={8} />
                            <Col md={1}>
                                <button
                                    type="button"
                                    onClick={this.redirectToPreviousPage}
                                    className="btn btn-secondary back-button"
                                >
                                    Cancel
                                </button>
                            </Col>
                            <Col md={1}>
                                <button type="button" onClick={this.handleUpdate} className="btn btn-primary ">
                                    Update
                                </button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <br />
                <div className="divider">
                    <ShowProject
                        history={history}
                        onClick={this.handleClick}
                        location={location}
                        {...this.state}
                    />
                </div>
            </div>
        );
    }
}
