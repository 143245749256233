import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import VisaEditDialog from "./VisaEditDialog";
import Box from "@material-ui/core/Box";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#EDEDED",
    color: theme.palette.common.black,
    fontSize: 16,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 300,
  },
});

export default function VisaTable(props) {
  const classes = useStyles();
  const visaDetails = props.visaDetails;
  const handleDeleteVisa = props.handleDeleteVisa;
  const handleEditVisa = props.handleEditVisa;
  const countries = props.countries;

  return (
    <TableContainer component={Paper} className={classes.container}>
      {visaDetails.length > 0 && (
        <Table stickyHeader aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Country</StyledTableCell>
              <StyledTableCell align="center">Visa Type</StyledTableCell>
              <StyledTableCell align="center">Visa Validity</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visaDetails.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell align="center">{row.country}</StyledTableCell>
                <StyledTableCell align="center">
                  {row.type_of_visa}
                </StyledTableCell>
                <StyledTableCell align="center">{row.validity}</StyledTableCell>
                <StyledTableCell align="center">
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <Box px={1}>
                      <VisaEditDialog
                        country={row.country}
                        visa={row}
                        rowEditVisa={handleEditVisa}
                        countries={countries}
                      />
                    </Box>

                    <Box>
                      <DeleteForeverIcon
                        onClick={(event) =>
                          handleDeleteVisa(
                            `${row.country}-${row.type_of_visa}-${row.validity}`,
                            event
                          )
                        }
                      />
                    </Box>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
}
