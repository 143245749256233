import React, { Component } from 'react';
import { replace } from "../../utils/helperFunctions";
import { SPECIAL_CHAR_REMOVAL_REGEX } from "../../utils/constants";

export default class CustomerEmail extends Component {
    constructor(props) {
        super(props);
        this.handleChangeOfName = this.handleChangeOfName.bind(this);
    }

    handleChangeOfName(event) {
        const { handleChangeInParentComponent } = this.props;
        handleChangeInParentComponent('email', replace(SPECIAL_CHAR_REMOVAL_REGEX.EMAIL, event.target.value));
    }

    render() {
        const { inputFieldColor: { email: emailFieldColor }, email } = this.props;
        return (
            <div>
                <div>
                    <label className="labels">
Customer Email:
                        <span><i className='field-indicator-color'>*</i></span>
                    </label>
                </div>
                <div>
                    <input
                        type="email"
                        name="customer-email"
                        id="customer-email"
                        className={`${emailFieldColor} customer-email`}
                        value={email}
                        onChange={this.handleChangeOfName}
                    />
                </div>
            </div>
        );
    }
}
