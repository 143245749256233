import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import $ from 'jquery';
import Routes from "../staffing/routes";
import { getApiUrl } from "../staffing/utils/serviceUrls";
import 'semantic-ui-css/semantic.min.css';
import 'react-select-plus/dist/react-select-plus.css';
import 'styles/application.scss'; // eslint-disable-line import/no-unresolved
import HttpClient from "./http-client";

$(document).ready(() => {
    HttpClient.setupInterceptors();

    window.STAFFING_APP_STORAGE = window.localStorage;
    window.userInit = (user) => {
        window.loggedInUser = user;
    };

    window.storeToken = token => STAFFING_APP_STORAGE.setItem('token', token);

    window.clearStorage = () => STAFFING_APP_STORAGE.clear();

    window.getMe = () => {
        $.ajax({
            url: getApiUrl("AUTHENTICATE_USER"),
            type: 'POST',
            headers: { Authorization: window.getJwtToken() },
            success(response) {
                window.loggedInUser = response;
                if (window.loggedInUser) {
                    Object.defineProperty(window.loggedInUser, 'auth_token', {
                        writable: false
                    });
                    Object.defineProperty(window.loggedInUser, 'username', {
                        writable: false
                    });
                    Object.defineProperty(window.loggedInUser, 'role', {
                        writable: false
                    });
                    Object.defineProperty(window.loggedInUser, 'id', {
                        writable: false
                    });
                }
                if (!window.loggedInUser) {
                    window.clearStorage();
                }
                ReactDOM.render(
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>,
                    document.getElementById('staffing-app')
                );
            }
        });
    };
    window.getJwtToken = () => STAFFING_APP_STORAGE.getItem('token');
    window.getMe();
});

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

window.AppStorage = window.localStorage;

window.userInit = (user) => {
    window.loggedInUser = user;
};

window.storeToken = (token) => {
    AppStorage.setItem('token', token);
};

window.clearStorage = () => {
    AppStorage.clear();
};


window.getJwtToken = () => { AppStorage.getItem('token'); };
