import React from "react";
import { Row, Col } from "react-bootstrap";
import Select from 'react-select';
import AppComponent from "../../AppComponent";
import DateRangeSelector from "../../common/DateRangeSelector";
import UserStatistics from "../../../models/UserStatistics";
import Roles from "../../../models/collections/Roles";
import OpenRoleTable from "./openRoleTable";

export default class OpenRoles extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            startDate: "",
            endDate: "",
            openRoles: new UserStatistics(),
            options: [],
            selectedRoles: [],
            roles: new Roles([]),
            showTable: false
        };
    }

    componentWillMount() {
        this.fetchRoles();
        if (this.state.startDate) {
            return;
        }
        if (this.props.location.state.roleName) {
            const {
                startDate, endDate, roleName, roleId
            } = this.props.location.state;
            this.setState(
                {
                    startDate,
                    endDate,
                    selectedRoles: [{ label: roleName, value: roleId }]
                }
            );
            const option = [roleName];
            this.fetchOpenRoles(startDate, endDate, option);
        }
    }

    fetchRoles = () => {
        const { roles } = this.state;
        roles.getRoles(this.getRolesSuccessCallback);
    }

  getRolesSuccessCallback = (response) => {
      const roles = new Roles(response);
      this.setState({ roles });
      this.convertResponseToOptions(roles);
  }

  convertResponseToOptions = (roles) => {
      const options = roles.map(role => ({ label: role.get('name'), value: role.get('id') }));
      this.setState({ options });
  }

  setDate = (startDate, endDate) => {
      this.setState({ startDate, endDate });
  }

  handleSearchClick = () => {
      const {
          startDate, endDate, selectedRoles
      } = this.state;
      if (!startDate && !endDate) {
          this.setState({ showTable: false });
          return;
      }
      const option = selectedRoles.length ? this.convertRolesToParams(selectedRoles) : "with_details";
      this.fetchOpenRoles(startDate, endDate, option);
  }

  fetchOpenRoles = (startDate, endDate, option) => {
      const { openRoles } = this.state;
      openRoles.getOpenRoles({ startDate, endDate, option },
          (response) => {
              const updatedUserStatistics = new UserStatistics(response);
              this.setState({
                  openRoles: updatedUserStatistics,
                  showTable: true
              });
          });
  }

  convertRolesToParams(roles) {
      const params = roles.map(role => role.label);
      return params;
  }

  clearDates = () => {
      const startDate = "";
      const endDate = "";
      this.setState({ startDate, endDate });
  }

  handleRoleChange = (event) => {
      this.setState({
          selectedRoles: event
      });
  }

  countOpenRoles = () => {
      const { openRoles } = this.state;
      let count = 0;
      openRoles.model.forEach((openRole) => {
          count += openRole.project_role_id.length;
      });
      return count;
  }

  render() {
      const {
          startDate, endDate, options, selectedRoles, openRoles, showTable
      } = this.state;
      return (
          <>
              <Row className="show-grid">
                  <Col md={6}>
                      <span className="heading-1">Open Roles</span>
                  </Col>
              </Row>
              <Row className="margin-top-2 text-medium font-weight-bold">
                  <Col md={5}>
                      <Row>
                          <Col md={3} className="padding-top-2">
                      Select role:
                          </Col>
                          <Col md={8} className="select-actions">
                              <Select
                                  multi
                                  options={options}
                                  onChange={this.handleRoleChange}
                                  value={selectedRoles}
                                  classNamePrefix="select"
                                  autosize={false}
                              />
                          </Col>
                      </Row>
                  </Col>
                  <Col md={4}>
                      <Row>
                          <Col md={4} className="padding-top-2">
                      Select date:
                          </Col>
                          <Col md={8}>
                              <DateRangeSelector
                                  onRangeSelected={this.setDate}
                                  onClearDates={this.clearDates}
                                  min
                                  startDate={startDate}
                                  endDate={endDate}
                              />
                          </Col>
                      </Row>
                  </Col>
                  <Col md={2} className="margin-left-2">
                      <button
                          type="button"
                          className="btn btn-primary text-small btn-padding"
                          onClick={this.handleSearchClick}
                      >
                    Search
                      </button>
                  </Col>
              </Row>
              {showTable ? (
                  <>
                      <Row className="margin-top-2">
                          <Col md={12} className="text-align-right font-weight-bold text-medium padding-right-5">
                    Count:
                              <span>
                                  {' '}
                                  {this.countOpenRoles()}
                              </span>
                          </Col>
                      </Row>
                      <OpenRoleTable
                          data={openRoles}
                          lastPageUrl={this.props.location.pathname}
                          history={this.props.history}
                      />
                  </>
              ) : (
                  <span className="availability-message">
                    Select Role and a date range to find open roles.
                  </span>
              )}

          </>
      );
  }
}
