import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import _ from 'underscore';
import Customers from "../../../models/collections/Customers";
import AppComponent from "../../AppComponent";

export default class CustomerList extends AppComponent {
    constructor(props) {
        super(props);
        this.noCustomerFound = "No Customer Found!";
        this.state = {
            customers: new Customers([])
        };
        _.bindAll(this,
            'isObjectEmpty',
            'redirectToCreateCustomer',
            'redirectToCreateProject',
            'navigateToShowCustomerView');
    }

    componentWillMount() {
        const { customers } = this.state;
        customers.getAll((customers) => {
            this.setState({ customers });
        });
    }

    getHeader() {
        return (
            <tr key="customer-list-headings" className="table-header">
                <th>Customer Name</th>
                <th>Contact Person</th>
                <th>Email Id</th>
                <th>Sales Executive</th>
                <th>Add Project</th>
            </tr>
        );
    }

    isObjectEmpty(hashObject) {
        for (const key in hashObject) return false;
        return true;
    }

    navigateToShowCustomerView = (event, key) => {
        if (loggedInUser.role === "admin") {
            return null;
        }
        const { customers } = this.state;
        this.navigateTo(`/customers/${customers.get(key)
            .get('id')}`, { lastPageUrl: "/customers", customer_id: customers.get(key).get('id') });
    }

    redirectToCreateCustomer() {
        const { history } = this.props;
        history.push("/customers/create", { lastPageUrl: "/customers" });
    }

    redirectToCreateProject = (event, key) => {
        const { customers } = this.state;
        event.stopPropagation();
        this.navigateTo("/projects/create", { lastPageUrl: "/customers", customer_id: customers.get(key).get('id') });
    }

    getClassName = () => (loggedInUser.role === "admin" ? "table-data" : " table-data link-pointer")

    convertCustomerJsonToHTml() {
        const { customers: customerList } = this.state;
        const customerListArray = [];
        const length = customerList.getSize();
        for (let key = 0; key < length; key += 1) {
            const customer = customerList.get(key);
            customerListArray.push(
                <tr
                    key={key}
                    className={this.getClassName()}
                    onClick={event => this.navigateToShowCustomerView(event, key)}
                >
                    <td>{customer.get('name')}</td>
                    <td>{customer.get('contact_person')}</td>
                    <td>{customer.get('email')}</td>
                    <td>{customer.get('sales_executive')}</td>
                    <td className="table-data actions text-align-center">
                        <button
                            type="button"
                            title="Add project"
                            className="btn margin-left-5"
                            id={customer.get('id')}
                            onClick={event => this.redirectToCreateProject(event, key)}
                        >
                            <span>
                                <img src="add-icon.svg" className="height-20" alt="add icon" />
                            </span>
                        </button>
                    </td>
                </tr>
            );
        }
        return customerListArray;
    }

    render() {
        let customerHeader = [];
        let customerList = [];
        let notice = "";
        const { customers } = this.state;
        if (!this.isObjectEmpty(customers)) {
            customerHeader = this.getHeader();
            customerList = this.convertCustomerJsonToHTml();
        } else {
            notice = this.noCustomerFound;
        }
        const { location: { state } } = this.props;
        if (state !== undefined) {
            if (state.notice) notice = state.notice;
        }
        return (
            <div>
                <center>
                    <p>
                        <span className="green-color">
                            {notice}
                            {' '}
                        </span>
                    </p>
                </center>

                <Row className="show-grid">
                    <Col xs={6} md={6}>
                        <div><span className="heading-1">Customers</span></div>
                    </Col>
                    <Col xs={6} md={6}>
                        { window.loggedInUser.role === "admin" ? null
                            : (
                                <button
                                    type="button"
                                    className="btn btn-primary float-right text-small"
                                    onClick={event => this.redirectToCreateCustomer(event)}
                                >
                            Create Customer
                                </button>
                            )}
                    </Col>
                </Row>

                <center>
                    <div className="margin-top-3">
                        <Table bordered responsive>
                            <thead>
                                {customerHeader}
                            </thead>
                            <tbody>
                                {customerList}
                            </tbody>
                        </Table>
                    </div>
                </center>
            </div>
        );
    }
}
