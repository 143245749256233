import React from 'react';
import { Col, Row } from "react-bootstrap";
import AppComponent from "../AppComponent";
import Projects from "../../models/collections/Projects";
import Day from "../../utils/day";
import Project from "../../models/Project";
import Roles from "../../models/collections/Roles";
import DateRangeSelector from "../common/DateRangeSelector";
import UserProjectSummaryContainer from "./UserProjectSummaryContainer";
import NoUserProjectsFound from "./NoUserProjectsFound";
import AllProjects from "./AllProjects";
import ProjectVacancies from "./ProjectVacancies";
import FirstTimeUser from "../Slider/FirstTimeUser";


export default class UserDashboard extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            startDate: Day.getTodaysDate(),
            endDate: Day.getTodaysDate(),
            userProjects: new Projects([]),
            selectedProject: new Project({}),
            roles: new Roles([]),
            firstTimeUser: true
        };
    }

    handleUserStatisticsDateRangeChange = (startDate, endDate) => {
        this.setState({ startDate, endDate });
        this.getUserProjects();
    };

    componentDidMount() {
        this.setState({ firstTimeUser: window.loggedInUser.first_time_user });
        this.populateData();
    }

    populateData = () => {
        this.getRoles();
        this.getUserProjects();
    };

    getRoles = () => {
        const { roles } = this.state;
        roles.getRoles((response) => { this.setState({ roles: new Roles(response) }); });
    };

    getUserProjects = () => {
        const { userProjects, startDate, endDate } = this.state;
        userProjects.getUserProjectsInDateRange({ startDate, endDate }, this.callback);
    };

    callback = (response) => {
        const userProjects = new Projects(response);
        this.setState({ userProjects, selectedProject: new Project(response[0]) });
    };

    closeOnBoardingSlider=() => {
        this.setState({ firstTimeUser: false });
        window.loggedInUser.first_time_user = false;
    }

    render() {
        const {
            startDate, endDate, userProjects, selectedProject, firstTimeUser
        } = this.state;
        const { history, location } = this.props;
        return (
            <div>
                <FirstTimeUser open={firstTimeUser} nonAdminUser closeOnBoardingSlider={this.closeOnBoardingSlider} />
                <div className="user-dashboard">
                    <Row>
                        <Col xs={8} md={8}>
                            <div><span className="heading-2">My Projects</span></div>
                        </Col>
                        <Col xs={3} md={3}>
                            <DateRangeSelector
                                onRangeSelected={this.handleUserStatisticsDateRangeChange}
                                startDate={startDate}
                                endDate={endDate}
                                noClearOption
                                data-test="user-projects-date-range"
                            />
                        </Col>
                    </Row>
                    <Row className="margin-top-2">
                        {userProjects.getSize()

                            ? (
                                <UserProjectSummaryContainer
                                    userProjects={userProjects}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectedProject={selectedProject}
                                    roles={this.state.roles}
                                    history={history}
                                    location={location}
                                />
                            )
                            : (
                                <NoUserProjectsFound />
                            )
                        }
                    </Row>
                    <Row className="margin-top-3" />
                    <Row className="divider" />
                    <div>
                        <AllProjects history={this.props.history} lastPageUrl={this.props.location.pathname} />
                    </div>
                    <Row className="margin-top-2" />
                    <Row className="divider" />
                    <ProjectVacancies />
                </div>
            </div>
        );
    }
}
