import moment from "moment";
import BaseModel from "./BaseModel";
import ProjectRoleSkills from "./collections/ProjectRoleSkills";
import BaseCollection from "./collections/BaseCollection";
import ProjectRoleUsers from "./collections/ProjectRoleUsers";
import Day from "../utils/day";

export default class ProjectRole extends BaseModel {
    constructor(attributes) {
        super(attributes);
        if (this.model.project_role_skills) {
            this.model.project_role_skills = this.getProjectRoleSkills();
        }
        if (this.model.project_role_users) {
            this.model.project_role_users = this.getProjectRoleUsers();
        }
    }

    isValidStartDate(startDate) {
        return new moment(this.model.role_start_date) >= new moment(startDate)
            && new moment(this.model.role_start_date) <= new moment(this.model.role_end_date);
    }

    isValidEndDate(endDate) {
        return new moment(this.model.role_end_date) <= new moment(endDate);
    }

    canDelete =() => {
        const roleStartDate = new moment(this.get('role_start_date'));
        const todaysDate = new moment(Day.getTodaysDate());
        return roleStartDate > todaysDate;
    }

    canAddUser = () => {
        const todaysDate = new moment(Day.getTodaysDate());
        const roleEndDate = new moment(this.get("role_end_date"));
        return roleEndDate >= todaysDate;
    }

    get(key) {
        return super.get(key);
    }

    isModelAlreadyLoaded(key) {
        return this.model[key] instanceof BaseCollection;
    }

    getProjectRoleUsers() {
        if (this.model.project_role_users) {
            if (this.isModelAlreadyLoaded('project_role_users')) {
                return this.model.project_role_users;
            }

            return new ProjectRoleUsers(this.model.project_role_users);
        }
        return null;
    }

    getProjectRoleSkills() {
        if (this.model.project_role_skills) {
            if (this.isModelAlreadyLoaded('project_role_skills')) {
                return this.model.project_role_skills;
            }

            return new ProjectRoleSkills(this.model.project_role_skills);
        }
        return null;
    }

    destroy() {
        if (!this.model.id) {
            this.model = undefined;
            return;
        }
        this.model._destroy = true;
    }

    areProjectRoleSkillsEmpty() {
        return this.model.project_role_skills.areEmpty();
    }

    getSkillNames() {
        return this.get('project_role_skills')
            .collection
            .map(projectskill => projectskill.get('skill').get('skill_type'));
    }
}
