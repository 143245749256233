import React from "react";
import { Col, Row } from "react-bootstrap";
import AppComponent from "../../AppComponent";
import { ROLES } from "../../../utils/constants";
import Pill from "../../common/Pill";

export default class ShowMappedUser extends AppComponent {
    renderUserRoleSkills = (projectRoleSkills) => {
        const userRoleSkills = projectRoleSkills.map((value, index) => (
            <Pill
                key={index}
                value={value}
                highlight
            />
        ));
        return userRoleSkills;
    }

    render() {
        const {
            roleStartDate, roleEndDate, capacity, employee, mapPercentage, shadow, skills
        } = this.props;
        return (
            <Col md={11}>
                <Row className="mapping-table-data">
                    <Col md={2} className="show-table-data">
                        {roleStartDate}
                    </Col>
                    <Col md={2} className="show-table-data margin-left-negative-5">
                        {roleEndDate}
                    </Col>
                    <Col md={2} className="show-table-data margin-left-negative-5">
                        {capacity}
                    </Col>
                    <Col md={2} className="show-table-data" data-test="employee">
                        {employee}
                    </Col>
                    {window.loggedInUser.role === ROLES.USER
                        ? "" : (
                            <React.Fragment>
                                <Col md={3} className="show-table-data">
                                    {skills ? this.renderUserRoleSkills(skills) : "-"}
                                </Col>
                                <Col md={2} className="show-table-data">
                                    {shadow ? "Shadow" : mapPercentage}
                                </Col>
                            </React.Fragment>
                        )}

                </Row>
            </Col>
        );
    }
}
