import React from "react";
import { Row, Col } from "react-bootstrap";
import EditProjectRoleRow from "./editProjectRoleRow";
import ProjectRoles from "../../../models/collections/ProjectRoles";
import AppComponent from "../../AppComponent";

export default class EditProjectRoleGroup extends AppComponent {
    renderHeaders = () => (
        <Col md={11}>
            <Row className="mapping-table-data margin-bottom-15px margin-top-2">
                <Col md={2}>Start Date</Col>
                <Col md={2}>End Date</Col>
                <Col md={2}>Select Capacity</Col>
                <Col md={2}>Role Capacity</Col>
            </Row>
        </Col>
    )

    renderProjectRoleRows = () => {
        const {
            elements, project, updateProject, projectRoles
        } = this.props;
        const activeRoles = elements.filter(projectRole => projectRole.get("_destroy") !== true);
        const filteredRoles = new ProjectRoles(activeRoles);
        const row = filteredRoles.map(element => (
            <EditProjectRoleRow
                key={element.get('id')}
                projectRole={element}
                project={project}
                updateProject={updateProject}
                projectRoles={projectRoles}
                className="project-role-user-group"
            />
        ));
        return row;
    }

    render() {
        const { toggle, noOfRoles } = this.props;
        if (toggle && noOfRoles) {
            return (
                <>
                    {this.renderHeaders()}
                    {this.renderProjectRoleRows()}
                </>
            );
        }

        return (
            <div />
        );
    }
}
