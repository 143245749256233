import React from 'react';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Dots } from '@zendeskgarden/react-loaders';
import { LOADER } from "../../utils/constants";

export default function Loader() {
    return (
        <div className="loader">
            <ThemeProvider>
                <Dots color={LOADER.COLOR} size={LOADER.SIZE} baopacity={0} />
            </ThemeProvider>
        </div>
    );
}
