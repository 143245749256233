import React from "react";
import PropTypes from "prop-types";

export default function Pill(props) {
    const { highlight, value } = props;
    const additionalCss = highlight && "filled";
    return (
        <span className={`pill ${additionalCss}`}>{` ${value} `}</span>
    );
}

Pill.propTypes = {
    value: PropTypes.string.isRequired
};
