import React from 'react';
import { Checkbox, Pagination } from 'semantic-ui-react';
import { Row, Col } from "react-bootstrap";
import Modal from "../common/Modal";
import Skills from "../../models/collections/Skills";
import {
    NO_RECORDS_FOUND, NUMBER, SKILLS_PER_PAGE, SUCCESS_CODES, TIME_INTERVAL
} from "../../utils/constants";
import AppComponent from "../AppComponent";

export default class MergeDuplicateSkills extends AppComponent {
    constructor(props) {
        super(props);

        this.state = {
            collection: new Skills([]),
            show: false,
            preferredId: null,
            selectedSkillsCount: 0,
            activePage: 1,
            totalPages: 0
        };
        this.selectedSkills = {};
    }

    componentDidMount() {
        this.populateSkills();
    }

    populateSkills = () => {
        const { collection, activePage } = this.state;
        collection.getPaginatedSkills({ page: activePage, per_page: SKILLS_PER_PAGE }, (response) => {
            const skills = new Skills(response.skills);
            this.setState({ collection: skills, totalPages: response.total_pages });
        });
    };

    handleClose = () => {
        this.setState({ show: false });
    };

    handleSave = () => {
        const { preferredId } = this.state;
        this.setState({ show: false });
        delete this.selectedSkills[preferredId];
        const skills = new Skills([]);
        skills.mergeSkills(this.selectedSkills, preferredId,
            () => {
                this.notifySuccess(SUCCESS_CODES.SKILLS_MERGED_SUCCESS, TIME_INTERVAL.THREE_THOUSAND);
                this.selectedSkills = {};
                this.populateSkills();
            });
    };

    handleMerge = () => {
        if (Object.keys(this.selectedSkills).length > NUMBER.ONE) {
            this.setState({ show: true });
        }
    };

    handlePaginationChange = (e, { activePage }) => {
        this.setState({ activePage }, () => {
            this.populateSkills();
        });
    };

    tryUpdateSelectedSkillsCount = (event) => {
        const { checked, id, name } = event.target;
        if (checked) {
            this.selectedSkills[id] = name;
            this.setState({ selectedSkillsCount: this.getIdCount() });
            return;
        }
        delete this.selectedSkills[id];
        this.setState({ selectedSkillsCount: this.getIdCount() });
    };

    getIdCount = () => Object.keys(this.selectedSkills).length;

    handlePrimarySkill = (event) => {
        const { value: preferredId } = event.target;
        this.setState({ preferredId });
    };

    renderMergeModelContent = () => (
        <div className="margin-top-3">
            {Object.entries(this.selectedSkills)
                .map(key => (
                    <Row>
                        <Col md={6}>
                            <div className="radio">
                                <input
                                    type="radio"
                                    name="optradio"
                                    onClick={this.handlePrimarySkill}
                                    value={key[0]}
                                />
                                {' '}
                                {key[1]}
                            </div>
                        </Col>
                    </Row>
                ))}
        </div>
    );

    renderGridContent = () => {
        const { collection } = this.state;
        const gridContent = collection.map(model => (
            <div key={`${model.get('id')}`} className="grid-content">
                <div className="name">
                    <span>{model.get('skill_type')}</span>
                </div>

                <Checkbox
                    checked={Object.keys(this.selectedSkills).includes(String(model.get('id')))}
                    id={model.get('id')}
                    name={model.get('skill_type')}
                    onClick={this.tryUpdateSelectedSkillsCount}
                    className="skill-checkbox"
                />
            </div>
        ));
        return gridContent;
    };

    render() {
        const {
            show, selectedSkillsCount, collection, activePage, totalPages
        } = this.state;
        const disabledCss = selectedSkillsCount > NUMBER.ONE ? "" : "btn-disabled";
        if (!collection.getSize()) {
            return (
                <div className="data-listing">
                    <span className="heading-2">{NO_RECORDS_FOUND("Skills")}</span>
                </div>
            );
        }
        return (
            <div className="data-listing">
                <div>
                    <Row>
                        <Col md={6}>
                            <span className="heading-1">Skills</span>
                        </Col>
                    </Row>
                </div>
                <div className="grid">
                    <div className="grid-header">
                        <div className="name">
                            Select To Merge
                        </div>
                        <div className="status">
                            <button
                                type="button"
                                className={`btn btn-primary ${disabledCss}`}
                                onClick={this.handleMerge}
                            >
                                Merge
                            </button>
                        </div>
                    </div>
                    {this.renderGridContent()}
                    <div className="grid-footer">
                        <Pagination
                            activePage={activePage}
                            boundaryRange={1}
                            onPageChange={this.handlePaginationChange}
                            size='mini'
                            siblingRange={1}
                            totalPages={totalPages}
                        />
                    </div>
                </div>
                <div className="merge-skill-model">
                    <Modal
                        showCloseIcon
                        open={show}
                        onClose={this.handleClose}
                        header="Select Primary Skill"
                        content={this.renderMergeModelContent()}
                        handleClose={this.handleClose}
                        handleSave={this.handleSave}
                        actionBtnText="Merge"
                        warningText="Merging skill might affect projects"
                        dialogClassName="merge-skill-model"
                    />
                </div>
            </div>
        );
    }
}
