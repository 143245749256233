import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import UpIcon from "@material-ui/icons/KeyboardArrowUp";
import { green } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import SaveIcon from "@material-ui/icons/Save";


const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    position: "relative",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}));

export default function FloatingActionButtonZoom(props) {
  const classes = useStyles();

  const fabs = [
    {
      color: "primary",
      className: classes.fab,
      icon: <SaveIcon className ={classes.extendedIcon}/>,
      label: "Save",
    },
  ];

  return (
    <div className={classes.root}>
      {fabs.map((fab) => (
        <Fab
          aria-label={fab.label}
          className={fab.className}
          color={fab.color}
          onClick={props.handleClick}
          variant="extended"
        >
          {fab.icon } Save 
        </Fab>
      ))}
    </div>
  );
}
