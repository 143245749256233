export default class PercentageColorCodeProvider {
    static getColorCode(percentage) {
        if (percentage > 75 && percentage <= 100) {
            return 'D8FD88';
        }
        if (percentage > 50 && percentage <= 75) {
            return 'E3FB5C';
        }
        if (percentage > 25 && percentage <= 50) {
            return 'FBF85C';
        }
        if (percentage > 0 && percentage <= 25) {
            return 'FFFEC5';
        }
        if (percentage === 0) {
            return 'FFD0C5';
        }
        return 'FFFFFF';
    }
}
