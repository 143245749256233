import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import _ from "underscore";
import AppComponent from "../AppComponent";
import Day from "../../utils/day";
import Projects from "../../models/collections/Projects";
import DateRangeSelector from "../common/DateRangeSelector";
import SortHeader from "../common/SortHeader";
import { ASCENDING, DESCENDING } from "../../utils/constants";

function getEmptyRow() {
    return (
        <tr
            key="-"
            className="row empty-row"
        >
            <td className="col-md-3">&ndash;</td>
            <td className="col-md-3">&ndash;</td>
            <td className="col-md-2">&ndash;</td>
            <td className="col-md-2">&ndash;</td>
            <td className="col-md-2">&ndash;</td>
        </tr>
    );
}

export default class AllProjects extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            startDate: Day.getTodaysDate(),
            endDate: Day.getTodaysDate(),
            projects: new Projects([]),
            sortOrder: ASCENDING
        };
    }

    componentDidMount() {
        this.fetchAllProjects();
    }

    fetchAllProjects() {
        const { startDate, endDate } = this.state;
        const projectCollection = new Projects([]);
        projectCollection.getAllProjects({
            startDate,
            endDate
        }, (projects) => {
            this.setState({ projects });
        });
    }

    sortBy = (field) => {
        const { projects, sortOrder } = this.state;
        const newSortOrder = sortOrder === ASCENDING ? DESCENDING : ASCENDING;
        let sorted = _.sortBy(projects, field);

        if (this.state.sortOrder === ASCENDING) {
            sorted = sorted.reverse();
        }

        this.setState({ sortOrder: newSortOrder, projects: sorted });
    };

    sortByName = () => this.sortBy('project_name');

    sortByNoOfPeople = () => this.sortBy('team_size');

    sortByStartDate = () => this.sortBy('start_date');

    sortByStatus = () => this.sortBy('status');

    renderHeaders = () => (
        <thead>
            <tr className="header row">
                <SortHeader
                    title="Name"
                    key="project_name"
                    onSort={this.sortByName}
                    sortOrder={this.state.sortOrder}
                    sortName={this.state.sortName}
                    className="col-md-3"
                />
                <th className="col-md-3">Technology</th>
                <SortHeader
                    title="Start date"
                    key="start_date"
                    onSort={this.sortByStartDate}
                    sortOrder={this.state.sortOrder}
                    className="col-md-2"
                />
                <SortHeader
                    title="Status"
                    key="status"
                    onSort={this.sortByStatus}
                    sortOrder={this.state.sortOrder}
                    className="col-md-2"
                />
                <SortHeader
                    title="No of people"
                    key="team_size"
                    onSort={this.sortByNoOfPeople}
                    sortOrder={this.state.sortOrder}
                    className="col-md-2"
                />
            </tr>
        </thead>
    );

    renderContent = () => {
        const { projects } = this.state;
        if (!projects) {
            return;
        }

        if (!projects.length) {
            return getEmptyRow();
        }
        return projects.map(project => (
            this.getProjectRow(project)
        ));
    };

    getProjectRow(project) {
        return (
            <tr
                key={project.project_id}
                className="row link-pointer"
                onClick={() => this.navigateToViewProject(project)}
            >
                <td className="col-md-3">{project.project_name}</td>
                <td className="col-md-3">
                    <span className="apply-eclipse-on-column">
                        {project.skills.join()}
                    </span>
                </td>
                <td className="col-md-2">{project.start_date}</td>
                <td className={`col-md-2 ${project.status === "On going project" ? "on-going-projects" : ""}`}>
                    {project.status}
                </td>
                <td className="col-md-2">{project.team_size}</td>
            </tr>
        );
    }

    navigateToViewProject = (project) => {
        const { history, lastPageUrl } = this.props;
        const url = this.getViewProjectUrl(project);
        history.push(`${url}`, { lastPageUrl });
    }

    getViewProjectUrl = project => `/customers/${project.customer_id}/view-projects/${project.project_id}`

    handleDateRangeChange = (startDate, endDate) => {
        this.setState({ startDate, endDate });
        this.fetchAllProjects();
    };

    render() {
        const { startDate, endDate } = this.state;
        return (
            <div>
                <Row className="margin-2">
                    <Col md={8} className="heading-2">All Projects</Col>
                    <Col md={3}>
                        <DateRangeSelector
                            onRangeSelected={this.handleDateRangeChange}
                            startDate={startDate}
                            endDate={endDate}
                            data-test="user-all-projects"
                            noClearOption
                        />
                    </Col>
                </Row>
                <Row className="padding-left-25px">
                    <Col md={11} className="customized-table">
                        <Table hover responsive>
                            {this.renderHeaders()}
                            <tbody>{this.renderContent()}</tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}
