import React from "react";
import { Row, Col } from "react-bootstrap";
import ProjectRoleUsersRows from "./projectRoleUsersRows";

export default class ProjectRoleGroup extends React.Component {
    renderHeaders = () => (
        <Row className="mapping-table-data margin-bottom-15px margin-top-3">
            <Col md={2}>Start Date</Col>
            <Col md={2} className="margin-left-negative-5">End Date</Col>
            <Col md={1} className="margin-left-negative-5">Required Capacity</Col>
            <Col md={2} className="margin-right-4">Mapped Employee</Col>
            <Col md={2} className="margin-right-2">Skills</Col>
            <Col md={1} className="margin-right-2">Available Capacity</Col>
            <Col md={1} className="margin-right-2">Mapped Capacity</Col>
        </Row>
    )

    renderProjectRoleUsers = () => {
        const { elements, project, updateProject } = this.props;
        const row = elements.map(element => (
            <ProjectRoleUsersRows
                key={element.get('id')}
                element={element}
                project={project}
                updateProject={updateProject}
                className="project-role-user-group"
            />
        ));
        return row;
    }

    render() {
        const { toggle } = this.props;
        if (toggle) {
            return (
                <>
                    {this.renderHeaders()}
                    {this.renderProjectRoleUsers()}
                </>
            );
        }

        return (
            <div />
        );
    }
}
