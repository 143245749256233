import React from "react";
import { Col } from "react-bootstrap";
import Select from "react-select-plus";
import moment from "moment";
import DateSelector from "../../common/DateSelector";
import {
    AVAILABLE, MESSAGES, UNAVAILABLE
} from "../../../utils/constants";
import AppComponent from "../../AppComponent";
import Users from "../../../models/collections/Users";
import Pill from "../../common/Pill";
import Day from "../../../utils/day";

export default class ProjectRoleUserRow extends AppComponent {
    constructor(props) {
        super(props);
        this.state = {
            usersWithSkills: new Users([]),
            availability: -1
        };
    }

    componentWillMount() {
        this.getAvailableUsers();
    }

    getAvailableUsers = (changedStartDate = "", changedEndDate = "") => {
        const { projectRoleUser, projectRole } = this.props;
        const startDate = changedStartDate || projectRoleUser.get('start_date');
        const endDate = changedEndDate || projectRoleUser.get('end_date');
        const usersWithSkills = new Users([]);
        const skills = projectRole.getSkillNames();
        if (skills.length) {
            usersWithSkills.getUsersWithSkillsWithinDates({
                skills,
                startDate,
                endDate
            }, (response) => {
                this.setState({ usersWithSkills: response });
            });
        }
    };

    startDateChange = (selectedDate) => {
        const { projectRoleUser, updateProject, project } = this.props;
        projectRoleUser.set('start_date', selectedDate);
        this.getAvailableUsers(selectedDate);
        updateProject(project);
    };

    endDateChange = (selectedDate) => {
        const { projectRoleUser, updateProject, project } = this.props;
        projectRoleUser.set('end_date', selectedDate);
        this.getAvailableUsers("", selectedDate);
        updateProject(project);
    };

    getAvailableOptions =() => {
        const { usersWithSkills } = this.state;
        if (!usersWithSkills.available) {
            return;
        }

        const availableOptions = usersWithSkills.available
            .map(x => ({
                label: x.name,
                value: x.id
            }));

        if (availableOptions.length === 0) {
            availableOptions.push(
                {
                    label: "No user available",
                    value: "No user available"
                }
            );
        }
        return availableOptions;
    }

    getUnavailableOptions = () => {
        const { usersWithSkills } = this.state;

        if (!usersWithSkills.unavailable) {
            return;
        }

        const unavailableOptions = usersWithSkills
            .unavailable.map(value => ({
                label: value.name,
                value: value.id
            }));

        if (unavailableOptions.length === 0) {
            unavailableOptions.push(
                {
                    label: "No user available",
                    value: "No user available"
                }
            );
        }
        return unavailableOptions;
    }

    getFormattedUsers = () => [
        {
            label: AVAILABLE,
            options: this.getAvailableOptions()
        },
        {
            label: UNAVAILABLE,
            options: this.getUnavailableOptions()
        }
    ];

    handleMappedEmployeeChange = (selectedUser) => {
        const {
            projectRoleUser, updateProject, project, projectRole, index
        } = this.props;
        const { usersWithSkills } = this.state;
        if (projectRoleUser.isNewUser() && selectedUser === null) {
            const user = {
                name: ""
            };
            projectRoleUser.set('user_id', "");
            projectRoleUser.set('user', user);
            updateProject(project);
            return;
        }
        if (selectedUser === null) {
            if (projectRole.canDelete()) {
                index === 0 ? this.deleteAllUsers() : projectRoleUser.set('_destroy', true);
                updateProject(project);
                return;
            }
            this.notifyError(MESSAGES.CANNOT_DELETE_ALREADY_MAPPED_USER);
            return;
        }
        const selectedUserSection = selectedUser.group.label.toLowerCase();
        let mappedPercentage = 0;
        usersWithSkills[selectedUserSection].map((user) => {
            if (user.id === selectedUser.value) {
                mappedPercentage = user.available_percentage;
            }
        });
        const capacity = projectRole.get('capacity');
        if (mappedPercentage > capacity) { mappedPercentage = capacity; }
        projectRoleUser.set('user_id', selectedUser.value);
        const personName = selectedUser.label.slice(0, selectedUser.label.indexOf("(") - 1);
        const user = {
            name: personName,
            id: selectedUser.value
        };
        projectRoleUser.set('mapped_percentage', mappedPercentage);
        projectRoleUser.set('user', user);
        projectRoleUser.set('_destroy', false);
        updateProject(project);
    };

    canDeleteUsers = (projectRole) => {
        const roleStartDate = new moment(projectRole.get('role_start_date'));
        const todaysDate = new moment(Day.getTodaysDate());
        return roleStartDate > todaysDate;
    }

    deleteAllUsers = () => {
        const { projectRole } = this.props;
        const projectRoleUsers = projectRole.get('project_role_users');
        projectRoleUsers.collection.map(user => user.set('_destroy', true));
    }

    renderSkillsAndHighlight = () => {
        const { usersWithSkills } = this.state;

        if (!usersWithSkills) {
            return;
        }

        if (!usersWithSkills.available) {
            return;
        }

        const { projectRoleSkills } = this.props;
        const skills = usersWithSkills.available.concat(usersWithSkills.unavailable);
        const userId = this.props.value.value;
        const userSkill = skills.find(x => x.id === userId);
        const userMatchedSkills = userSkill ? userSkill.matched_skills : [];

        const userRoleSkills = projectRoleSkills.map((value) => {
            const skillName = this.getSkillName(value);
            return (
                <Pill
                    key={skillName}
                    value={skillName}
                    highlight={userMatchedSkills.includes(skillName)}
                />
            );
        });
        return userRoleSkills;
    }

    getAvailability = () => {
        const { value } = this.props;
        const { usersWithSkills } = this.state;
        if (!usersWithSkills.available) {
            return;
        }
        const users = usersWithSkills.available.concat(usersWithSkills.unavailable);
        const userId = value.value;
        const user = users.find(x => x.id === userId);
        const availability = user ? user.available_percentage : "-";
        return availability;
    }

    getSkillName = value => value.get('skill').get('skill_type');

    render() {
        const {
            projectRoleUser, project, index, value, projectRole
        } = this.props;
        const availability = this.getAvailability();
        return (
            <>
                <Col md={2}>
                    <DateSelector
                        onDateSelected={this.startDateChange}
                        startDate={projectRoleUser.get('start_date')}
                        min={projectRole.get("role_start_date")}
                        max={projectRole.get("role_end_date")}
                        data-test="start-date-selector"
                    />
                </Col>
                <Col md={2} className="margin-left-negative-5">
                    <DateSelector
                        onDateSelected={this.endDateChange}
                        startDate={projectRoleUser.get('end_date')}
                        min={project.get("actual_start_date") || project.get('tentative_start_date')}
                        max={project.get("actual_end_date") || project.get('tentative_end_date')}
                        data-test="end-date-selector"
                    />
                </Col>
                <Col md={1} className="margin-left-negative-5">
                    {index === 0 ? ` ${projectRole.get('capacity')}%` : ""}
                </Col>
                <Col md={2} className="margin-right-4">
                    <Select
                        options={this.getFormattedUsers()}
                        value={value}
                        onChange={this.handleMappedEmployeeChange}
                        data-test="mapped-employee"
                    />
                </Col>
                <Col md={2} className="user-skill-names margin-right-2">
                    {this.renderSkillsAndHighlight()}
                </Col>
                <Col
                    md={1}
                    className={this.getClassName(projectRole, availability)}
                >
                    { availability == '-' ? "" : `${availability}%`
                    }
                </Col>
                <Col md={1} className="margin-right-2">
                    {projectRoleUser.get('mapped_percentage') == '-'
                        ? ""
                        : `${projectRoleUser.get('mapped_percentage')}%`}
                </Col>
            </>
        );
    }

    getClassName = (projectRole, availability) => `margin-right-2 ${projectRole.get('capacity')
        <= availability ? "" : "red-color"}`
}
