import React, { Component } from 'react';

export default class Notes extends Component {
    constructor(props) {
        super(props);
        this.handleChangeOfName = this.handleChangeOfName.bind(this);
    }

    handleChangeOfName(event) {
        const { handleChangeInParentComponent } = this.props;
        handleChangeInParentComponent('notes', event.target.value);
    }

    render() {
        const { notes } = this.props;
        return (
            <div id='notes-field' className='width-65'>
                <label className="labels">Notes:</label>
                <textarea
                    type="text"
                    name="notes"
                    id="notes"
                    className="text-font border-thin notes-form"
                    value={notes || ''}
                    onChange={this.handleChangeOfName}
                    placeholder="Add extra details ..."
                />
            </div>
        );
    }
}
