import React, { Component } from "react";
import Select from "react-select";

import { MAPS, SUCCESS_CODES, TIME_INTERVAL } from "../../utils/constants";
import UserPermission from "../../models/UserPermission";

const toastr = require("toastr");

export default class SelectAccessRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRole: props.selectedRole,
            accessRoles: ["owner", "admin", "user"],
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selectedRole: nextProps.selectedRole });
    }

    getOptions = values => values.map(value => ({ label: MAPS.accessRolesMap[value], value }));

    onRoleChange = (event) => {
        const { fetchUsers, id } = this.props;

        this.setState({ selectedRole: event.value }, () => {
            const model = new UserPermission(
                {
                    role: this.state.selectedRole,
                    userId: id
                }
            );
            model.updateAccessRole(() => {
                toastr.success(SUCCESS_CODES.UPDATE_ROLE_SUCCESS, 'Success', { timeOut: TIME_INTERVAL.THOUSAND });
                fetchUsers();
            });
        });
    };

    render() {
        const { selectedRole, accessRoles } = this.state;
        return (

            <Select
                multi={false}
                options={this.getOptions(accessRoles)}
                value={selectedRole}
                onChange={this.onRoleChange}
                className="upper-case-text"
                clearable={false}
            />
        );
    }
}
