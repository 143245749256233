import axios from "axios";
import BaseCollection from "./BaseCollection";
import Role from "../Role";
import { getApiUrl } from "../../utils/serviceUrls";

export default class Roles extends BaseCollection {
    getModel(attributes) {
        return new Role(attributes);
    }

    getRolesMap() {
        const rolesMap = new Map();
        for (let index = 0; index < this.collection.length; index++) {
            rolesMap.set(this.collection[index].model.id, this.collection[index].model.name);
        }
        return rolesMap;
    }

    getRoles(successCallback) {
        axios.get(getApiUrl("GET_ROLES"),
            { headers: this.getAuthHeaders() })
            .then((response) => {
                successCallback(response.data);
            });
    }
}
