import React, { Component } from 'react';
import { Table } from "react-bootstrap";
import _ from 'underscore';
import formatDate from "../../../utils/formatDate";

export default class UserProjectsGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headings: [],
            datasource: []
        };

        _.bindAll(this, 'getRowData', 'renderTableData');
    }

    componentWillMount() {
        const { heading, dataToPrint } = this.props;
        this.setState({
            headings: heading,
            datasource: dataToPrint
        });
    }

    getRowData(projectId) {
        const { headings, datasource } = this.state;
        const rowData = headings.map((heading) => {
            const columnName = heading.column_name;
            const cellValue = datasource.get(projectId).get(columnName);
            return (
                <td
                    key={`${projectId}-${heading.column_name}`}
                    className='link-pointer'
                    onClick={() => this.redirectToProjectView(datasource.get(projectId))}
                >
                    {this.cellValue(columnName, cellValue)}
                </td>
            );
        });
        return rowData;
    }

    redirectToProjectView = (project) => {
        const { history, lastPageUrl } = this.props;
        const url = this.constructShowUrl(project);
        history.push(`${url}`, { customerID: project.get('customerId'), lastPageUrl });
    }

    constructShowUrl = project => `/customers/${project.get('customerId')}/view-projects/${project.get('projectId')}`

    cellValue(columnName, value) {
        const isDate = (columnName === "assignedFrom" || columnName === "assignedTo");
        const areSkills = (columnName === "projectSkills" || columnName === "skillWorkedOn");
        if (isDate && value) {
            return formatDate(value);
        }
        if (areSkills && value) {
            return value.join(", ");
        }
        return value;
    }

    renderTableHeader() {
        const { headings } = this.state;
        const tableHeading = headings.map(heading => (
            <th key={heading.text}>
                {heading.text}
            </th>
        ));
        return tableHeading;
    }

    renderTableData() {
        const { datasource } = this.state;
        const tableData = datasource.map((element, projectIndex) => {
            const rowData = this.getRowData(projectIndex);
            return (
                <tr key={element.get('projectId') + 1} className="table-data clickable">
                    {rowData}
                </tr>
            );
        });
        return tableData;
    }

    render() {
        const { datasource } = this.state;
        if (datasource.length <= 0) {
            return (
                <div className="normal-bold-font">
                    No Result Found!
                </div>
            );
        }
        return (
            <Table bordered responsive>
                <thead>
                    <tr className="table-header">
                        {this.renderTableHeader()}
                    </tr>
                </thead>
                <tbody>
                    {this.renderTableData()}
                </tbody>
            </Table>
        );
    }
}
